import React, { useEffect, useRef, useState } from 'react'
import ChatData from "assets/data/chat.data.json"
import { Avatar, Divider, Input, Form, Button, Menu, Dropdown } from 'antd';
import {
	FileOutlined,
	SendOutlined,
	PaperClipOutlined,
	SmileOutlined,
	AudioMutedOutlined,
	UserOutlined,
	DeleteOutlined
} from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars';
import Flex from 'components/shared-components/Flex';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown'
import { connect } from 'react-redux';
import { finishAdminChat, selectChatDetailList, selectChatList, startAdminChat } from 'utils/redux/actions/chatActions';
import { bindActionCreators } from 'redux';
import { MESSAGE_SEND_TYPE, MESSAGE_TYPES } from 'constants/messageConstants';
import { Spin, Alert } from 'antd';
import ProfileImageComponent from 'components/ProfileImageComponent';
import globalConstant from 'constants/globalConstant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faExternalLinkAlt, faChevronDown, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import ListMessageItemModal from 'components/ListMessageItemModal'; 
import network from 'network/network'; 
import helperFunc from 'helpers/helperFunc';
import { Helmet } from 'react-helmet';
import MessageItemInformationModal from 'components/MessageItemInformationModal';

const menu = () => (
	<Menu>
		<Menu.Item key="0">
			<UserOutlined />
			<span>User Info</span>
		</Menu.Item>
		<Menu.Item key="1">
			<AudioMutedOutlined />
			<span>Mute Chat</span>
		</Menu.Item>
		<Menu.Divider />
		<Menu.Item key="3">
			<DeleteOutlined />
			<span>Delete Chat</span>
		</Menu.Item>
	</Menu>
);


const Conversation = (props) => {

	const formRef = useRef();
	const chatBodyRef = useRef(null)
	const isInitial = useRef(true)
	const idIsChange = useRef(true)
	const messageDetailModalRef = useRef();

	const [listMessageId, setListMessageId] = useState(-1);
	const [messageHeader, setMessageHeader] = useState("");
	const [listItemModalVisible, setListItemModalVisible] = useState(false);
	const [adminMessage, setAdminMessage] = useState("");
	const [sendLoading, setSendLoading] = useState(false);
	const [messageInfoIconIndex, setMessageInfoIconIndex] = useState(false);

	// const { id } = props.match.params
	const { id } = props;

	useEffect(() => {
		//selectMessageList();
		 selectMessageList();
    	//console.log('selectMessageList result:', result);
	}, []);

	useEffect(() => {

		global.chatBodyRef = chatBodyRef.current;
	}, [chatBodyRef.current]);
	useEffect(() => {

	 
		if (isInitial.current) {
			isInitial.current = false;
			
		} else {
			idIsChange.current = true;
			selectMessageList();

		}

	}, [id]);

	useEffect(() => {
		if (idIsChange.current) {
			idIsChange.current = false;
		} else
			if (props.scrollBottomActive) {
				scrollToBottom();
			}

	}, [props.scrollBottomActive]);
	const scrollToBottom = () => {
		if (chatBodyRef.current) {
			setTimeout(() => {
				if (chatBodyRef.current) {
					chatBodyRef.current.scrollToBottom()
				}

			}, 100);

		}
	}
	
	const selectMessageList = () => {

		var params = {
			userToken: localStorage.getItem("auth_token"),
			customerNumber: id
		}
		props.selectChatListAction(params);
	}


	const finishCustomerService = () => {


		var params = { 
			customerNumber: id
		}
		props.finishAdminChatAction(params);
	}
	const finishCustomerServicePreview = () => {

		helperFunc.showConfirm({
			content: `Otomatik mesajlaşmaya geçilecek. Onaylıyor musunuz?`,
			title: "Hatırlatma", onConfirm: () => finishCustomerService()
		});
	}

	const onAdminSendMessage = async () => {

		if (adminMessage.trim().length == 0) {
			return;
		}
		var messageModel = {
			text: adminMessage,
			type: "text"
		}
 
		var params = {
			adminmessage: JSON.stringify(messageModel),
			message: (messageModel), 
			destination: id,
			disablePreview: false,
			source: "",

		}
		setSendLoading(true)


		var result = await network.post("/messages/sendMessageFromAdmin", params);

		setSendLoading(false)
		if (result.isSuccess) {
			var postResult = result.data;
			if (postResult.isSuccess) {
				props.startAdminChatAction();
				params.sendType = 2;
				setAdminMessage(""); 


			} else {
				alert(postResult.errorMessage);
			}

		} else {
			alert(result.errorMessage);
		}
	};

	const getFinishTransactionButton = () => {

		if (props.isAdminTransaction) {

			return (
				<Button type="link" onClick={() => finishCustomerServicePreview()} htmlType="button"  >
					Manuel Mesajlaşmayı Bitir
				</Button>
			);
		}
	}


	const chatContentHeader = name => (
		<div className="chat-content-header" style={{ background: "#fff" }}>
			<div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", alignItems: "center" }}>

				<img style={{ borderRadius: 40, width: 35, height: 35, marginRight: 15 }} src={globalConstant.imageTypes.getImageUrl(globalConstant.imageTypes.customerImage.type, props.customerImage)}></img>
				<h4 className="mb-0">{name}</h4>
			</div>

			<Flex>
				<div>
					{getFinishTransactionButton()}
				</div>
				<EllipsisDropdown menu={menu} />
			</Flex>
		</div>
	)


	const getListMessage = (item, i) => {



		return (

			<div className={`bubble-wrapper${item.sendType === MESSAGE_SEND_TYPE.outbound ? "me" : ""}`}
				style={{ maxWidth: 408, paddingLeft: 10 }}
			>
				<div className='header'>
					<span className='bold' style={{ fontWeight: "bold" }}>{item.message.title}</span>
				</div>
				<div className='content' style={{ marginTop: 5, marginBottom: 5 }}>
					<span className=''>{item.message.body}</span>
				</div>
				{getMessageDate(item)}
				<div onClick={() => {
					setListMessageId(item.mainMessageId);
					setListItemModalVisible(true);
					setMessageHeader(item.message.globalButtons[0].title);
				}} className={`bubble-wrapperme text-center p-2 text-primary`}
					style={{ marginBottom: 3, marginTop: i == 0 ? 3 : 0, border: 0, borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "#d3d0d0", borderRadius: 0, cursor: "pointer" }}
				>
					<FontAwesomeIcon style={{ marginRight: 5, }} className="text-primary" icon={faList} />
					{item.message.globalButtons[0].title}
				</div>
			</div>


		);
	}

	const getQuickRepplyMessage = (item) => {



		return (
			<>
				<div   className={`bubble-wrapper${item.sendType === MESSAGE_SEND_TYPE.outbound ? "me" : ""}`}
					style={{ maxWidth: 408, paddingLeft: 10 }}
				>
					<div className='header'>
						<span className='bold' style={{ fontWeight: "bold" }}>{item.message.content.header}</span>
					</div>
					<div className='content' style={{ marginTop: 5, marginBottom: 5 }}>
						<span className=''>{item.message.content.text}</span>
					</div>
					<div className='' style={{ color: "gray" }}>
						<span className=''>{item.message.content.caption}</span>
					</div>

					{getMessageDate(item)}
				</div>
				{item.message.options.map((item2, i) => (
					<div className={`bubble-wrapper${item.sendType === MESSAGE_SEND_TYPE.outbound ? "me" : ""} text-center p-2 text-primary`}
						style={{ marginBottom: 3, marginTop: i == 0 ? 3 : 0 }}
					>
						{item2.title}
					</div>
				))}
			</>

		);
	}


	const getMessageDropdownMenu = (item) => {

		if (item.sendType === MESSAGE_SEND_TYPE.inbound) {

			return;
		}
		return (
			<Menu>
				<Menu.Item key="0">
					<a onClick={() => {
						messageDetailModalRef.current.getMessageInfo(item.mainMessageId)
					}}>Mesaj Detayı</a>
				</Menu.Item>

			</Menu>
		);
	}
	const getMessageInfoIcon = (message, index) => {

		if (message.sendType === MESSAGE_SEND_TYPE.inbound) {
			return;
		}
		if (messageInfoIconIndex == index) {
			return (
				<Dropdown overlay={getMessageDropdownMenu(message)} trigger={['click', 'contextMenu']}>
					<a style={{ cursor: "pointer", float: "right" }}
					>
						<FontAwesomeIcon style={{ marginRight: 5, color: "#585858", fontSize: 16 }} icon={faChevronDown} />
					</a>
				</Dropdown>
			);
		}

	}

	const getMessageDate = (elm) => {


		return (
			<div style={{ textAlign: "right" }}>
				<label style={{ fontSize: 11 }}>
					{elm.messageDate ? elm.messageDate + " - " : ""}
					{elm.messageTime}
				</label>
			</div>
		);
	}

	const getChatText = (elm, i) => {

		var messageObject = [];
		const messageType = (elm.message ||{}).type;

		switch (messageType) {
			case MESSAGE_TYPES.TEXT:
				var messsageText = elm.message.text;
				var expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
				var matches = messsageText.match(expression);

				if (matches && matches.length > 0) {
					for (let i = 0; i < matches.length; i++) {
						const element = matches[i];
						var linkStr = `<a target="_blank" href = "${element}"> ${element} </a>`;
						messsageText = messsageText.replace(element, linkStr);

					}
				}

				messageObject = [
					<div key={elm.mainMessageId} className={`bubble-wrapper${elm.sendType === MESSAGE_SEND_TYPE.outbound ? "me" : ""}`}
					>
						<div dangerouslySetInnerHTML={{
							__html: messsageText
						}}>

						</div>
						{getMessageDate(elm)}
					</div>
				]
				break;
			case MESSAGE_TYPES.IMAGE:

				break;
			case MESSAGE_TYPES.LOCATION:
				  
				messageObject = [
					<div key={elm.mainMessageId} className={`bubble-wrapper${elm.sendType === MESSAGE_SEND_TYPE.outbound ? "me" : ""}`}
					>

						<a target={"_blank"} href={`https://www.google.com/maps/search/?api=1&query=${elm.message.latitude},${elm.message.longitude}`}>
							<FontAwesomeIcon style={{ marginRight: 5, color: "#585858", fontSize: 16 }} icon={faMapMarkerAlt} />
							Konumu görüntülemek için Tıklayın
						</a>
					</div>
				]
				break;
			case MESSAGE_TYPES.QUICK_REPPLY:
				messageObject = [getQuickRepplyMessage(elm, i)]
				break;
			case MESSAGE_TYPES.LIST:
				messageObject = [getListMessage(elm, i)]
				break;
			case MESSAGE_TYPES.BUTTON_REPPLY:


				break;
			case MESSAGE_TYPES.LIST_REPPLY:

				break;
			case MESSAGE_TYPES.FILE:

				messageObject = [

					<div className={`bubble-wrapper${elm.sendType === MESSAGE_SEND_TYPE.outbound ? "me" : ""}`}>
						<div style={{
							minWidth: 120,
							padding: 15,
							paddingBottom: 5,
							paddingTop: 5,
							fontWeight: "bold",
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							background: "#d3eebe",
							borderRadius: 10,
							cursor: "pointer"
						}}
							onClick={() => {
								window.open(elm.message.url, "_blank");
							}}
						>

							{elm.message.filename || "Dosya"}
							<FontAwesomeIcon style={{ marginRight: 5, }} className="text-primary" icon={faExternalLinkAlt} />
							{getMessageDate(elm)}
						</div>
					</div>

				]

				break;

			default:
				break;
		}

		return (
			<div  onMouseOver={() => { setMessageInfoIconIndex(i) }}
				onMouseLeave={() => { setMessageInfoIconIndex(-1) }}
				key={`msg-${id}-${i}`}
				className={`msg ${elm.msgType === 'date' ? 'datetime' : ''} ${elm.sendType === MESSAGE_SEND_TYPE.inbound ? 'msg-recipient' : elm.sendType === MESSAGE_SEND_TYPE.outbound ? 'msg-sent mr-4' : ''}`}
				style={{ whiteSpace: "pre-wrap" }}
			>
				<div className={`bubble ml-5`}>
				
					{messageObject}
				</div>
				{getMessageInfoIcon(elm, i)}
			</div>
		);
	}


	const chatContentBody = (props, id) => (
		<div className="chat-content-body" style={{ padding: 0 }} >
			<Scrollbars ref={chatBodyRef} autoHide>
				{
					props.map((elm, i) => (
						getChatText(elm, i)
					))
				}
			</Scrollbars>
		</div>
	)

	const chatContentFooter = () => (
		<div className="chat-content-footer" style={{ padding: 0, height: 55, background: "white" }}>
			<div name="msgInput" ref={formRef} onFinish={onAdminSendMessage} className="w-100">
				<Input
					autoComplete="off"
					placeholder="Mesajınız..."
					enterKeyHint='done'
					onChange={(ev) => {
						setAdminMessage(ev.target.value);
					}}
					onPressEnter={(ev) => {
						onAdminSendMessage();
					}}
					defaultValue={adminMessage}
					value={adminMessage}
					suffix={
						<div className="d-flex align-items-center">

							<Button shape="circle" type="primary" size="small" onClick={onAdminSendMessage}
								htmlType="button"
								loading={sendLoading}
							>
								{sendLoading ? null : <SendOutlined />}

							</Button>
						</div>
					}
				/>
			</div>
		</div>
	)

	const getContent = () => {


		if (props.isLoading) {

			return (
				<Spin tip="Mesajlar Yükleniyor..." style={{ width: "100%", height: "100%", marginTop: 25 }}>
				</Spin>
			);
		} else {

			return (
				<>
					{chatContentHeader(props.customerName)}
					{chatContentBody(props.messageList, id)}
					{chatContentFooter()}
				</>
			);
		}


	}


	return (
		<div className="chat-content" style={{
			backgroundColor: "#e5ddd5",
			backgroundImage: "url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1089577/background.png);",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
		}}>

			<ListMessageItemModal messageId={listMessageId} modalVisible={listItemModalVisible} setModalVisible={(isVisible) => {
				setListItemModalVisible(isVisible);
				setListMessageId(-1);
			}} messageHeader={messageHeader}></ListMessageItemModal>

			<MessageItemInformationModal ref={messageDetailModalRef}>

			</MessageItemInformationModal>
			{getContent()}
		</div>
	)

}

function mapStateToProps(state) {

	return {
		isLoading: state.chatDetailReducer.isLoading,
		messageList: state.chatDetailReducer.messageList,
		hasError: state.chatDetailReducer.hasError,
		errorMessage: state.chatDetailReducer.errorMessage,
		customerName: state.chatDetailReducer.customerName,
		customerId: state.chatDetailReducer.customerId,
		customerImage: state.chatDetailReducer.customerImage,
		scrollBottomActive: state.chatDetailReducer.scrollBottomActive,
		isAdminTransaction: state.chatDetailReducer.isAdminTransaction
	}
}

function mapDispatchToProps(dispatch) {
	return {
		selectChatListAction: bindActionCreators(selectChatDetailList, dispatch), 
		finishAdminChatAction: bindActionCreators(finishAdminChat, dispatch),
		startAdminChatAction: bindActionCreators(startAdminChat, dispatch),

	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);