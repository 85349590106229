import React, { useEffect } from "react"
import {Skeleton } from 'antd';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";  
import { selectCustomerChart, selectMessageChart } from "utils/redux/actions/defaultDashboardActions"; 
import StatisticWidget from "components/shared-components/StatisticWidget";
import ApexChart from "react-apexcharts";
import { apexLineChartDefaultOption, COLOR_2 } from "constants/ChartConstant";

const CustomerChart = (props) => {

    useEffect(()=>{

        props.selectCustomerChartAction();
    },[]); 

      return (
        <StatisticWidget
        title={
          <ApexChart
            options={{
              ...apexLineChartDefaultOption,
              ...{
                chart: {
                  sparkline: {
                    enabled: true,
                  }
                },
                colors: [COLOR_2],
                xaxis: {
                  categories: props.customerChartDates
                }
              }
            }}
            series={[props.customerChartSeries]}

            height={145}
          />
        }
        value={props.activeCustomer}
        // status={3.7}
        subtitle="Aktif Müşteriler"
      />
      );
}


function mapStateToProps(state) {

    return {
        customerChartDates: state.dashboardReducer.customerChartDates,
        customerChartSeries: state.dashboardReducer.customerChartSeries,
        activeCustomer: state.dashboardReducer.activeCustomer,
        customerChartLoading: state.dashboardReducer.customerChartLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectCustomerChartAction: bindActionCreators(selectCustomerChart, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerChart);
