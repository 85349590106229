 
import network from "network/network";
import actionTypes from "./actionTypes";



export function selectNotificationList() {

    var params = {
     
    }

    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.SELECT_NOTIFICATION_LIST_LOADING,
            isLoading: true
        })

        network.get("/admin/notifications/selectNotificationList", params, false).then((result) => {

            dispatch({
                type: actionTypes.SELECT_NOTIFICATION_LIST_LOADING,
                isLoading: false
            })

            const{data} = result;
            if (result.isSuccess && data.isSuccess) {

                var products = (data.data);
                dispatch({
                    type: actionTypes.SELECT_NOTIFICATION_LIST_SUCCESS,
                    payload: products
                })

            } else {
                dispatch({
                    type: actionTypes.SELECT_NOTIFICATION_LIST_ERROR,
                    errorMessage: result.errorMessage,
                    hasError: true
                })
            }

        });
    }
};



export function selectHomePageNotificationList() {

    var params = { 
    }

    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.SELECT_HOME_NOTIFICATION_LIST_LOADING,
            isLoading: true
        })

        network.get("/admin/Notifications/selectHomePageNotificationList", params, false).then((result) => {

            dispatch({
                type: actionTypes.SELECT_HOME_NOTIFICATION_LIST_LOADING,
                isLoading: false
            })
 
            const {data} = result;
            if (result.isSuccess && data.isSuccess) {


                dispatch({
                    type: actionTypes.SELECT_HOME_NOTIFICATION_LIST_SUCCESS,
                    payload: data.data
                })

            } else {
                dispatch({
                    type: actionTypes.SELECT_HOME_NOTIFICATION_LIST_ERROR,
                    errorMessage: result.errorMessage,
                    hasError: true
                })
            }

        });
    }
};


export function selectUnreadNotificationCount() {

    var params = { 
    }
    return (dispatch, getState) => {



        network.get("/admin/notifications/selectUnreadNotificationCount", params, false).then((result) => {

            if (result.data) {

                dispatch({
                    type: actionTypes.SELECT_UNREAD_NOTIFICATION_COUNT,
                    payload: result.data
                })
            }

        });
    }
};

export function readAllNotification() {

    var params = { 
    }
    return (dispatch, getState) => {

        network.get("/admin/notifications/readAllNotification", params, false).then((result) => {
 
                dispatch({
                    type: actionTypes.SELECT_UNREAD_NOTIFICATION_COUNT,
                    payload: 0
                })
            
        });
    }
};