import network from "network/network";
import actionTypes from "./actionTypes";
 
 

export function selectChatList (user) {

    return (dispatch, getState) => {

        // var defaultList = getState().chatReducer.chatList;
        // if (defaultList && defaultList.length>0) {
        //     return;
        // }
        dispatch({
            type: actionTypes.SELECT_CHAT_LIST_LOADING,
            isLoading : true
        }) 

        network.get("/Messages/selectFirmMessageMaster", null, false).then((result) => {
            dispatch({
                type: actionTypes.SELECT_CHAT_LIST_LOADING,
                isLoading : false
            }) 
            
            const {data} = result;
            if (result.isSuccess && data.isSuccess) {
                var messages = (data.data);
                dispatch({
                    type: actionTypes.SELECT_CHAT_LIST_SUCCESS,
                    payload : messages
                })
            } else {
                dispatch({
                    type: actionTypes.SELECT_CHAT_LIST_ERROR,
                    errorMessage: result.errorMessage,
                    hasError : true
                })
            }

        });
    }
};
 

 
export function resetUnreadCount (number) {

    return (dispatch, getState) => {

       
        dispatch({
            type: actionTypes.RESET_UNREAD_COUNT,
            customerNumber : number
        })  
    }
};

export function selectChatDetailList (user) {

    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.SELECT_CHAT_DETAIL_LIST_LOADING,
            isLoading : true
        }) 

        network.get("/messages/selectMessageDetail", user, false).then((result) => {

            dispatch({
                type: actionTypes.SELECT_CHAT_DETAIL_LIST_LOADING,
                isLoading : false
            }) 
            const {data} = result;
            if (result.isSuccess && data.isSuccess) {

                var messageMaster = (data.data);
                              
                dispatch({
                    type: actionTypes.SELECT_CHAT_DETAIL_LIST_SUCCESS,
                    payload : messageMaster.messageList,
                    customerName : messageMaster.customerName,
                    customerId : messageMaster.customerId,
                    customerImage : messageMaster.customerImage,
                    customerNumber : messageMaster.customerNumber,
                    isAdminTransaction : messageMaster.isAdminTransaction
                })

            } else {
                dispatch({
                    type: actionTypes.SELECT_CHAT_DETAIL_LIST_ERROR,
                    errorMessage: result.errorMessage,
                    hasError : true
                })
            }

        });
    }
};



export function finishAdminChat (params) {

    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.FINISH_ADMIN_CHAT, 
        }) 

        network.get("/messages/finishAdminChat", params, false).then((result) => {

           

        });
    }
};

export function startAdminChat () {

    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.START_ADMIN_CHAT, 
        })  
    }
};