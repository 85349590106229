import React from 'react'
import { APP_NAME } from 'configs/AppConfig';
import { useTranslation } from 'react-i18next';

export default function Footer() {
	const { t, i18n } = useTranslation();
	return (
		<footer className="footer">
			{/* <span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights reserved.</span> */}
			<div>

				{/* <a className="text-gray" href="/#" onClick={e => e.preventDefault()}>{t('privacyTerms')}</a> */}
			</div>
		</footer>
	)
}

