import actionTypes from "utils/redux/actions/actionTypes";

 


const initialState = {
    roomList: [], 
    isLoading: false,
    hasError: false,
    errorMessage: "", 
};
export function roomReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.SELECT_ROOM_LIST_SUCCESS:

            return {
                ...state,
                roomList : action.payload
            };

        case actionTypes.SELECT_ROOM_LIST_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
  
        case actionTypes.SELECT_ROOM_LIST_ERROR:
            return {
                ...state,
                hasError: action.hasError,
                errorMessage: action.errorMessage
            }; 
        default:
            return state;
    }
}
