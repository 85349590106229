import React from "react"



const CustomBadge =()=>{

	return(
        <div style={{
            width:9,
            height:9,
            borderWidth : 1,
            borderStyle:"solid",
            borderColor:"red",
            background:"red",
            borderRadius:20,
            position:"absolute",
            right:10,
            top:10 
        }}>

        </div>
    );
}
export default CustomBadge;