import { notification } from "antd";
import network from "network/network";
import actionTypes from "./actionTypes";



export function selectActivityStatusList() {

    return (dispatch, getState) => {

        var defaultList = getState().activityStatusReducer.activityList;
        if (defaultList && defaultList.length > 0) {
            return;
        }
        dispatch({
            type: actionTypes.SELECT_ACTIVITY_STATUS_LIST_LOADING,
            isLoading: true
        })

        network.get("/admin/Client/selectAllActivityList", null, false).then((result) => {

            dispatch({
                type: actionTypes.SELECT_ACTIVITY_STATUS_LIST_LOADING,
                isLoading: false
            })

            const { data } = result;

            if (result.isSuccess && data.isSuccess) {


                var activityList = (data.data);
                dispatch({
                    type: actionTypes.SELECT_ACTIVITY_STATUS_LIST_SUCCESS,
                    payload: activityList
                })

            } else {
                dispatch({
                    type: actionTypes.SELECT_ACTIVITY_STATUS_LIST_ERROR,
                    errorMessage: result.errorMessage,
                    hasError: true
                })
            }

        });
    }
};

export function changeActivityStatus(params) {

    return (dispatch, getState) => {  

        network.get("/admin/Client/changeCustomerActivityStatus", params, false).then((result) => {

 
            const { data } = result;

            if (result.isSuccess && data.isSuccess) {
                //Müşterinin Activity statusunu değiştir
                
                notification.success({
                    message: `Başarılı`,
                    description: "Aktivite durumu başarılı bir şekilde güncellendi.",
                    placement: "topRight",
                });

                dispatch({
                    type: actionTypes.CHANGE_CUSTOMER_ACTIVITY_STATUS,
                    activityStatus: params.activityStatus,
                    customerId : params.customerId
                })
            } else {

            }
        });
    }

}