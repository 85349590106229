import { Col, Input, InputNumber, Modal, notification, Row, Select } from "antd";
import network from "network/network";
import React, { useState } from "react"
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { insertOrderItem, updateOrderItem } from "utils/redux/actions/orderAction";
const { Option } = Select


const InsertOrderItemModal = React.forwardRef((props, ref) => {


    React.useImperativeHandle(ref, () => ({

        openItemModal(itemId) {

            openItemModal(itemId);

        }
    }));
    const initialItemInfo = {
        itemid: -1,
        orderid: -1,
        price: 0,
        productId: 0,
        quantity: null,
        productName: ""
    };
    const [modalVisible, setModalVisible] = useState(false);
    const [orderItemInfo, setOrderItemInfo] = useState(initialItemInfo);




    const closeModal = () => {

        setModalVisible(false);
    }

    const openItemModal = async (itemId) => {

        setModalVisible(true);
        if (itemId > 0) {

            var index = props.orderItemList.findIndex(w => w.itemId == itemId);
            var item = props.orderItemList[index];

            setOrderItemInfo(item); 
        } else {
            var nextId = generateNextId() + 1;
            setOrderItemInfo({
                ...orderItemInfo,
                itemId: nextId
            });
        }
    }
    function generateNextId() {

        var maxval = 0;
        if (props.orderItemList && props.orderItemList.length > 0) {
            maxval = Math.max.apply(Math, props.orderItemList.map(function (o) { return o.itemId; }))
        }
        return maxval;
    }

    const onInsertClick = () => {
        if (productId > 0 && quantity > 0) {

            if (itemid > 0) {
                props.updateOrderItemAction(orderItemInfo);
            } else {
                props.insertOrderItemAction(orderItemInfo);
            }
            setOrderItemInfo(initialItemInfo);
            setModalVisible(false);

        } else {
            notification.error({
                message: 'Uyarı!',
                description: 'Lütfen zorunlu alanları doldurunuz.',
            });
        }
    }

    const onProductSelect = (val) => {

        var index = props.productList.findIndex(w => w.productid == val);
        var item = props.productList[index];

        setOrderItemInfo({
            ...orderItemInfo,
            productId: val,
            price: item.productprice,
            productName: item.productname
        });

    }

    const getProductPrice = (item) => {
        const price = item.productprice;
        return (
            <NumberFormat
                displayType={'text'}
                value={(Math.round(price * 100) / 100).toFixed(2)}
                prefix={'₺'}
                thousandSeparator={true}
            />

        );
    }
    const { itemid, orderid, price, productId, quantity } = orderItemInfo;

 

    return (
        <Modal visible={modalVisible} onCancel={closeModal} destroyOnClose={closeModal}
            onOk={onInsertClick} maskClosable={false}>
            <Row className="row">
                <Col xs={24} sm={24} md={24}>
                    <div className='ant-col ant-form-item-label'>
                        <label className='ant-form-item-required' htmlFor="productName">Ürün Adı</label>
                    </div>
                    <div>
                        <Select className="w-100" placeholder="Ürün Seçiniz"
                            showSearch
                            onChange={(val) => onProductSelect(val)}
                            defaultValue={productId > 0 ? productId : "Ürün Seçiniz"}
                        >
                            {props.productList.map((item, index) => (
                                <Option key={item.productname} value={item.productid} >
                                    {item.productname} {" - "}
                                    {getProductPrice(item)}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </Col>
            </Row>

            <div className='m-4'>

            </div>
            <div className='ant-col ant-form-item-label'>
                <label className='ant-form-item-required' htmlFor="productName">Ürün Adedi</label>
            </div>
            <div>
                <InputNumber
                    value={quantity}
                    defaultValue={quantity}
                    onChange={(val) => {
                        setOrderItemInfo({
                            ...orderItemInfo,
                            quantity: val
                        });
                    }}
                    className="w-100"
                    // formatter={value => `₺ ${value}`}
                    parser={value => value.replace(/\₺\s?|(,*)/g, '')}
                />
            </div>





        </Modal>
    );
})



function mapStateToProps(state) {

    return {
        isLoading: state.productReducer.isLoading,
        productList: state.productReducer.productList,
        hasError: state.productReducer.hasError,
        errorMessage: state.productReducer.errorMessage,
        customerList: state.customerReducer.customerList,
        orderItemList: state.orderDetailReducer.orderItemList || []
    }
}

function mapDispatchToProps(dispatch) {
    return {
        insertOrderItemAction: bindActionCreators(insertOrderItem, dispatch),
        updateOrderItemAction: bindActionCreators(updateOrderItem, dispatch),
        // selectCustomerListAction: bindActionCreators(selectCustomerList, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(InsertOrderItemModal);
