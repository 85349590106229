
export const MESSAGE_TYPES = {
    TEXT: "text",
    IMAGE: "image",
    FILE: "file",
    AUDIO: "audio",
    VIDEO: "video",
    CONTACT: "contact",
    LOCATION: "location",
    BUTTON_REPPLY: "button_reply",
    LIST_REPPLY: "list_reply",
    STICKER: "text",
    LIST: "list",
    QUICK_REPPLY: "quick_reply",
}

export const TEXT = "text";
export const IMAGE = "image";
export const FILE = "file";
export const AUDIO = "audio";
export const VIDEO = "video";
export const CONTACT = "contact";
export const LOCATION = "location";
export const BUTTON_REPPLY = "button_reply";
export const LIST_REPPLY = "list_reply";
export const STICKER = "text";
export const LIST = "list";
export const QUICK_REPPLY = "quick_reply";

export const MESSAGE_SEND_TYPE = {
    inbound: 1,
    outbound: 2,
    template: 3
}