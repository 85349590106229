
import actionTypes from "utils/redux/actions/actionTypes";




const initialState = {
    activityList: [],
    activityFilterList: [],
    isLoading: false,
    hasError: false,
    errorMessage: "",
};
export function activityStatusReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.SELECT_ACTIVITY_STATUS_LIST_SUCCESS:
            var filters = [];
            for (let i = 0; i < action.payload.length; i++) {
                const element = action.payload[i];
                filters.push({
                    text: element.statusdesc,
                    value: element.id,
                },);
            }


            return {
                ...state,
                activityList: action.payload,
                activityFilterList : filters
            };

        case actionTypes.SELECT_ACTIVITY_STATUS_LIST_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };

        case actionTypes.SELECT_ACTIVITY_STATUS_LIST_ERROR:

            return {
                ...state,
                hasError: action.hasError,
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
}