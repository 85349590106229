import React, { useState } from 'react'
import { Badge, Input } from 'antd';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import { COLOR_1 } from 'constants/ChartConstant';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Tabs, } from 'antd';
import globalConstant from 'constants/globalConstant';
import CustomBadge from 'components/CustomBadge';

const { TabPane } = Tabs;

const ChatMenu = ({ chatList, isLoading, resetUnreadCountactions, onChangeChatIndex, activeNumber }) => {


	const [searchText, setSearchText] = useState("");
	const [activeTab, setActiveTab] = useState("1");
	const openChat = id => {
		// var lastChar = match.url.substr(match.url.length - 1);
		// var split = "/";
		// if (lastChar == "/") {
		// 	split = "";
		// }
		onChangeChatIndex(id);
		// history.push(`${match.url + split}${id}`)

		resetUnreadCountactions(id);
	}

	const searchOnChange = e => {
		const query = e.target.value;
		setSearchText(query);
	}

	//const id = parseInt(location.pathname.match(/\/([^/]+)\/?$/)[1])


	if (isLoading) {

		return (
			<Spin tip="Mesajlar Yükleniyor..." style={{ width: "100%", height: "100%", marginTop: 25 }}>
			</Spin>
		);
	}
	var newchatList = JSON.parse(JSON.stringify(chatList));
	if (searchText.length > 0) {
		newchatList = newchatList.filter(function (el) {
			return (el.customerNumber.toUpperCase().indexOf(searchText.toUpperCase()) != -1 || (el.customerName || "").toUpperCase().indexOf(searchText.toUpperCase()) != -1)
		});
	}

	const getAdminTransactionBadge = (item) => {

		if (item.isAdminTransaction) {

			return (
				<CustomBadge>

				</CustomBadge>
			);
		}
	}

	const getTabMessageList = () => {
		var tabData = JSON.parse(JSON.stringify(newchatList));
		if (activeTab == "2") {
			tabData = tabData.filter(function (el) {
				return (el.isAdminTransaction);
			});
		} 

		return tabData.map((item, i) => (
			<div
				style={{
					position: "relative"
				}}
				key={`chat-item-${item.customerNumber}`}
				onClick={() => openChat(item.customerNumber)}
				className={`chat-menu-list-item ${i === (newchatList.length - 1) ? 'last' : ''} ${item.customerNumber == activeNumber ? 'selected' : ''}`}
			>
				{getAdminTransactionBadge(item)}
				<AvatarStatus src={globalConstant.imageTypes.getImageUrl(globalConstant.imageTypes.customerImage.type, item.customerImage)}
					name={item.customerName} subTitle={item.lastWrittenMessage} />
				<div className="text-right">
					<div className="chat-menu-list-item-time">{item.time}</div>
					{item.unreadMessageCount === 0 ? <span></span> : <Badge count={item.unreadMessageCount} style={{ backgroundColor: COLOR_1 }} />}
				</div>
			</div>
		))
	}

	const getChatTab = () => {


		return (
			<Tabs defaultActiveKey={activeTab} onChange={setActiveTab}>
				<TabPane tab="Tüm Mesajlar" key="1">
					{getTabMessageList("1")}
				</TabPane>
				<TabPane tab="Müşteri Temsilcisine bağlananlar" key="2">
					{getTabMessageList("2")}
				</TabPane>
			</Tabs>
		);
	}

	return (
		<div className="chat-menu">

			<div className="chat-menu-toolbar">
				<Input
					placeholder="Mesajlarda Ara"
					onChange={searchOnChange}
					prefix={
						<SearchOutlined className="font-size-lg mr-2" />
					}
				/>
			</div>
			<div className="chat-menu-list">
				{getChatTab()}

			</div>
		</div>
	)
}

export default ChatMenu
