import { render } from "@testing-library/react";
import { Button, Card, Col, Input, Modal, Row, Spin, Table, Tag } from "antd";
import network from "network/network";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectCustomerList as fetchCustomerList } from "utils/redux/actions/customerActions"; // Renamed
import { DashboardOutlined, ExportOutlined, LoadingOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import tools from 'tools';
import * as XLSX from 'xlsx';
import AvatarStatus from "components/shared-components/AvatarStatus";
import DetailDashboardCustomer from "./detail-customer";
import { t } from "i18next";

const CustomerDashboard = (props) => {
    const [customerData, setCustomerData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [isModal, setIsmodal] = useState(false);
    const [customerId, setCustomerId] = useState(0);
    const navigate = useNavigate();

    const branchColors = {
        1: "#FF6A00",
        3: "rgb(204, 153, 0)",
        4: "#10375C",

    }

    useEffect(() => {
        if (!isModal) {
            selectCustomerList();
        }

    }, [isModal]);

    const selectCustomerList = async () => {
        var params = {};
        props.selectCustomerListAction(params);

        // setLoading(true);

        var result = await network.get("/admin/Client/GetCustomersWithDetail", params, false);

        // setTimeout(() => {
        //     setLoading(false);
        // }, (100));

        if (result.isSuccess && result.data.isSuccess) {
            const uniqueData = result.data.data.filter((value, index, self) => 
                index === self.findIndex((t) => t.customerid === value.customerid)
            );
            

            setCustomerData(uniqueData);
            setFilteredData(uniqueData);
        }
        console.log("With Detail", result);

        // Initialize filtered data
    }

    const onSearch = (e) => {
        const value = e.currentTarget.value;
        setSearchValue(value);

        if (value.length > 0) {
            const filtered = customerData.filter((item) => {
                return (
                    item.customername.toUpperCase().includes(value.toUpperCase()) ||
                    item.customernumber.toUpperCase().includes(value.toUpperCase()) ||
                    item.email?.toUpperCase().includes(value.toUpperCase()) ||
                    item.branchname?.toUpperCase().includes(value.toUpperCase())
                );
            });
            setFilteredData(filtered); // Update filtered data
        } else {
            setFilteredData(customerData); // Reset to original data
        }
    }

    const tableColumns = [
        {
            title: t('customerName'),
            dataIndex: "customername",
            sorter: (a, b) => tools.antdTableSorter(a, b, 'customername'),
            render: (_, record) => (
                <div className="d-flex align-items-center">
                    <AvatarStatus src={"/img/avatars/user-blank-image.jpg"}></AvatarStatus>
                    <h5>{record.customername}</h5>
                </div>
            ),
            shouldCellUpdate: (record, prevRecord) => record.customername !== prevRecord.customername,
        },
        {
            title: t('customerNumber'),
            dataIndex: "customernumber",
            sorter: (a, b) => tools.antdTableSorter(a, b, 'customernumber'),
            render: (_, record) => (
                <div>
                    +{record.customernumber}
                </div>
            ),
            shouldCellUpdate: (record, prevRecord) => record.customernumber !== prevRecord.customernumber,

        },
        {
            title: t('regDate'),
            dataIndex: "insertdate",
            sorter: (a, b) => tools.antdTableSorter(a, b, 'insertdate'),
            render: (_, record) => (
                <div className="font-weight-normal">
                    {new Date(record.insertdate).toLocaleString('tr-TR').replace(/:\d{2}$/, '')}
                </div>
            ),
            shouldCellUpdate: (record, prevRecord) => record.insertdate !== prevRecord.insertdate,
        },
        {
            title: "E-Mail",
            dataIndex: "email",
            sorter: (a, b) => tools.antdTableSorter(a, b, 'email'),
            render: (_, record) => (
                <div>
                    {record.email != null ? record.email : '-'}
                </div>
            ),
            shouldCellUpdate: (record, prevRecord) => record.email !== prevRecord.email,
        },
        {
            title: t('Branch'),
            dataIndex: "branchname",
            sorter: (a, b) => tools.antdTableSorter(a, b, 'branchname'),
            render: (_, record) => (
                <div>
                    {record.branchname != null ? <Tag bordered={true} style={{
                        borderColor: `${branchColors[record.branchid]}`, // 33 is the opacity in HEX (20% opacity)
                        color: `${branchColors[record.branchid]}`,
                        fontWeight: "bold" // Text color remains fully opaque
                        // Optional: Custom border color if you want it
                    }} >{record.branchname}
                    </Tag> : '-'}
                </div>
            ),
            shouldCellUpdate: (record, prevRecord) => record.branchname !== prevRecord.branchname,
        },
        {
            title: t('customerDetail'),
            dataIndex: "",
            render: (_, record) => (
                <div className="d-flex justify-content-center align-items-center">
                    <Button
                        style={{ width: "40px" }}
                        variant="outlined"
                        shape="circle"
                        className="mx-auto"
                        color="default"
                        icon={<ExportOutlined />}
                        onClick={() => openModal(record.customerid)}
                    />
                </div>
            )
        }
    ]

    const handleExcelExport = () => {
        const excelData = filteredData.map((record) => ({
            [t('customerNumber')]: record.customernumber,
            [t('customerName')]: record.customername,
            [t('regDate')]: new Date(record.insertdate).toLocaleDateString(),
            [t('Email')]: record.email,
            [t('Branch')]: record.branchname
        }));

        // Create a new worksheet and add the data
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");

        // Export to Excel
        XLSX.writeFile(workbook, "CustomerData.xlsx");
    }

    const openModal = (id) => {
        setIsmodal(true);
        setCustomerId(id);
    }

    return (
        <div>
            {loading ? (<div className="loading-overlay"><Spin indicator={<LoadingOutlined spin />} size="large" spinning={loading}></Spin></div>) : (
                <>
                    <div>
                        <Row className="mb-3" justify={"space-between"}>

                            <Col span={4}>
                                <Input
                                    placeholder={t('searchInCustomers')}
                                    prefix={<SearchOutlined />}
                                    onChange={onSearch}
                                />
                            </Col>
                            <Col span={2}>
                                <Button style={{ width: "100%" }} type="primary" onClick={handleExcelExport}>
                                    {t('Export')}
                                </Button>
                            </Col>
                        </Row>

                        <Card>
                            <div className="table-responsive">
                                <Table
                                    columns={tableColumns}
                                    dataSource={filteredData}
                                    size="middle"
                                    rowKey={"customerid"}
                                />
                            </div>
                        </Card>
                    </div>

                    <Modal title={t('customerInfoCard')} open={isModal} width={800} onCancel={() => setIsmodal(false)} footer={null}>
                        <DetailDashboardCustomer customerId={customerId} />
                    </Modal>
                </>
            )}


        </div>


    );
}

function mapStateToProps(state) {
    return {
        customerList: state.customerReducer.customerList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectCustomerListAction: bindActionCreators(fetchCustomerList, dispatch), // Renamed
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDashboard);
