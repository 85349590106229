import React, { Component, useEffect, useState } from 'react'
import network from 'network/network';
import Flex from 'components/shared-components/Flex';
import { ArrowUpOutlined, SortDescendingOutlined, SortAscendingOutlined, RollbackOutlined, ArrowDownOutlined, ArrowRightOutlined, EyeOutlined, EditOutlined, SearchOutlined, PlusCircleOutlined, MessageOutlined, DeleteOutlined, CopyOutlined, FieldTimeOutlined, TeamOutlined, CheckOutlined, ReadOutlined } from '@ant-design/icons';
import { Modal, Col, Card, Table, Select, Input, Button, Badge, Menu, notification, Spin, Tag, Row, Tooltip } from 'antd';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import { Progress } from 'antd';

const Campaigns = (props) => {

    const [control, setControl] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [selectValue, setSelectValue] = useState("");
    const [selectStatusValue, setSelectStatusValue] = useState("");
    const [datas, setDatas] = useState([]);

    const onSearch = e => {
        const value = e.currentTarget.value
        setSearchValue(value);
    }
    const onSelectStatusFilter = e => {
        const value = e.value
        setSelectStatusValue(value);
    }
    const onSelectFilter = e => {
        const value = e.value
        setSelectValue(value);

    }
    const getStatusColor = (status) => {
        if (status == 1) {
            return "green";
        }
        else {
            return "orange";
        }

    }
    const SplitDate = (date) => {
        var aylar = new Array("Ocak", "Şubat", "Mart",
            "Nisan", "Mayıs", "Haziran", "Temmuz", "Agustos", "Eylül", 
            "Ekim", "Kasım", "Aralık");
        var t = new Date(date);
        var gun = t.getDate();
        var ay = t.getMonth();
        var yil = t.getFullYear();
        // document.write(gun + "." + aylar[ay] + "-" + yil);
        var newdate = date.split('T')
        console.log(gun + "/" + aylar[ay] + "/" + yil)
        //    let msec = Date.parse("March 21, 2012");
        //    const d = new Date(msec);
        return <>{(gun + " " + aylar[ay] + " " + yil)}</>

    }
    const getStatusText = (status) => {
        if (status == 1) {
            return "Complated";

        }
        else {
            return "Draft";
        }

    }

    useEffect(() => {
        responseData();

    }, [])

    const responseData = async () => {
        var result = await network.get("/admin/Campaign/selectCampaignList", false);
        if (result.isSuccess) {
            var data = result.data;
            setDatas(data.data);
            setControl(true);
            console.log(data.data)
        } else {
            alert(result.errorMessage);
        }

    }

    const info = () => {
        Modal.info({
            title: 'Gönderilen Mesaj Türü : Text',
            content: (
                <Col style={{ background: "#e5ddd5", borderRadius: 10 }} className='mt-1' >
                    <Row>
                        <Col span={4}>
                        </Col>
                        <Col span={20} >

                            <div className=" mt-4 mr-2 p-3 " style={{ background: "#d3eebe", borderRadius: 10 }}>
                                <div align="center" className='mb-1' >
                                    <img style={{ borderRadius: 7 }} src="https://i.hizliresim.com/7kb2hsj.png" width={"100%"} height={"100%"} />
                                </div>
                                <div className=' ml-2 mr-2 p-1 d-flex align-items-center justify-content-center' >
                                </div>
                                Merhaba bu Test1 tarafından gönderilmiş Test mesajıdır lütfen dikkate almayınız
                                Merhaba bu Test1 tarafından gönderilmiş Test mesajıdır lütfen dikkate almayınız
                            </div>
                            <div className='my-1 '></div>
                            <Col span={24}>
                                <div className=' mr-2 p-1 d-flex align-items-center justify-content-center' style={{ background: "#d3eebe", borderRadius: 5 }} >
                                    <a>Ödeme Yap<RollbackOutlined className='ml-2' style={{ fontSize: '18px' }} /></a>
                                </div>
                                <div className='my-1 '></div>
                                <div className='mr-2 p-1 d-flex align-items-center justify-content-center mb-3' style={{ background: "#d3eebe", borderRadius: 5 }} >
                                    <a>Evet<ArrowRightOutlined className='ml-2' style={{ fontSize: '18px' }} /></a>
                                </div>

                            </Col>
                        </Col>

                    </Row>

                </Col>
            ),
            onOk() { },
        });
    };

    const dropdownMenu = row => (
        <Menu>
            <Menu.Item >
                <Flex alignItems="center">
                    <EditOutlined />
                    <span className="ml-2">Düzenle</span>
                </Flex>
            </Menu.Item>
            <Menu.Item >
                <Flex alignItems="center">
                    <CopyOutlined />
                    <span className="ml-2">Tekrar Gönder</span>
                </Flex>
            </Menu.Item>
            <Menu.Item >
                <Flex alignItems="center">
                    <DeleteOutlined />
                    <span className="ml-2">Sil</span>
                </Flex>
            </Menu.Item>
        </Menu>
    );
    const [columns, setColumns] = useState([
        {
            title: 'Kampanya Detayları',
            dataIndex: 'campaignsName',
            key: 'campaignsName',
            width: '250px',

            render: (_, record) => (
                <div>
                    <div class="flex-container d-inline">
                        <div class="item mb-2 px-2 pb-3 font-weight-bold">{record.campaignName}
                            <Tag className="ml-1" color={getStatusColor(record.campaignStatus)}>{getStatusText(record.campaignStatus)}</Tag>
                        </div>

                        <div class="item my-1 p-1 ml-1">
                            <Tooltip title={`${record.firmBusinessNumber}`} >
                                Gbm Yazılım Şirketi
                            </Tooltip>
                        </div>
                        <div class="item mt-1 mb-1 p-1">
                            <span style={{ fontSize: '16px' }} className='ml-1 mr-1'><FieldTimeOutlined /> </span>

                            {SplitDate(record.insertDate)}

                        </div>

                    </div>
                </div>
            )
        },
        {
            title: 'Gönderilen Gruplar',
            dataIndex: 'sendGrup',
            width: '180px',
            key: 'sendGrup',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'createDate'),
            align: 'center',
            render: (_, record) => (
                <div>

                    {record.campaignGroups.map(index => <Tag className='m-1' color={"volcano"}>{index.groupname} </Tag>)}

                </div>
            )
        },
        {
            title: 'Gönderim Detayları',
            dataIndex: 'recipients',
            key: 'recipients',
            width: '230px',
            align: 'center',

            render: (_, record) => (
                <>
                    <div className='mt-2 mb-1'>
                        <Tooltip title="Alıcı/Teslim Edilen: %80  Alıcı/Okunan: %70">
                            <Progress percent={(record.statistics.deliveredCount) / (record.statistics.totalPersonCount) * 100} success={{ percent: '${(record.statistics.readCount)/(record.statistics.totalPersonCount)*100}' }} width={70} type="circle" />
                        </Tooltip>
                    </div>
                    <div className="d-inline">
                        <div className="d-inline-block  mt-2 " style={{ width: "80px", height: "50px" }}>
                            <div className='d-block ' style={{ fontSize: '20px' }}><TeamOutlined /></div>
                            <div style={{ fontSize: '11px' }}>{record.statistics.totalPersonCount} Alıcı</div>
                        </div>
                        <div className="d-inline-block  " style={{ width: "80px", height: "50px" }}>
                            <span className='d-block ' style={{ fontSize: '20px' }}>   <h2 style={{ margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 18 18" height="21" width="21" preserveAspectRatio="xMidYMid meet" class="dh5rsm73 hpdpob1j" version="1.1" x="0px" y="0px" enable-background=""><title>status-dblcheck</title><path fill="currentColor" d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"></path></svg></h2></span>
                            <span className='d-block' style={{ fontSize: '11px' }} >{record.statistics.deliveredCount} Teslim Edilen </span>
                        </div>
                        <div className="d-inline-block  " style={{ width: "80px  ", height: "50px" }}>
                            <span className='d-block ' style={{ fontSize: '20px' }}>   <h2 style={{ color: "#53bdea", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 18 18" height="21" width="21" preserveAspectRatio="xMidYMid meet" class="dh5rsm73 hpdpob1j" version="1.1" x="0px" y="0px" enable-background=""><title>status-dblcheck</title><path fill="currentColor" d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"></path></svg></h2></span>
                            <span className='d-block ' style={{ fontSize: '11px' }} >{record.statistics.readCount} Okunan</span>
                        </div>
                    </div></>
            )
        },
        {
            title: '',
            dataIndex: 'id',
            width: 200,
            align: 'center',
            render: (_, elm) => {

                return (
                    <div  >
                        <span>
                            <Tooltip title="Kampanya Detayını Göster">
                                <Button className="rounded-circle  ml-1 " style={{ width: '40px', height: '40px' }} aria-label="Favorite">
                                    <a className=' d-flex  justify-content-center' href={"campaign-detail"} > <EyeOutlined style={{ fontSize: '19px' }} /></a>
                                </Button>


                            </Tooltip>
                            <Tooltip title="Mesaj Göster">

                                <Button className="rounded-circle  ml-1 " style={{ width: '40px', height: '40px' }} aria-label="Favorite">
                                    <a onClick={info} className=' d-flex  justify-content-center'  > <MessageOutlined style={{ fontSize: '19px' }} /></a>
                                </Button>

                            </Tooltip>
                        </span>

                        <span className='mb-2 ml-1'>
                            <EllipsisDropdown className="mb-3" menu={dropdownMenu(elm)} />
                        </span>


                    </div>


                )
            }

        },

    ])
    var data = JSON.parse(JSON.stringify(datas));
    if (searchValue.length > 0) {
        data = data.filter(function (el) {
            return (el.campaignName.toUpperCase().indexOf(searchValue.toUpperCase()) != -1 ||
                (el.campaignID || "").toString().toUpperCase().indexOf(searchValue.toUpperCase()) != -1
            )

        });
    }

    if (selectStatusValue != "0") {
        data = data.filter(function (el) {
            return (
                el.campaignStatus.toString().toUpperCase().indexOf(selectStatusValue.toUpperCase()) != -1
            )
        });

    }
    if (selectValue == "0") {

        data = data.sort(function (a, b) {
            console.log(data)
            var firstValue = a.campaignName.toUpperCase();
            var secondValue = b.campaignName.toUpperCase();
            if (firstValue < secondValue) { return -1; }
            if (firstValue > secondValue) { return 1; }
            return 0;
        });
    }
    if (selectValue == "1") {
        data = data.sort(function (a, b) {
            var firstValue = a.campaignName.toUpperCase();
            var secondValue = b.campaignName.toUpperCase();
            if (firstValue < secondValue) { return 1; }
            if (firstValue > secondValue) { return -1; }
            return 0;
        });
    }
    if (selectValue == "2") {
        data = data.sort(function (a, b) {
            var firstValue = a.insertDate;
            var secondValue = b.insertDate;
            if (firstValue < secondValue) { return 1; }
            if (firstValue > secondValue) { return -1; }
            return 0;
        });
    }
    if (selectValue == "3") {
        data = data.sort(function (a, b) {
            var firstValue = a.insertDate;
            var secondValue = b.insertDate;
            if (firstValue < secondValue) { return -1; }
            if (firstValue > secondValue) { return 1; }
            return 0;
        });
    }




    return (
        <div>

            <Card>

                <div className='mt-2 pt-3 mb-2 pb-2 ml-2'>
                    <h1>WhatsApp Kampanyaları</h1>
                </div>
                <div className='mt-2 pt-2 mb-2 pb-2 ml-2'>
                    <h3>WhatsApptaki kişilerinize pazarlama kampanyası başlatın...</h3>
                </div>
                <Flex className="mt-4" alignItems="center" justifyContent="between" mobileFlex={false}>
                    <Flex className="mb-1" mobileFlex={false}>
                        <div className="mr-md-3 mb-3">
                            <Input placeholder="Kampanyalarda Ara" prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
                        </div>
                        <div className="mr-md-3 mb-3">
                            <Select
                                onChange={e => onSelectStatusFilter(e)}
                                id='stateSelect'
                                labelInValue
                                defaultValue={"All"}
                                style={{ width: 180 }}
                            >
                                <Select.Option value="0">All</Select.Option>
                                <Select.Option value="1">Completed</Select.Option>
                                <Select.Option value="2">Draft</Select.Option>

                            </Select>
                        </div>
                        <div className="mr-md-3 mb-3">
                            <Select
                                onChange={e => onSelectFilter(e)}

                                labelInValue
                                placeholder={"Sıralama"}
                                style={{ width: 180 }}
                            >
                                <Select.Option value="0">
                                    <div>
                                        <SortAscendingOutlined className='mr-1' style={{ fontSize: '16px' }} />   Kampanya Adı
                                    </div>
                                </Select.Option>
                                <Select.Option value="1">
                                    <div >
                                        <SortDescendingOutlined className='mr-1' style={{ fontSize: '18px' }} />   Kampanya Adı
                                    </div>
                                </Select.Option>
                                <Select.Option value="2">
                                    <div>
                                        <ArrowDownOutlined className='mr-1' style={{ fontSize: '18px' }} />    Oluşturulma Tarihi
                                    </div> </Select.Option>
                                <Select.Option value="3">
                                    <div>
                                        <ArrowUpOutlined className='mr-1' style={{ fontSize: '18px' }} />    Oluşturulma Tarihi
                                    </div>
                                </Select.Option>
                            </Select>
                        </div>

                    </Flex>
                    <Flex className="mb-1" mobileFlex={false}>
                    </Flex>
                    <Flex className="mb-1" alignItems="center" justifyContent="between" mobileFlex={false}>
                        <div className="mr-md-3 mb-3">
                            <Button href={"campaign-create"} type="primary" icon={<PlusCircleOutlined />} block>Kampanya Oluştur</Button>
                        </div>
                    </Flex>
                </Flex>
                <div className="table-responsive mt-4">
                    <Table
                        columns={columns}
                        dataSource={data}
                        rowKey='id'
                    />
                </div>
            </Card>
        </div>
    );

}

export default Campaigns 