import { API_FULL_URL } from "constants/ApiConstant"; 


const apiOperations={


    GetServiceAddress: async function () {
        return API_FULL_URL;
        // return this.serviceAddress + this.serviceName + this.svcPath ;
    },
    objToQueryString(obj) {
        const keyValuePairs = [];
        for (const key in obj) {

            keyValuePairs.push((key) + '=' + (obj[key]));
        }
        return keyValuePairs.join('&');
    },
    apiSuccess(result =[]) {
        return ({
            isSuccess: true,
            data: result,
            errorMessage: ""
        });
    },
    apiError(errorMsg) {
        return ({
            isSuccess: false,
            errorMessage: errorMsg,
            data: null
        });
    },
    
}

export default apiOperations;