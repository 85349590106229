import React, { useEffect, useState } from 'react'
import ChatContent from './ChatContent';
import ChatMenu from './ChatMenu';
import { resetUnreadCount, selectChatList } from 'utils/redux/actions/chatActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import InnerAppLayout from 'pages/inner-app-layout';

const Chat = props => {

	useEffect(() => {
		var params = { 
		}
		props.selectChatListAction(params);
	}, []); 
	const [activeNumber,setActiveNumber] = useState(""); 

	const onChangeChatIndex =(number)=>{
		setActiveNumber(number);
	}


	return (
		<div className="chat">
			<Helmet>
				<title>Mesaj Listesi</title>
			</Helmet>
			<InnerAppLayout
				sideContent={<ChatMenu {...props} activeNumber={activeNumber} onChangeChatIndex={onChangeChatIndex} />}
				mainContent={<ChatContent {...props} activeNumber={activeNumber} />}
				sideContentWidth={450}
				sideContentGutter={false}
				border
				pageHeader={false}
			/>
		</div>
	)
}


function mapStateToProps(state) {

	return {
		isLoading: state.chatReducer.isLoading,
		chatList: state.chatReducer.chatList,
		hasError: state.chatReducer.hasError,
		errorMessage: state.chatReducer.errorMessage,


	}
}

function mapDispatchToProps(dispatch) {
	return {
		selectChatListAction: bindActionCreators(selectChatList, dispatch),
		resetUnreadCountactions: bindActionCreators(resetUnreadCount, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
