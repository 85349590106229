/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Card, Table, Select, Input, Button, Badge, Menu, Tag, Popconfirm, notification, Spin } from 'antd';
import OrderListData from "assets/data/order-list.data.json"
import { EyeOutlined, FieldTimeOutlined, SearchOutlined, PlusCircleOutlined, LoadingOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import Flex from 'components/shared-components/Flex'
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { DATE_FORMAT_DD_MM_YYYY } from 'constants/DateConstant'
import tools from 'tools'
import { selectOrderList } from 'utils/redux/actions/orderAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { API_BASE_URL } from 'constants/ApiConstant';
import globalConstant from 'constants/globalConstant';
import { useNavigate } from 'react-router-dom';
import helperFunc from 'helpers/helperFunc';
import network from 'network/network';
import { Helmet } from 'react-helmet';


const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />

const { Option } = Select



const getShippingStatus = status => {
	if (status && status != 1) {
		if (status == 2) {
			return 'blue'
		} else if (status == globalConstant.orderStatus.cancelOrder) {
			return 'red'
		} else {
			return 'cyan'
		}
	} else {
		return 'orange'
	}
}
const getShippingStatusText = (status) => {
	if (status && status != 1) {
		if (status == 2) {
			return "Yolda"
		} else if (status == globalConstant.orderStatus.cancelOrder) {
			return "İptal Edildi"
		} else {
			return "Teslim Edildi"
		}
	} else {
		return "Hazırlanıyor"
	}
}
const getPaymentStatusText = (status, paymentType) => {


	if (paymentType == globalConstant.paymentType.cashOnDelivery) {
		return "Kapıda Ödeme";
	} else {
		if (status && status != globalConstant.paymentStatus.waitForPayment) {
			if (status == globalConstant.paymentStatus.paymentComplete) {
				return "Ödeme Alındı";
			} else {
				return "Ödeme Hataya Düştü";
			}
		} else {
			return "Online Ödeme Bekleniyor";
		}
	}
}
const getPaymentStatusColor = (status, paymentType) => {
	if (paymentType == globalConstant.paymentType.cashOnDelivery) {
		return "blue";
	} else {
		if (status && status != globalConstant.paymentStatus.waitForPayment) {
			if (status == globalConstant.paymentStatus.paymentComplete) {
				return "green";
			} else {
				return "red";
			}
		} else {
			return "orange";
		}
	}
}

const Orders = (props) => {

	const navigate = useNavigate();

	const [list, setList] = useState(OrderListData)
	const [selectedRows, setSelectedRows] = useState([])
	const [selectedRowKeys, setSelectedRowKeys] = useState([])
	const [loadingPanel, setLoadingPanel] = useState(false)
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		selectOrderList();
	}, []);

	const selectOrderList = () => {

		var params = {
		}
		props.selectOrderListAction(params);
	}


	const handleShowStatus = value => {
		if (value !== 'All') {
			const key = 'paymentStatus'
			const data = tools.filterArray(OrderListData, key, value)
			setList(data)
		} else {
			setList(OrderListData)
		}
	}

	const rowSelection = {
		onChange: (key, rows) => {
			setSelectedRows(rows)
			setSelectedRowKeys(key)
		}
	};

	const onSearch = e => {
		const value = e.currentTarget.value
		setSearchValue(value);
		// const searchArray = e.currentTarget.value ? list : OrderListData
		// const data = utils.wildCardSearch(searchArray, value)
		// setList(data)
		// setSelectedRowKeys([])
	}
	const goToOrderDetail = (row) => {
		navigate("/order/detail/" + row.ORDERID);
	}

	const changeOrderStatusConfirm = async (row, status) => {


		setLoadingPanel(true);
		var params = {
			orderId: row.ORDERID,
			status: status
		}

		var result = await network.get("/admin/orders/updateOrderStatus", params, false);

		setTimeout(() => {
			setLoadingPanel(false);
		}, 250);

		if (result.isSuccess) {

			var data = result.data;
			if (data.isSuccess) {

				notification.success({
					message: `Başarılı`,
					description: "Sipariş durumu başarılı bir şekilde değiştirildi.",
					placement: "topRight",
				});
				selectOrderList();

			} else {
				notification.error({
					message: `Hata!`,
					description: data.errorMessage,
					// placement:"topRight",
				});
			}
		} else {
			notification.error({
				message: `Hata!`,
				description: result.errorMessage.message,
				placement: "topRight",
			});
		}
	}

	const changeOrderStatusClick = (row, status) => {


		helperFunc.showConfirm({
			content: `Sipariş durumu değiştirilecek. Devam edilsin mi?`,
			title: "Hatırlatma", onConfirm: () => changeOrderStatusConfirm(row, status)
		});
	}

	const orderSyncClickConfirm= async(row)=>{
		setLoadingPanel(true);
		var params = {
			orderId: row.ORDERID, 
		}

		var result = await network.get("/admin/orders/syncOrder", params, false);

		setTimeout(() => {
			setLoadingPanel(false);
		}, 250);

		if (result.isSuccess) {

			var data = result.data;
			if (data.isSuccess) {

				notification.success({
					message: `Başarılı`,
					description: "Sipariş başarılı bir şekilde aktarılmıştır.",
					placement: "topRight",
				});
				selectOrderList();

			} else {
				notification.error({
					message: `Hata!`,
					description: data.errorMessage,
					// placement:"topRight",
				});
			}
		} else {
			notification.error({
				message: `Hata!`,
				description: result.errorMessage.message,
				placement: "topRight",
			});
		}

	}

	const orderSyncClick = (row, status) => {


		helperFunc.showConfirm({
			content: `Sipariş Javsuya aktarılacak. Devam edilsin mi?`,
			title: "Hatırlatma", onConfirm: () => orderSyncClickConfirm(row, status)
		});
	}

	const getNexStatusId = (row) => {

		const status = row.ORDERSTATUS || globalConstant.orderStatus.inProgress;
		if (status == globalConstant.orderStatus.inProgress) {

			return globalConstant.orderStatus.onDelivery;
		}
		if (status == globalConstant.orderStatus.onDelivery) {

			return globalConstant.orderStatus.deliveryComplete;
		}
	}

	const getCancelOrderMenuItem = (row) => {

		if (row.ORDERSTATUS !== globalConstant.orderStatus.deliveryComplete) {
			return {
				
				label:"İptal Et",
				key: '51',
				icon: <EyeOutlined />,
				onClick: ()=> changeOrderStatusClick(row, globalConstant.orderStatus.cancelOrder) 
			}
		}
	}

	const getEditOrderButton = (row) => {

		if (row.ORDERSTATUS !== globalConstant.orderStatus.deliveryComplete) {
			return {
				
					label:"Düzenle",
					key: '5',
					icon: <EditOutlined />,
					onClick: ()=> navigate(`/order/edit/${row.ORDERID}`) 
			}
		}
	}

	const getNextStatusMenu = (row) => {

		const { ORDERSTATUS } = row;
		if (ORDERSTATUS == globalConstant.orderStatus.deliveryComplete || ORDERSTATUS == globalConstant.orderStatus.cancelOrder) {

			return;
		}
		var text = "";
		var nextStatusId = getNexStatusId(row);

		if (nextStatusId == globalConstant.orderStatus.onDelivery) {
			text = "Yola Çıktı Olarak Değiştir";
		}
		if (nextStatusId == globalConstant.orderStatus.deliveryComplete) {
			text = "Tamamlandı Olarak Değiştir";
		}
		return {
				
			label:text,
			key: '33',
			icon: <FieldTimeOutlined />,
			onClick: ()=> changeOrderStatusClick(row, nextStatusId) 
		}
	}
	const getOrderSyncButton =(row)=>{

		if (row.ISORDERSYNC != true) {
		return {
				
			label:"Siparişi Aktar",
			key: '31',
			icon: <CheckOutlined />,
			onClick: ()=> orderSyncClick(row) 
		}
		}

	}

	const dropdownMenu = row => {
		return [
			{
			  label:"Detayı Göster",
			  key: '0',
			  icon: <EyeOutlined />,
			  onClick: ()=> goToOrderDetail(row)
			},
			
			getEditOrderButton(row),
			getNextStatusMenu(row),
			getCancelOrderMenuItem(row),
			getOrderSyncButton(row)
		  ];
		} 

	const tableColumns = [
		{
			title: 'id',
			dataIndex: 'ORDERID',
			sorter: (a, b) => tools.antdTableSorter(a, b, 'ORDERID')
		},
		{
			title: 'Müşteri Adı',
			dataIndex: 'CLIENTNAME',
			sorter: (a, b) => tools.antdTableSorter(a, b, 'CUSTOMERNAME')
		},
		{
			title: 'Sipariş Tarihi',
			dataIndex: 'ORDERDATE',
			sorter: (a, b) => tools.antdTableSorter(a, b, 'ORDERDATE')
		},
		{
			title: 'Sipariş Durumu',
			dataIndex: 'ORDERSTATUS',
			render: (_, record) => (
				<><Tag color={getShippingStatus(record.ORDERSTATUS)}>{getShippingStatusText(record.ORDERSTATUS)}</Tag></>
			),
			sorter: (a, b) => tools.antdTableSorter(a, b, 'ORDERSTATUS')
		},
		{
			title: 'Ödeme Durumu',
			dataIndex: 'PAYMENTSTATUS',
			render: (_, record) => (
				<Tag color={getPaymentStatusColor(record.PAYMENTSTATUS, record.PAYMENTTYPE)}>
					{getPaymentStatusText(record.PAYMENTSTATUS, record.PAYMENTTYPE)}
				</Tag>
			),
			sorter: (a, b) => tools.antdTableSorter(a, b, 'PAYMENTSTATUS')
		},
		{
			title: 'Aktarım Durumu',
			dataIndex: 'ISORDERSYNC',
			render: (_, record) => (
				<Tag color={record.ISORDERSYNC == true ? "green" : "red"}>
					{record.ISORDERSYNC == true ? "Aktarıldı" : "Aktarılamadı"}
				</Tag>
			),
			sorter: (a, b) => tools.antdTableSorter(a, b, 'ISORDERSYNC')
		},
		{
			title: 'Toplam Tutar',
			dataIndex: 'TOTALAMOUNT',
			render: (_, record) => (
				<span className="font-weight-semibold">
					<NumberFormat
						displayType={'text'}
						value={(Math.round(record.TOTALAMOUNT * 100) / 100).toFixed(2)}
						prefix={'₺'}
						thousandSeparator={true}
					/>
				</span>
			),
			sorter: (a, b) => tools.antdTableSorter(a, b, 'TOTALAMOUNT')
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right">
					<EllipsisDropdown menu={{items:dropdownMenu(elm)}} />
				</div>
			)
		}
	];


	var data = JSON.parse(JSON.stringify(props.orderList));
	if (searchValue.length > 0) {
		data = data.filter(function (el) {
			return (el.CUSTOMERNAME.toUpperCase().indexOf(searchValue.toUpperCase()) != -1 ||
				(el.ORDERID || "").toString().toUpperCase().indexOf(searchValue.toUpperCase()) != -1 ||
				(el.TOTALAMOUNT || "").toString().toUpperCase().indexOf(searchValue.toUpperCase()) != -1)
		});
	}



	return (
		<Spin spinning={loadingPanel} indicator={Icon} >
			<Helmet>
				<title>Sipariş Listesi</title>
			</Helmet>
			<Card>
				<Flex alignItems="center" justifyContent="between" mobileFlex={false}>
					<Flex className="mb-1" mobileFlex={false}>
						<div className="mr-md-3 mb-3">
							<Input placeholder="Siparişlerde Ara" prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
						</div>
						{/* <div className="mb-3">
						<Select 
							defaultValue="All" 
							className="w-100" 
							style={{ minWidth: 180 }} 
							onChange={handleShowStatus} 
							placeholder="Status"
						>
							<Option value="All">All payment </Option>
							{paymentStatusList.map(elm => <Option key={elm} value={elm}>{elm}</Option>)}
						</Select>
					</div> */}
					</Flex>
					<Flex alignItems="center" justifyContent="between" mobileFlex={false}>

						<div>
							<Button  href={"/order/edit/0"} type="primary" icon={<PlusCircleOutlined />} block>Sipariş Ekle</Button>
						</div>

					</Flex>
				</Flex>
				<div className="table-responsive">
					<Table
						columns={tableColumns}
						dataSource={data}
						rowKey='ORDERID'
						// rowSelection={{
						// 	selectedRowKeys: selectedRowKeys,
						// 	type: 'checkbox',
						// 	preserveSelectedRowKeys: false,
						// 	...rowSelection,
						// }}
					/>
				</div>
			</Card>
		</Spin>
	)
}



function mapStateToProps(state) {

	return {
		isLoading: state.orderReducer.isLoading,
		orderList: state.orderReducer.orderList,
		hasError: state.orderReducer.hasError,
		errorMessage: state.orderReducer.errorMessage,


	}
}

function mapDispatchToProps(dispatch) {
	return {
		selectOrderListAction: bindActionCreators(selectOrderList, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);