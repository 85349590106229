import { 
	AUTHENTICATED, 
	SIGNOUT_SUCCESS, 
	SHOW_LOADING, 
    SIGNIN_ERROR,
	HIDE_AUTH_MESSAGE,
	SIGNOUT
} from '../constants/Auth';

const initState = {
  loading: false,
  errorMessage: '',
  showMessage: false, 
  isSuccess: false,
  token : localStorage.getItem("auth_token")
}



export const loginReducer = (state = initState, action) => {
	switch (action.type) {
		case SIGNOUT:
			return {
				...state, 
				token: null, 
			} 
		case AUTHENTICATED:
			return {
				...state,
				loading: false, 
				token: action.token,
                isSuccess : true
			} 
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				redirect: '/',
				loading: false
			}
		} 
		case HIDE_AUTH_MESSAGE: {
			return {
				...state,
				showMessage: false, 
			}
		} 
        case SIGNIN_ERROR: {
			return {
				...state, 
				loading: false,
                errorMessage : action.errorMessage,
				showMessage: true, 
			}
		} 
		case SHOW_LOADING: { 
			return {
				...state,
				loading: true
			}
		} 
		default:
			return state;
	}
}

export default loginReducer