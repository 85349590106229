import Axios from "axios";
import { API_BASE_URL } from "constants/ApiConstant";
import { AUTH_TOKEN } from "constants/StorageConstant";
import apiOperations from "./apiOperations";
import { useHistory } from "react-router-dom";




const network = {

    get: async function (method, obj, selectFromJson) {

        const authToken = localStorage.getItem(AUTH_TOKEN);

        var queryString = "";
        if (obj) {
            queryString = "/?" + apiOperations.objToQueryString(obj);
        }

        const config = {
            headers: { Authorization: `Bearer ${authToken}` }
        };

      
        var serviceAddress = await apiOperations.GetServiceAddress();
        if (selectFromJson) {
            serviceAddress = API_BASE_URL;
        }
        const url = serviceAddress + method + queryString;


        try {
            return apiOperations.apiSuccess(await (await Axios.get(url, config)).data);

        } catch (error) { 
            this.extractErrorMessage(error);

            return apiOperations.apiError(error.message);
            
        }
    },

    post: async function (method, obj) {
        const serviceAddress = await apiOperations.GetServiceAddress();
        const url = serviceAddress + method;
        const authToken = localStorage.getItem(AUTH_TOKEN);
        try {
         

            var data = apiOperations.apiSuccess(await (await Axios.post(url, obj, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: `Bearer ${authToken}`
                },
            })).data);
            return data;

        } catch (error) {
            this.extractErrorMessage(error);
            return apiOperations.apiError(error);
        }
    },

    extractErrorMessage (error){

        if (error.response && error.response.data && error.response.data.Message.length>0) {
            var serverMessage = error.response.data.Message;
            if (serverMessage.indexOf("Login Required") != -1) {
                // const history = useHistory();

                window.localStorage.removeItem(AUTH_TOKEN);
                window.location.reload();
                // history.push(ENTRY_ROUTE)

                return error;
            }

        }else {
            return error;
        }

    }
}

export default network;