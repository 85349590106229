import { API_BASE_URL } from "constants/ApiConstant";
import globalConstant from "constants/globalConstant";
import helperFunc from "helpers/helperFunc";
import actionTypes from "utils/redux/actions/actionTypes";




const initialState = {
    reservationList: [],
    isLoading: false,
    hasError: false,
    errorMessage: "",
};
export function reservationReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.SELECT_RESERVATION_LIST_SUCCESS:




            return {
                ...state,
                reservationList: action.payload
            };

        case actionTypes.SELECT_RESERVATION_LIST_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };

        case actionTypes.SELECT_RESERVATION_LIST_ERROR:

            return {
                ...state,
                hasError: action.hasError,
                errorMessage: action.errorMessage
            };

        case actionTypes.ON_RESERVATION_LISTENER:

       helperFunc.playSound();
            return {
                ...state, 
            };
            case actionTypes.ON_REQUEST_INFORMATION_LISTENER:

                helperFunc.playSound();
                     return {
                         ...state, 
                     };
        default:
            return state;
    }
}



const orderDetailState = {

    orderItemList: [],
    orderMasterInfo: {
        customerId: -1,
        orderDate: new Date(),
        orderId: -1,
        paymentType: -1,
        totalAmount: 0,
    },
    isLoading: false,
    hasError: false,
    errorMessage: "",
    orderisEnable : true
};
export function reservationDetailReducer(state = orderDetailState, action) {

    switch (action.type) {
        case actionTypes.SELECT_RESERVATION_DETAIL_SUCCESS:

            return {
                ...state,
                orderItemList: action.orderItemList,
                orderMasterInfo: action.orderMasterInfo,
                orderisEnable : action.orderisEnable
            };

        case actionTypes.UPDATE_RESERVATION_MASTER_INFO:

            return {
                ...state,
                orderMasterInfo: action.orderMasterInfo,
            };
        case actionTypes.SELECT_RESERVATION_DETAIL_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };

        case actionTypes.SELECT_RESERVATION_DETAIL_ERROR:

            return {
                ...state,
                hasError: action.hasError,
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
}