import React, { useEffect, useState } from 'react';
import { Card, Table, Input, Button, Spin, notification, Row, Col } from 'antd';
import network from 'network/network'; 
import moment from 'moment';
import { Modal, Carousel } from 'antd';
import * as XLSX from 'xlsx';

const KodsanReport = () => {
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [imageList, setImageList] = useState([]);
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, 'days').format('YYYY-MM-DD'));
    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        setLoading(true);
        try {
            const response = await network.get("/admin/Kodsan/selectFormList"); 
            setData(response.data);
        } catch (error) {
            notification.error({
                message: 'Hata',
                description: 'Ürün verileri alınamadı.',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = async () => {
        setLoading(true);
        try {
            const response = await network.get(`/admin/Kodsan/selectFormListByDateRange`, {
                startDate: startDate + 'T00:00:00',
                endDate: endDate + 'T23:59:59',
            });
            setData(response.data);
        } catch (error) {
            notification.error({
                message: 'Hata',
                description: 'Ürün verileri alınamadı.',
            });
        } finally {
            setLoading(false);
        }
    };
    

    const handleExportExcel = () => {
        const headers = [
            'No', 'Tarih', 'Saat', 'Kayıt Yapan', 'Kayıt Yapan Tel', 'Seri No', 'Kurulum Yeri', 'Sorumlu', 
            'Sorumlu Tel', 'Konum Adresi', 'Konum İli', 'Açık Adresi', 'Ürün Adı', 'Elektrik Rez. Pano var mı?', 
            'T&P Valf var mı?', 'Topraklama var mı?', 'Emniyet Ventili var mı?', 'Genleşme Tankı var mı?', 
            'Filtre, Çekvalf var mı?', 'Boyler ile Tavan 1m fazla mı?'
        ];
    
        const excelData = data.data.map(({ 
            serviceFormId, insertDate, clientName, clientNumber, serialCode, installationPlaces, personnel, personelPhone, 
            address, city, manuelAddress, productName, electricalResistanceUsage, hasSafetyValvetpvalveusage, 
            groundingStatus, hasSafetyValve, hasExpansionTank, hasFilterCheckValve, hasAnodeClearance 
        }) => ({
            'No': serviceFormId,
            'Tarih': moment(insertDate).format('DD.MM.YYYY'),
            'Saat': moment(insertDate).format('HH:mm'),
            'Kayıt Yapan': clientName,
            'Kayıt Yapan Tel': clientNumber,
            'Seri No': serialCode,
            'Kurulum Yeri': installationPlaces,
            'Sorumlu': personnel,
            'Sorumlu Tel': personelPhone,
            'Konum Adresi': address,
            'Konum İli': city,
            'Açık Adresi': manuelAddress,
            'Ürün Adı': productName,
            'Elektrik Rez. Pano var mı?': electricalResistanceUsage ? 'Evet' : 'Hayır',
            'T&P Valf var mı?': hasSafetyValvetpvalveusage ? 'Evet' : 'Hayır',
            'Topraklama var mı?': groundingStatus ? 'Evet' : 'Hayır',
            'Emniyet Ventili var mı?': hasSafetyValve ? 'Evet' : 'Hayır',
            'Genleşme Tankı var mı?': hasExpansionTank ? 'Evet' : 'Hayır',
            'Filtre, Çekvalf var mı?': hasFilterCheckValve ? 'Evet' : 'Hayır',
            'Boyler ile Tavan 1m fazla mı?': hasAnodeClearance ? 'Evet' : 'Hayır',
        }));
    
        const finalData = [headers].concat(excelData.map(item => Object.values(item)));
    
        const worksheet = XLSX.utils.aoa_to_sheet(finalData);
    
        const maxLengths = finalData[0].map((_, colIndex) =>
            Math.max(...finalData.map(row => (row[colIndex] ? row[colIndex].toString().length : 0)))
        );
    
        worksheet['!cols'] = maxLengths.map(len => ({ wch: len + 2 }));
    
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "KodsanData");
    
        XLSX.writeFile(workbook, 'KodsanReport.xlsx');
    };

    const handleShowImages = async (messageTransactionId) => {
        const response2 = await network.get(`/admin/Kodsan/selectFormImageList?messageTransactionId=${messageTransactionId}`);
        console.log("Gelen resimler:", response2.data.data);
        setImageList(response2.data.data);
        setVisible(true);
    };

    const tableColumns = [
        {
            title: 'No',
            dataIndex: 'serviceFormId',
        }, 
        {
            title: 'Tarih',
            dataIndex: 'insertDate',
            render: (text) => moment(text).format('DD.MM.YYYY'),
        }, 
        {
            title: 'Saat',
            dataIndex: 'insertDate',
            render: (text) => moment(text).format('HH:mm'),

        }, 
        {
            title: 'Kayıt Yapan',
            dataIndex: 'clientName',
        },
        {
            title: 'Kayıt Yapan Tel',
            dataIndex: 'clientNumber',
        },
        {
            title: 'Seri No',
            dataIndex: 'serialCode',
        },
        {
            title: 'Kurulum Yeri',
            dataIndex: 'installationPlaces',
        },
        {
            title: 'Sorumlu',
            dataIndex: 'personnel',
        },
        {
            title: 'Sorumlu Tel',
            dataIndex: 'personelPhone',
        },
        {
            title: 'Konum Adresi',
            dataIndex: 'address',
            render: (text) => (
                <div className="ellipsis-2-lines">
                    {text}
                </div>
            ),
        },
        {
            title: 'Konum İli',
            dataIndex: 'city',
            render: (text) => (
                <div className="ellipsis-2-lines">
                    {text}
                </div>
            ),
        },
        {
            title: 'Açık Adresi',
            dataIndex: 'manuelAddress',
            render: (text) => (
                <div className="ellipsis-2-lines">
                    {text}
                </div>
            ),
        },
        {
            title: 'Ürün Adı',
            dataIndex: 'productName',
        },
        {
            title: 'Elektrik Rez. Pano var mı?',
            dataIndex: 'electricalResistanceUsage',
            render: (text) => (text ? 'Evet' : 'Hayır'),
        },
        {
            title: 'T&P Valf var mı?',
            dataIndex: 'hasSafetyValvetpvalveusage',
            render: (text) => (text ? 'Evet' : 'Hayır'),
        },
        {
            title: 'Topraklama var mı?',
            dataIndex: 'groundingStatus',
            render: (text) => (text ? 'Evet' : 'Hayır'),
        },
        {
            title:'Emniyet Ventili var mı?',
            dataIndex: 'hasSafetyValve',
        },
        {
            title:'Genleşme Tankı var mı?',
            dataIndex: 'hasExpansionTank',
            render: (text) => (text ? 'Evet' : 'Hayır'),
        },
        {
            title:'Filtre, Çekvalf var mı?',
            dataIndex: 'hasFilterCheckValve',
            render: (text) => (text ? 'Evet' : 'Hayır'),
        },
        {
            title:'Boyler ile Tavan 1m fazla mı?',
            dataIndex: 'hasAnodeClearance',
            render: (text) => (text ? 'Evet' : 'Hayır'),
        },
        {
            title: 'Resimler',
            dataIndex: 'messageTransactionId',
            render: (messageTransactionId) => (
                <Button onClick={() => handleShowImages(messageTransactionId)}>Göster</Button>
            ),
        },
    ];

    return (
        <Spin spinning={loading}>
            <Card>
            <Row style={{ marginTop: '40px' }}>
                    <Col span={3}>
                        <Input 
                            type="date" 
                            value={startDate} 
                            onChange={e => setStartDate(e.target.value)} 
                            placeholder="Başlangıç Tarihi"
                            style={{ width: '180px' }}
                        />
                    </Col>
                    <Col span={3}>
                        <Input 
                            type="date" 
                            value={endDate}
                            onChange={e => setEndDate(e.target.value)} 
                            placeholder="Bitiş Tarihi" 
                            style={{ width: '180px' }}
                        />
                    </Col>
                    <Col span={18} style={{ textAlign: 'right' }}>
                        <Button onClick={handleSearch} type="primary">Ara</Button>
                        <Button onClick={handleExportExcel} style={{ marginLeft: '8px' }}>Excel'e Aktar</Button>
                    </Col>
                </Row>
                <Table
                    columns={tableColumns}
                    dataSource={data.data}
                    rowKey="productId"
                    style={{marginTop:"10px"}}
                    scroll={{ x: 'max-content' }}
                />
            </Card>
            <Modal
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
                width={800} 
            >
                <Carousel>
                    {imageList && imageList.length > 0 ? (
                        imageList.map((img, index) => (
                            <div key={index}>
                                <img 
                                    src={'http://admintest.chatshire.io/chatshireadmintestservice/Image/Kodsan/'+img.imageurl} 
                                    alt={`image-${index}`} 
                                    style={{ width: '100%', height: 'auto', maxHeight: '500px', objectFit: 'contain' }} 
                                />
                            </div>
                        ))
                    ) : (
                        <div>Resim yok</div>
                    )}
                </Carousel>
            </Modal>

            
        </Spin>
    );
};

export default KodsanReport;
