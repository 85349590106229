import { API_BASE_URL } from "constants/ApiConstant";
import globalConstant from "constants/globalConstant";
import actionTypes from "utils/redux/actions/actionTypes";
import { apexLineChartDefaultOption, COLOR_2 } from "constants/ChartConstant";



const initialState = {
    messageChartDates:[],
    messageChartSeries: [{
        name: '',
        data: []
    }],
    messageChartLoading: true,

    customerChartDates:[],
    customerChartSeries: [{
        name: '',
        data: []
    }],
    activeCustomer : 0,
    customerChartLoading: true,

    transactionOrderPercent: 0,
    transactionOrderPercentLoading: true,

    priceList: [],
    priceListLoading: true,

    latestCustomerList: [],
    latestCustomerListLoading: true,

    latestOrderList: [],
    latestOrderListLoading: true,

    isLoading: false,
    hasError: false,
    errorMessage: "",
};
export function dashboardReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.SELECT_D_MESSAGE_CHART_SUCCESS:

            return {
                ...state,
                messageChartDates: action.messageChartDates,
                messageChartSeries: action.messageChartSeries,
            };
        case actionTypes.SELECT_D_MESSAGE_CHART_LOADING:

            return {
                ...state,
                messageChartLoading: action.isLoading
            };
        case actionTypes.SELECT_D_MESSAGE_CHART_ERROR:

            return {
                ...state,
                hasError: action.hasError,
                errorMessage: action.errorMessage
            };

        case actionTypes.SELECT_D_CUSTOMER_CHART_SUCCESS:

            return {
                ...state,
                customerChartDates: action.customerChartDates,
                customerChartSeries: action.customerChartSeries,
                activeCustomer : action.activeCustomer
            };
        case actionTypes.SELECT_D_CUSTOMER_CHART_LOADING:

            return {
                ...state,
                customerChartLoading: action.isLoading
            };

        case actionTypes.SELECT_D_TRANSACTION_ORDER_PERCENT_SUCCESS:

            return {
                ...state,
                transactionOrderPercent: action.payload
            };
        case actionTypes.SELECT_D_TRANSACTION_ORDER_PERCENT_LOADING:

            return {
                ...state,
                transactionOrderPercentLoading: action.isLoading
            };

        case actionTypes.SELECT_D_PRICES_SUCCESS:

            return {
                ...state,
                priceList: action.payload
            };
        case actionTypes.SELECT_D_PRICES_LOADING:

            return {
                ...state,
                priceListLoading: action.isLoading
            };

        case actionTypes.SELECT_D_LATEST_CUSTOMERS_SUCCESS:

            return {
                ...state,
                latestCustomerList: action.payload
            };
        case actionTypes.SELECT_D_LATEST_CUSTOMERS_LOADING:

            return {
                ...state,
                latestCustomerListLoading: action.isLoading
            };

        case actionTypes.SELECT_D_LATEST_ORDERS_SUCCESS:

            return {
                ...state,
                latestOrderList: action.payload
            };
        case actionTypes.SELECT_D_LATEST_ORDERS_LOADING:

            return {
                ...state,
                latestOrderListLoading: action.isLoading
            };

        default:
            return state;
    }
}
