import React, { useState } from "react"
import { Input, Button, Modal, message, } from 'antd';
import network from "network/network";



const FirmSellerInsertUpdate = React.forwardRef((props, ref) => {



    const [firmSellerModalVisible, setFirmSellerModalVisible] = useState(false);
    const [loadingVisible, setLoadingVisible] = useState(false);
    const [sellerId, setSellerId] = useState(-1);

    const [sellerName, setSellerName] = useState("");
    const [sellerCode, setSellerCode] = useState("");
    const [tahsiledinSellerCode, setTahsiledinSellerCode] = useState("");
    const [status, setStatus] = useState("");
    React.useImperativeHandle(ref, () => ({
        openFirmSellerModal
    }));

    const openFirmSellerModal = (params) => {

        setSellerId(params.id);
        setSellerName(params.name);
        setSellerCode(params.code);
        setTahsiledinSellerCode(params.tahsiledinCode);
        setStatus(params.status);

        setFirmSellerModalVisible(true);
    }

    const onFirmSellerConfirmClick = () => {

        if (sellerName.length > 0 && sellerCode.length>0 && tahsiledinSellerCode.length>0) {
            onFirmSellerEditConfirm();
        } else {
            message.error('Lütfen zorunlu alanları doldurunuz');
        }
    }

    /*
         public string SellerName { get; set; }
         public string SellerCode { get; set; }
         public string TahsiledinSellerCode { get; set; }
         public bool Status { get; set; }
     */
    const onFirmSellerEditConfirm = async () => {

        setLoadingVisible(true);

        const params = {
            FirmSellerId: parseInt(sellerId),
            FirmId: 0,
            SellerName: sellerName,
            SellerCode: sellerCode,
            TahsiledinSellerCode: tahsiledinSellerCode,
            Status: status
        }

        var result = await network.post(`/admin/FirmSellers/insertUpdateSeller`, params);

        setLoadingVisible(false)
        if (result.isSuccess) {
            var postResult = result.data;
            if (postResult.isSuccess) {
                setFirmSellerModalVisible(false);
                message.success('Bayi bilgileri başarılı bir şekilde kaydedilmiştir.');

                setTimeout(() => {

                    props.selectFirmSellerList()
                }, 250);

            } else {
                alert(postResult.errorMessage);
            }

        } else {
            alert(result.errorMessage);
        }

    }

    return (
        <Modal
            visible={firmSellerModalVisible}
            title={sellerId > 0 ? "Bayi Güncelle" : "Bayi Ekle"}
            onOk={onFirmSellerConfirmClick}
            onCancel={() => setFirmSellerModalVisible(false)}
            footer={[
                <Button key="back" onClick={() => setFirmSellerModalVisible(false)}>
                    İptal
                </Button>,
                <Button key="submit" type="primary" loading={loadingVisible} onClick={onFirmSellerConfirmClick}>
                    Kaydet
                </Button>,
            ]}
        >

            <div className='ant-col ant-form-item-label'>
                <label className='ant-form-item-required' htmlFor="customerName">Bayi Adı</label>
            </div>

            <Input value={sellerName}
                defaultValue={sellerName}
                onChange={(val) => {
                    setSellerName(val.target.value);
                }}
                placeholder="Bayi Adı" />

            <div className='ant-col ant-form-item-label'>
                <label className='ant-form-item-required' htmlFor="customerName">Bayi Kodu</label>
            </div>

            <Input value={sellerCode}
                defaultValue={sellerCode}
                onChange={(val) => {
                    setSellerCode(val.target.value);
                }}
                placeholder="Bayi Adı" />

            <div className='ant-col ant-form-item-label'>
                <label className='ant-form-item-required' htmlFor="customerName">Tahsiledin Bayi Kodu</label>
            </div>

            <Input value={tahsiledinSellerCode}
                defaultValue={tahsiledinSellerCode}
                onChange={(val) => {
                    setTahsiledinSellerCode(val.target.value);
                }}
                placeholder="Bayi Adı" />

        </Modal>
    );
})


export default FirmSellerInsertUpdate;