import React, { useEffect } from "react";
import { Row, Col, Card, Dropdown, Menu } from 'antd';
import { FIRM_NAME } from "constants/StorageConstant";
import {
  FileExcelOutlined,
  PrinterOutlined,
  PlusOutlined,
  EllipsisOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
 
import PriceList from "components/dashboard/defaultDashboardElements/PriceList";
import MessageChart from "components/dashboard/defaultDashboardElements/MessageChart";
import LatestCustomerList from "components/dashboard/defaultDashboardElements/LatestCustomerList";
import Message_TransactionPercent from "components/dashboard/defaultDashboardElements/Message_TransactionPercent";
import CustomerChart from "components/dashboard/defaultDashboardElements/CustomerChart";
import LatestOrderList from "components/dashboard/defaultDashboardElements/LatestOrderList";
const firmName = localStorage.getItem(FIRM_NAME);
const latestTransactionOption = (
  <Menu>
    <Menu.Item key="0">
      <span>
        <div className="d-flex align-items-center">
          <ReloadOutlined />
          <span className="ml-2">Refresh</span>
        </div>
      </span>
    </Menu.Item>
    <Menu.Item key="1">
      <span>
        <div className="d-flex align-items-center">
          <PrinterOutlined />
          <span className="ml-2">Print</span>
        </div>
      </span>
    </Menu.Item>
    <Menu.Item key="12">
      <span>
        <div className="d-flex align-items-center">
          <FileExcelOutlined />
          <span className="ml-2">Export</span>
        </div>
      </span>
    </Menu.Item>
  </Menu>
);

const cardDropdown = (menu) => (
  <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
    <a href="/#" className="text-gray font-size-lg" onClick={e => e.preventDefault()}>
      <EllipsisOutlined />
    </a>
  </Dropdown>
)
 
export const Home = (props) => { 
  const navigate = useNavigate();

  useEffect(() => {
 
  }, []);

  const addCustomer = row => {
    navigate(`/customer/detail/0`)
  } 

  const newJoinMemberOption = (
    <Menu>
      <Menu.Item key="0" onClick={addCustomer}>
        <span>
          <div className="d-flex align-items-center">
            <PlusOutlined />
            <span className="ml-2">Müşteri Ekle</span>
          </div>
        </span>
      </Menu.Item>
    </Menu>
  )



  return (
    <>
      <Helmet>
        <title>{firmName}{"| Chatshire Admin Panel"}</title>
      </Helmet>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={18}>
          <Row gutter={16}>
            <PriceList></PriceList>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <MessageChart></MessageChart>

            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6}>

          <Message_TransactionPercent></Message_TransactionPercent>
          <CustomerChart></CustomerChart>
       
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={7}>
          <Card title="Yeni Müşteriler" extra={cardDropdown(newJoinMemberOption)}>
            <div className="mt-3">
              <LatestCustomerList></LatestCustomerList>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={17}>
          <Card title="Son Siparişler" extra={cardDropdown(latestTransactionOption)}>
          
            <LatestOrderList></LatestOrderList>
          </Card>
        </Col>
      </Row>
    </>
  )
}
function mapStateToProps(state) {

  return {
 
  }
}

function mapDispatchToProps(dispatch) {
  return { 
   
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);

// export default withRouter(DefaultDashboard);
