import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { FIRM_ID, LANGUAGE } from 'constants/StorageConstant';

const firmLanguageMap = {
    "4": "tr",
    "1": "tr"
};

const resources = {
    en: {
        translation: {
            "newCustomer": "New Customer",
            "stampCount": "Stamp Count",
            "coffeeGift": "Coffee Gift",
            "activeCustomer": "Active Customer Ratio",
            "top10Stamps": "Number of Stamps Top 10",
            "last10Process": "Last 10 Customer Process",
            "today": "Today",
            "last7Days": "Last 7 Days",
            "currentMonth": "Current Month",
            "previousMonth": "Previous Month",
            "allTime": "All Time",
            "all": "All",
            "Stamp": "Stamp",
            "Card": "Card",
            "stampCancelled": "Stamp Cancelled",
            "stampAccepted": "Stamp Accepted",
            "stampRequestWait": "Stamp Request Waiting",
            "Customers": "Customers",
            "menu": {
                "title": "Menu",
                "dashboard": "Dashboard",
                "customers": "Customers",
                "baristaScreen":"Barista Screen"
            },
            "customerName": "Customer Name",
            "customerNumber": "Customer Phone Number",
            "regDate": "Registration Date",
            "Branch": "Branch",
            "customerDetail": "Customer Detail",
            "Export": "Export",
            "customerPermission": "Consent for Communication",
            "customerGroups": "Customer Groups",
            "Group": "Group",
            "cardNumber": "Card Number",
            "annualStampCustomer": "Annual Stamp Chart",
            "Email": "Email",
            "Password": "Password",
            "Login": "Login",
            "WrongEmail": "Please write your email address",
            "WrongEmailFormat": "Please enter a valid Email address!",
            "WrongPassword":"Please Write Your Password",
            "notAvaiable":"Not Avaiable",
            "emptyName":"Name field cannot be left blank",
            "wrongMailFormat":"Wrong mail format",
            "Error":"Error",
            "stampInfo":"Stamp Info",
            "customerInfo":"Customer Info",
            "Transactions":"Transactions",
            "stampConfirmationScreen":"Stamp Confirmation Screen",
            "freeCoffeMessage":"The customer is entitled to receive a free coffee. You can give the free coffee!",
            "cancelCoffeeMessage":"The customer's stamp request will be canceled. Do you want to proceed?",
            "confirmCoffeeMessage":"The customer’s stamp request will be confirmed. Would you like to proceed?",
            "Close":"Close",
            "Cancel":"Cancel",
            "Confirm":"Confirm",
            "branchDistribution":"Branch Distribution",
            "registeredBranch":"Registered Branch",
            "customerInfoCard":"Customer Info Card",
            "giftedCoffee":"Gifted Coffee",
            "Logout":"Log Out",
            "privacyTerms":"Privacy and Terms",
            "searchInCustomers":"Search in Customers",
            "stampRequest":"Stamp Request",
            "stampReqCome":"New stamp request!",
            "stampOkey":"The stamp was printed successfully.",
            "Succesful":"Successful",
            "Confirmation":"Confirmation",
            "cancelReason":"Please select the reason for cancellation :",
            "Cancellation":"Cancellation",
            "selectProduct":"Please choose a product before stamp confirmation"



          


            




        }
    },
    tr: {
        translation: {
            "newCustomer": "Yeni Müşteri",
            "stampCount": "Damga Sayısı",
            "coffeeGift": "Hediye Kahve",
            "activeCustomer": "Aktif Müşteri Oranı",
            "top10Stamps": "Damga Sayısı İlk 10",
            "last10Process": "Son 10 Müşteri İşlemi",
            "today": "Bugün",
            "last7Days": "Son 7 Gün",
            "currentMonth": "Bu Ay",
            "previousMonth": "Geçen Ay",
            "allTime": "Tüm Zamanlar",
            "all": "Tümü",
            "Stamp": "Damga",
            "Card": "Kart",
            "stampCancelled": "Damga İptal Edildi",
            "stampAccepted": "Damga Onaylandı",
            "stampRequestWait": "Damga İsteği Beklemede",
            "Customers": "Müşteriler",
            "menu": {
                "title": "Menü",
                "dashboard": "Gösterge Paneli",
                "customers": "Müşteriler",
                "baristaScreen":"Barista Ekranı"

            },
            "customerName": "Müşteri İsmi",
            "customerNumber": "Müşteri Telefon Numarası",
            "regDate": "Kayıt Tarihi",
            "Branch": "Şube",
            "customerDetail": "Müşteri Detay",
            "Export": "Dışa Aktar",
            "customerPermission": "Müşteri İletişim İzni",
            "customerGroups": "Müşteri Grupları",
            "Group": "Grup",
            "cardNumber": "Kart Sayısı",
            "annualStampCustomer": "Yıllık Müşteri Damga Grafiği",
            "Email": "Email",
            "Password": "Şifre",
            "Login": "Giriş Yap",
            "WrongEmail": "Lütfen Email adresinizi giriniz",
            "WrongEmailFormat": "Lütfen geçerli bir Email adresi giriniz!",
            "WrongPassword":"Lütfen Şifrenizi Giriniz",
            "notAvaiable":"Mevcut Değil",
            "emptyName":"İsim kısmı boş bırakılamaz",
            "wrongMailFormat":"Email formatı doğru değil",
            "Error":"Hata",
            "stampInfo":"Damga Bilgisi",
            "customerInfo":"Müşteri Bilgisi",
            "Transactions":"İşlemler",
            "stampConfirmationScreen":"Damga Onay Ekranı",
            "freeCoffeMessage":"Müşteri hediye kahve almaya hak kazandı. Hediye kahvesini verebilirsin!",
            "cancelCoffeeMessage":"Müşterinin damga isteği iptal edilecek devam edilsin mi ?",
            "confirmCoffeeMessage":"Müşterinin damga isteği kabul edilecek devam edilsin mi?",
            "Close":"Kapat",
            "Cancel":"İptal et",
            "Confirm":"Onayla",
            "branchDistribution":"Şube Dağılım",
            "registeredBranch":"Kayıtlı Olduğu Şube",
            "customerInfoCard":"Müşteri Bilgi Kartı",
            "giftedCoffee":"Hediye Kahve",
            "Logout":"Çıkış Yap",
            "privacyTerms":"Gizlilik ve Koşullar",
            "searchInCustomers":"Müşterilerde Ara",
            "stampRequest":"Damga Talebi",
            "stampReqCome":"Yeni damga talebi!",
            "stampOkey":"Damga başarılı bir şekilde basıldı.",
            "Succesful":"İşlem Başarılı",
            "Confirmation":"Onayla",
            "cancelReason":"Lütfen iptal etme sebebini seçiniz :",
            "Cancellation":"İptal",
            "selectProduct":"Lütfen devam etmeden bir ürün seçiniz."







            


            





        }
    }
};

const getInitialLanguage = () => {
    const firmId = localStorage.getItem(FIRM_ID);
    return firmLanguageMap[firmId] || 'en'; // default to English if no match
};


i18n.use(initReactI18next).
    init(
        {
            resources,
            lng: localStorage.getItem('Language'), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
            // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
            // if you're using a language detector, do not define the lng option

            interpolation: {
                escapeValue: false // react already safes from xss
            }
        }
    );


export default i18n;
