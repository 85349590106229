import { API_BASE_URL } from "constants/ApiConstant";
import globalConstant from "constants/globalConstant";
import actionTypes from "utils/redux/actions/actionTypes";




const initialState = {
    customerList: [],
    isLoading: false,
    hasError: false,
    errorMessage: "",
    firmSellerList: []
};
export function customerReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.SELECT_FIRM_SELLER_LIST_SUCCESS:

            return {
                ...state,
                firmSellerList: action.payload
            };
        case actionTypes.SELECT_CUSTOMER_LIST_SUCCESS:


            return {
                ...state,
                customerList: action.payload
            };

        case actionTypes.SELECT_CUSTOMER_LIST_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };

        case actionTypes.SELECT_CUSTOMER_LIST_ERROR:

            return {
                ...state,
                hasError: action.hasError,
                errorMessage: action.errorMessage
            };
        case actionTypes.CHANGE_CUSTOMER_ACTIVITY_STATUS:

            var index = state.customerList.findIndex(w => w.CUSTOMERID == action.customerId);
            var tempCustomer = JSON.parse(JSON.stringify(state.customerList));
            if (index != -1) {
                tempCustomer[index].ACTIVITYSTATUS = action.activityStatus
            }

            return {
                ...state,
                customerList: tempCustomer
            }

        case actionTypes.ON_CUSTOMER_SERVICE_LISTENER:
            playSound();

            return {
                ...state
            };
        case actionTypes.ON_NEWCUSTOMER_LISTENER:

            var customerInfo = (action.payload);
            customerInfo.customerstatus = globalConstant.customerStatus.waitConfirmation;
            customerInfo.customerImage = "";
            customerInfo.countrycode = "";
            customerInfo.customernumber = customerInfo.sourcePhone;

            var tempCustomer = JSON.parse(JSON.stringify(state.customerList));

            if (tempCustomer.length > 0) {
                tempCustomer.unshift(customerInfo);
            } else {
                tempCustomer.push(customerInfo);
            }
            playSound();

            return {
                ...state,
                customerList: tempCustomer
            };
        default:
            return state;
    }
}


function playSound() {

    const sound = API_BASE_URL + globalConstant.soundFolderPaths.newOrderSound + "newOrderSound.wav";
    var audio = new Audio(sound);

    setTimeout(() => {
        audio.play();
    }, 500);

    setTimeout(() => {
        audio.remove();
    }, 2500);

}