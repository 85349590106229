 
import helperFunc from "helpers/helperFunc";
import actionTypes from "utils/redux/actions/actionTypes";




const initialState = {
    orderList: [],
    isLoading: false,
    hasError: false,
    errorMessage: "",
};
export function orderReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.SELECT_ORDER_LIST_SUCCESS:

            return {
                ...state,
                orderList: action.payload
            };

        case actionTypes.SELECT_ORDER_LIST_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };

        case actionTypes.SELECT_ORDER_LIST_ERROR:

            return {
                ...state,
                hasError: action.hasError,
                errorMessage: action.errorMessage
            };

        case actionTypes.ON_ORDER_LISTENER:

   helperFunc.playSound();
            return {
                ...state,
                // orderList: tempOrder
            };
        default:
            return state;
    }
}



const orderDetailState = {

    orderItemList: [],
    orderMasterInfo: {
        customerId: -1,
        orderDate: new Date(),
        orderId: -1,
        paymentType: -1,
        totalAmount: 0,
    },
    isLoading: false,
    hasError: false,
    errorMessage: "",
    orderisEnable : true
};
export function orderDetailReducer(state = orderDetailState, action) {

    switch (action.type) {
        case actionTypes.SELECT_ORDER_DETAIL_SUCCESS:

            return {
                ...state,
                orderItemList: action.orderItemList,
                orderMasterInfo: action.orderMasterInfo,
                orderisEnable : action.orderisEnable
            };

        case actionTypes.UPDATE_ORDER_MASTER_INFO:

            return {
                ...state,
                orderMasterInfo: action.orderMasterInfo,
            };
        case actionTypes.SELECT_ORDER_DETAIL_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };

        case actionTypes.SELECT_ORDER_DETAIL_ERROR:

            return {
                ...state,
                hasError: action.hasError,
                errorMessage: action.errorMessage
            }; 

        case actionTypes.ORDER_ITEM_ADD:

            var items = JSON.parse(JSON.stringify(state.orderItemList));
            
      
            items.push(action.payload);

            return {
                ...state,
                orderItemList: items
            }

        case actionTypes.ORDER_ITEM_UPDATE:
            var items = JSON.parse(JSON.stringify(state.orderItemList));
            const updatedItem = action.payload;
            var index = items.findIndex(w => w.itemId == updatedItem.itemId);
            items[index] = updatedItem;

            return {
                ...state,
                orderItemList: items
            }

        case actionTypes.ORDER_ITEM_DELETE:

            var items = JSON.parse(JSON.stringify(state.orderItemList));
            const deletedId = action.payload;
            var index = items.findIndex(w => w.itemId == deletedId);

            items.splice(index, 1);

            return {
                ...state,
                orderItemList: items
            }
        default:
            return state;
    }
}