import { Grid, Layout } from 'antd';
import Footer from 'components/Footer';
import HeaderNav from 'components/HeaderNav';
import MobileNav from 'components/MobileNav';
import PageHeader from 'components/PageHeader';
import SideNav from 'components/SideNav';
import TopNav from 'components/TopNav';
import { FIRM_ID } from 'constants/StorageConstant';
import { DIR_LTR, DIR_RTL, NAV_TYPE_SIDE, NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import helperFunc from 'helpers/helperFunc';
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import tools from 'tools';
import { selectActivityStatusList } from 'utils/redux/actions/activityStatusActions';
import { appListener } from 'utils/redux/actions/appListenerActions';
import { selectHomePageNotificationList, selectUnreadNotificationCount } from 'utils/redux/actions/notiificationActions';
import * as signalR from '@aspnet/signalr';
import { API_BASE_URL } from 'constants/ApiConstant';
import globalConstant from 'constants/globalConstant';


function AppLayout({ location, navCollapsed, navType, direction, appListenerAction, selectActivityStatusListAction, selectHomePageNotificationListActions, selectUnreadNotificationCountActions }) {
  const { Content } = Layout;
  const { useBreakpoint } = Grid;
  navType = "SIDE";
  direction = DIR_LTR;
  const navigationConfig = helperFunc.getLeftMenuList();
  const currentRouteInfo = tools.getRouteInfo(navigationConfig, "/")

  const [currentRoute, setCurrentRoute] = useState(currentRouteInfo);
  const screens = tools.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const firmId = localStorage.getItem(FIRM_ID);
  const firmAdminState = JSON.parse(localStorage.getItem('isFirmAdmin'));
  const signalRConnectionRef = useRef(null);
  const isConnectListener = useRef(false);
  

const navigate = useNavigate();
  
  useEffect(() => {
    if (!firmAdminState) {
      navigate('/barista-screen'); 
    }
    if(!firmAdminState && firmId==5){
      navigate('/kodsan-report');
    }
  }, [firmAdminState, navigate]);

  useEffect(() => {
    if (isConnectListener.current == false) {
      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${API_BASE_URL}/messagehub`, {})
        .build();

      signalRConnectionRef.current = hubConnection;

      hubConnection.on('whatsappListener_' + firmId, onOrderReceived);
      function startConnection() {
        hubConnection.start().catch(err => { console.log(err); reconnect(); }).then(response => {
          console.log("connected");
          isConnectListener.current = true;
        });
      }
      startConnection();

      function reconnect() {
        console.log('reconnecting...');
        setTimeout(startConnection, 2000);
      }

      hubConnection.onclose(reconnect);
    }

  }, []);

  const onOrderReceived = (data) => {

    appListenerAction(data);
    var listenerType = data.type;
    if (listenerType == globalConstant.listenerTypes.neworder || listenerType == globalConstant.listenerTypes.newcustomer) {
        selectHomePageNotificationListActions();
        selectUnreadNotificationCountActions();
    }
  }


  useEffect(() => {
    selectActivityStatusListAction();
  }, []);

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() }
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() }
    }
    return { paddingLeft: getLayoutGutter() }
  }

  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }
  return (
    <>
      <HeaderNav isMobile={isMobile} />
      {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRoute} /> : null}
      {(!firmAdminState) ? 
      <Layout className="app-container ">
        <Content className='mt-3'>
          <Outlet />
        </Content>
      </Layout> : 
      <Layout className="app-container">
        {(isNavSide && !isMobile) ? <SideNav routeInfo={currentRoute} /> : null}
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content pt-2 ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader display={currentRoute?.breadcrumb} title={currentRoute?.title} />
            <Content>
              <Outlet />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>}


      {isMobile && <MobileNav />}
    </>

  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;

  return { navCollapsed, navType, locale, }
};
function mapDispatchToProps(dispatch) {
  return {
    appListenerAction: bindActionCreators(appListener, dispatch),
    selectHomePageNotificationListActions: bindActionCreators(selectHomePageNotificationList, dispatch),
    selectUnreadNotificationCountActions: bindActionCreators(selectUnreadNotificationCount, dispatch),
    selectActivityStatusListAction: bindActionCreators(selectActivityStatusList, dispatch),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AppLayout));
// export default AppLayout;