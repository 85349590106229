import React, { Component, useEffect, useState } from 'react'
import network from 'network/network';
import { Form ,notification, Tooltip, Collapse, Button, message, Steps, theme, Select, Row, Col, Tag, Table, Checkbox, Card, Input, Space, Descriptions, Badge } from 'antd';
import { SendOutlined, CloseOutlined, CheckOutlined, EditOutlined, UnorderedListOutlined, AlignCenterOutlined, EyeOutlined, CopyOutlined, FontSizeOutlined, CheckCircleFilled, UsergroupAddOutlined, CheckCircleOutlined, ExclamationCircleOutlined, SearchOutlined, RollbackOutlined, ArrowRightOutlined, UserOutlined } from '@ant-design/icons';
import './Steps.css';
import Flex from 'components/shared-components/Flex';
import { select } from 'redux-saga/effects';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';


const { Panel } = Collapse;
const { Option } = Select;
const opt = [
    {
        value: 'Grup 1',
    },
    {
        value: 'Grup 2',
    },
    {
        value: 'Grup 3',
    },
    {
        value: 'Grup 4',
    },
];

const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color={'volcano'}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
                marginRight: 3,
            }}
        >
            {label}
        </Tag>
    );
};


const CreateCampaigns = (props) => {

    const [buttonVariable, setButtonVariable] = useState()
    const [fromData, setFromData] = useState([])
    const [toData, setToData] = useState(new Array())
    const [parameterData, setParameterData] = useState([])
    const [datas, setDatas] = useState([]);
    const [variable, setVariable] = useState(new Array());
    const [input, setInput] = useState(true);
    const [inputValue, setInputValue] = useState("");
    const [inputEnable, setInputEnable] = useState(false);
    // const [sendMessage, setSendMessage] = useState("");
    const [campaignState, setCampaignState] = useState({
        campaignName: "",
        campaignFrom: "",
        campaignTo: [],
        campaignToName: [],
        chooseTemplateId: "",
        chooseTemplateName: "",
        chooseTemplateMessage: "",
        chooseTemplateType: "",
        chooseTemplateDynamicCount: "",
        chooseTemplateDynamicButtonCount: "",
        chooseTemplateButtonsData: [],
        chooseTemplateContent: "",
    });

    const panelErrorStyle = {
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "red ",
        borderRadius: "5px",
        // 'bg-danger':value
        marginTop: "1px",
    }
    const panelInfoStyle = {

        // borderWidth: 1,
        // borderStyle: "solid",
        // borderColor: "#ecf0f4",
        // borderRadius: "1px"
        // 'bg-danger':value
    }
    const [fromValidation, setFromValidation] = useState(false);
    const [toValidation, setToValidation] = useState(false);
    const [chooseTemplateValidation, setChooseTemplateValidation] = useState(false);
    const [contentValidation, setContentValidation] = useState(false);
    const [campaignNameValidation, setCampaignNameValidation] = useState(false);
    const [error, setError] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    // const [validation, setValidation] = useState({
    //     fromValidation: false,
    //     toValidation: false,
    //     chooseTemplateValidation: false,
    //     contentValidation: false,
    // });

    useEffect(() => {
        responseData();
        responseFromData();
    }, [])
    const changeButtonEdit = () => {

        if (campaignState?.chooseTemplateDynamicButtonCount == 0) {
            let regex = /{{(\d+)}}/g;
            let text = campaignState.chooseTemplateButtonsData[0]
            let matches = String(text).match(regex);
            let button = [];
            let srtButton = "";
            let bracketop = "{{"
            let bracketcl = "}}"

            if (matches) {
                let currentIndex = 0;
                matches.forEach(matchValue => {

                    const number = parseInt(matchValue.match(/\d+/)[0], 10);

                    const matchIndex = text.indexOf(matchValue);

                    const outsideText = text.slice(currentIndex, matchIndex);

                    button.push((<span >{outsideText}</span>));
                    srtButton += outsideText;


                    // component.push((<span className=" text-danger  font-size-md" >{buttonVariable}</span>));

                    button.push((<span className=" text-danger  font-size-md">{bracketop}test{bracketcl}</span>));
                    srtButton += bracketop + buttonVariable + bracketcl
                    currentIndex = matchIndex + matchValue.length;

                });

                // Eğer metin, son eşleşme sonrasında devam ediyorsa, kalan kısmı da ekrana yazdır
                if (currentIndex < text.length) {
                    const remainingText = text.slice(currentIndex);

                    button.push((<span>{remainingText}</span>));
                    srtButton += remainingText
                }
            }
            else {
                // Eğer eşleşme bulunamadıysa, metnin tamamını dışındaki metin olarak kabul edebilirsiniz.
                button.push((<span>{text}</span>));
                srtButton += text
            }
            return srtButton;


        }
    }
    const panelValidation = (value) => ({

        borderWidth: value ? 1 : 1,
        borderStyle: value ? "solid " : "solid",
        borderColor: value ? "red " : "#ecf0f4",
        borderRadius: value ? "5px" : "5px"


        // 'bg-danger':value
    })
    const contentButton = () => {
        if (campaignState?.chooseTemplateDynamicButtonCount != 0) {//parametre varsa
            let regex = /{{(\d+)}}/g;
            let text = campaignState.chooseTemplateButtonsData[0]
            let matches = String(text).match(regex);
            let component = [];

            if (matches) {
                let currentIndex = 0;
                matches.forEach(matchValue => {
                    const number = parseInt(matchValue.match(/\d+/)[0], 10);
                    const matchIndex = text.indexOf(matchValue);

                    const outsideText = text.slice(currentIndex, matchIndex);
                    component.push((<span >{outsideText}</span>));



                    // component.push((<span className=" text-danger  font-size-md" >{buttonVariable}</span>));


                    component.push((<span className=" text-danger  font-size-md"><Input onChange={inputChange} style={{
                        width: "200px",
                        height: "30px",
                        marginTop: "10px",
                        marginBottom: "15px",
                        marginLeft: "5px",
                        marginRight: "5px",

                    }}></Input></span>));

                    currentIndex = matchIndex + matchValue.length;

                });

                // Eğer metin, son eşleşme sonrasında devam ediyorsa, kalan kısmı da ekrana yazdır
                if (currentIndex < text.length) {
                    const remainingText = text.slice(currentIndex);

                    component.push((<span>{remainingText}</span>));
                }
            }
            else {
                // Eğer eşleşme bulunamadıysa, metnin tamamını dışındaki metin olarak kabul edebilirsiniz.

                component.push((<span>{text}</span>));
            }
            return (
                <div >
                    <Col className='d-flex align-items-center justify-content-center'> <b>Buton İçeriği</b> 
                                                </Col>
                    <div className=" ml-4 p-4" style={{ background: "#e8e8e8", borderRadius: 10 }}>
                        {component}
                    </div>
                </div>
            )
        }
        // else {//parametre yoksa
        //     let text = campaignState.chooseTemplateButtonsData
        //     const words = String(text).split(',')
        //     let component = [];

        //     if (words.length > 1)// Linkli Button
        //     {
        //         console.log(words);
        //         component.push(words[0]);
        //         component.push(words[1]);

        //         return (
        //             <div >
        //                 {/* <Tooltip title={component}>
        //                     <div className=' mr-2 p-1 d-flex align-items-center justify-content-center' style={{ background: "#d3eebe", borderRadius: 5 }} >
        //                         <a >{component}<RollbackOutlined className='ml-2' style={{ fontSize: '18px' }} /></a>
        //                     </div>
        //                 </Tooltip> */}
        //                 <div >
        //                     <div className=" mt-3 ml-4 mb-5   p-4" style={{ background: "#e8e8e8", borderRadius: 10 }}>
        //                         {component}
        //                     </div>
        //                 </div>

        //             </div>
        //         )
        //     }
        //     // else {// Normal Buttom


        //     //     return (
        //     //         <div>
        //     //             <div >
        //     //                 <div className=" mt-3 ml-4 mb-5   p-4" style={{ background: "#e8e8e8", borderRadius: 10 }}>
        //     //                     {text}
        //     //                 </div>
        //     //             </div>
        //     //         </div>
        //     //     )
        //     // }

        // }
    }

    const phoneButton = () => {
        if (campaignState?.chooseTemplateDynamicButtonCount != 0) {//değişken varsa 
            let regex = /{{(\d+)}}/g;

            let text = campaignState.chooseTemplateButtonsData[0]

            text = text.substring(2, text.length - 1);

            let matches = String(text).match(regex);
            let content = [];
            let srtButton = "";
            const words = String(text).split(',')
            // content.push(words[0]);
            // content.push(words[1]);
            if (matches) {
                let currentIndex = 0;
                matches.forEach(matchValue => {
                    const number = parseInt(matchValue.match(/\d+/)[0], 10);
                    const matchIndex = text.indexOf(matchValue);
                    const outsideText = text.slice(currentIndex, matchIndex);
                    content.push((<span >{outsideText}</span>));
                    srtButton += outsideText;
                    // component.push((<span className=" text-danger  font-size-md" >{buttonVariable}</span>));
                    content.push((<span className=" text-danger  font-size-md">test</span>));
                    srtButton += buttonVariable;
                    currentIndex = matchIndex + matchValue.length;
                });

                // Eğer metin, son eşleşme sonrasında devam ediyorsa, kalan kısmı da ekrana yazdır
                if (currentIndex < text.length) {
                    const remainingText = text.slice(currentIndex);
                    content.push((<span>{remainingText}</span>));
                    srtButton += remainingText;

                }
            }
            else {
                // Eğer eşleşme bulunamadıysa, metnin tamamını dışındaki metin olarak kabul edebilirsiniz.
                content.push((<span>{text}</span>));
                srtButton += text;
            }
            const word = String(srtButton).split(',');

            return (
                <Tooltip placement="bottom" color={""} title={word[1]}>
                    <div className=' mr-2 p-1 d-flex align-items-center justify-content-center' style={{ background: "#d3eebe", borderRadius: 5 }} >
                        <a >{word[0]}</a>
                    </div>
                </Tooltip>
            )
        }
        else {//değişken yoksa
            let text = campaignState.chooseTemplateButtonsData

            const response = text.map(item => {
                const text = item.substring(2, item.length - 1);
                const words = String(text).split(',')
                console.log(text)
                if (words.length > 1)//Linkli olan
                {
                    console.log(words[0])
                    return <Tooltip placement="bottom" title={words[1]}>
                        <div className=' mr-2 p-1 d-flex align-items-center justify-content-center' style={{ background: "#d3eebe", borderRadius: 5 }} >
                            <a>{words[0]}</a>
                        </div>
                    </Tooltip>
                }
                else//c2a buton
                {
                    return <div className="mt-1 mr-2 p-1 d-flex align-items-center justify-content-center" style={{ background: "#d3eebe", borderRadius: 10 }}>
                        <a>{text} </a>
                    </div>
                }

            })
            return response
            // if (search == -1)// normal BUtton
            // {
            //     console.log("search girdi");
            //     return (
            //         <div >
            //             <div className=" mr-2 p-1 d-flex align-items-center justify-content-center" style={{ background: "#d3eebe", borderRadius: 10 }}>
            //                 <a>{text[0]} </a>
            //             </div>
            //             <div className="mt-1 mr-2 p-1 d-flex align-items-center justify-content-center" style={{ background: "#d3eebe", borderRadius: 10 }}>
            //                 <a>{text[1]} </a>
            //             </div>
            //         </div>
            //     )
            // }
            // else 
            // {// Linkli Button
            //     console.log("else");
            //     const words = String(text).split(',')
            //     content.push(words[0]);
            //     content.push(words[1]);
            //     console.log(words);
            //     return (
            //         <div >
            //             <Tooltip placement="bottom" title={words[1]}>
            //                 <div className=' mr-2 p-1 d-flex align-items-center justify-content-center' style={{ background: "#d3eebe", borderRadius: 5 }} >
            //                     <a >{words[0]}</a>
            //                 </div>
            //             </Tooltip>
            //         </div>
            //     )
            // }

        }
    }
    const getCampaignParameter = (parameterId) => {

        var index = parameterData.findIndex(w => w.parameterid == parameterId);


        return parameterData[index]?.parameterid
    }
    const getCampaignParameterName = (parameterId) => {

        var index = parameterData.findIndex(w => w.parameterid == parameterId);


        return parameterData[index]?.parametername
    }
    const getCampaignTo = (groupId) => {

        var index = toData.findIndex(w => w.groupid == groupId);
        return toData[index].groupname

    }
    const handleContentChange = (i, value) => {
        let tmpVariable = [...variable];
        tmpVariable[i] = value;
        setVariable(tmpVariable);
        console.log(i)
        console.log(variable)
        // try {
        //     setCampaignState({
        //         ...campaignState,
        //         chooseTemplateContent: value,
        //     })
        //     variable[i]:value;
        // } catch (error) {
        //     alert(error);
        // }


    };
    // const handleButtonContentChange = (i, label) => {
    //     setButtonVariable(label);
    // };

    const onFinish = async () => {
        var error = false;
        var params = {
            campaignName: campaignState.campaignName,
            firmBusinessNumberId: getCampaignRow().firmbusinessnumberid,
            firmBusinessNumber: getCampaignRow().businessnumber,
            groupIds: campaignState.campaignTo,
            templateId: campaignState.chooseTemplateId,
            templateType: campaignState.chooseTemplateType,
            message: sendMessage(),
            parameterIds: variable,
            campaignStatus: 1,
            buttonValue: buttonVariable,
        }
        console.log(params)
        setCampaignNameValidation(false);
        setFromValidation(false);
        setToValidation(false);
        setChooseTemplateValidation(false);
        setContentValidation(false);

        if (campaignState.campaignName == "") {
            setCampaignNameValidation(true);
            setInput(true);
            error = true;

        }
        if (getCampaignRow().firmbusinessnumberid == undefined) {
            setFromValidation(true);
            error = true;

        }
        if (campaignState.campaignTo.length == 0) {
            setToValidation(true);

        }
        if (campaignState.chooseTemplateId == "") {
            setChooseTemplateValidation(true);
            error = true;

        }
        if (!(campaignState.chooseTemplateId != "" && variable.length == campaignState.chooseTemplateDynamicCount)) {
            setContentValidation(true);
            error = true;

        }
        if (error == true) {

            api['error']({
                message: 'Mesajınız Gönderilemedi',
                description:
                    'Lütfen zorunlu alanları doldurunuz..',
            });
            return;
        }
        else {
            var result = await network.post(`/admin/Campaign/insertCampaign`, params);

            if (result.isSuccess) {
                var postResult = result;
                if (postResult.isSuccess) {
                    message.success('Mesajınız başarıyla gönderilmiştir.');
                    setTimeout(() => {
                    }, 250);

                } else {
                    alert(postResult.errorMessage);
                }

            } else {
                alert(result.errorMessage);
            }
        }

    }
    const DynamicSelectList = (value) => {
        let list = [];
        const index = campaignState.chooseTemplateDynamicCount ? campaignState.chooseTemplateDynamicCount : 0;
        for (let i = 0; i < index; i++)
            list.push(
                <Select
                    style={{
                        width: "130px",
                        height: "30px",
                        marginTop: "10px",
                        marginBottom: "15px"
                    }}
                    onChange={(value, label) => {
                        handleContentChange(i, value, label);
                    }}>
                    {parameterData.map((item, index) => (
                        <Option value={item.parameterid} >
                            {item.parametername}
                        </Option>
                    ))}
                </Select>

                // <div className='mx-3 ant-col ant-form-item-label'>
                //     <label className=' mb-2 ant-form-item-required' htmlFor="">Dinamik Mesaj {i + 1}</label>
                //     <Input placeholder="Dinamik Mesajınızı giriniz ..."
                //         onChange={(e) => {
                //             handleChange(i, e.target.value);
                //         }}

                //     // value={messageTemplate?.messageList}
                //     // onChange={(val)=>{

                //     //     let tmpVariable = [...variable];
                //     //     tmpVariable[i] = val.target.value;
                //     //     setVariable(tmpVariable);
                //     //     console.log(tmpVariable);
                //     // }}
                //     />
                // </div>
            );
        return list[value];
    };
    const getCampaignRow = () => {
        let index = fromData.findIndex(w => w.firmbusinessnumberid == campaignState.campaignFrom);
        if (index != -1) {
            return fromData[index];
        }
        return {};
    }


    const handleFromChange = (value, key) => {
        try {
            setCampaignState({
                ...campaignState,
                campaignFrom: value
            })

        } catch (error) {
            alert(error);
        }

    };
    const handleToChange = (value, label) => {

        try {
            setCampaignState({
                ...campaignState,
                campaignTo: value,
                campaignToName: label
            })

        } catch (error) {
            alert(error);
        }
    };
    const responseData = async () => {
        var result = await network.get("/admin/Campaign/sendTemplateMessage", false);
        const { data } = result;
        const tempData = data.tempData;
        console.log(result)
        setDatas(tempData);
    }
    const responseFromData = async () => {

        var fromResult = await network.get("/admin/Firm/selectFirmNumberList", false);
        const fromData = fromResult.data.data;
        // const testdata = fromData.data.data;

        setFromData(fromData);

        var toResult = await network.get("/admin/Group/selectGroupList", false);

        const toData = toResult.data.data;
        setToData(toData);

        var paramaterResult = await network.get("/admin/Parameter/selectParameterList", false);

        const parameterData = paramaterResult.data.data;
        setParameterData(parameterData);

    }
    const inputChange = (e) => {

        setButtonVariable(e.target.value)
    };
    const onCampNameInput = (e) => {

        setInputEnable(true);

        setInputValue(e.target.value);

    };
    const CampNameClick = () => {
        setInput(true);

    };
    const InputClick = () => {
        setInput(false);

    };
    const InputTrueClick = () => {
        if (inputValue != "") {
            setInput(false);
        }
        setCampaignState({
            ...campaignState,
            campaignName: inputValue
        })
        // setCampaignName(inputValue)

        setInputEnable(false);
    };
    const [columns, setColumns] = useState([
        {
            key: 1,
            title: 'Template Name',
            dataIndex: 'templateName',
            width: 100,
            colSpan: 1,
            render: (_, record) => (
                <div>
                    <div>{record.templateName}</div>
                    <div className='m-2 ml-0'>Type: {record.templatetype}</div>
                </div>
            )
        },
        {
            key: 2,
            title: 'Mesaj İçeriği',
            dataIndex: 'headData',
            width: 350,

        }
    ])
    const templateTypeFor = () => {

        if (campaignState.chooseTemplateType == "IMAGE") {
            return <div align="center" className='mb-1' >
                <img style={{ borderRadius: 7 }} src="https://i.hizliresim.com/7kb2hsj.png" width={"100%"} height={"100%"} />
            </div>
        }
        else if (campaignState.chooseTemplateType == "VIDEO")
            return <div align="center" className='mb-1' >
                <img style={{ borderRadius: 7 }} src="https://i.hizliresim.com/7kb2hsj.png" width={"100%"} height={"100%"} />
            </div>
        else if (campaignState.chooseTemplateType == "LOCATION") {
            return <div><img style={{ borderRadius: 7 }} src="https://i.hizliresim.com/7kb2hsj.png" width={"100%"} height={"100%"} /></div>
        };

    };
    const CampaignInput = () => (
        <Form>
            <Row className='m-4 ml-1' ><Form.Item rules={[{
                required: true,
                message: 'Please input your username!',
            },]}>
                <Col>

                    <Input placeholder="Kampanya Adını Giriniz.." onChange={onCampNameInput} defaultValue={campaignState.campaignName}
                        showCount maxLength={20} className={(campaignNameValidation) ? panelErrorStyle : ""} />
                </Col>
            </Form.Item>
                <Col>
                    {inputEnable ? <Button onClick={() => InputTrueClick()} className=' ml-3 p-1 pt-2'>{<CheckOutlined className='ml-2 ' style={{ fontSize: "24px", display: "inline" }} />} </Button>
                        : <Button className=' ml-3 p-1 pt-2' disabled>{<CheckOutlined className='ml-2 ' style={{ fontSize: "24px", display: "inline" }} />} </Button>}
                    <Button onClick={() => InputClick()} className=' ml-2 p-1 pt-2'  >{<CloseOutlined className='ml-2 ' style={{ fontSize: "24px", display: "inline" }} />} </Button>
                </Col>
                <Col><Tag className='ml-3 mt-2' color={"orange"}>Draft</Tag></Col>
            </Row>
        </Form>
    );

    const CampaignLabel = () => (

        <div style={{ display: "inline" }}>
            <Row className='m-4 ml-1'>
                <Col className='mt-1'><h2 style={{ display: "inline" }}>{campaignState.campaignName}</h2></Col>
                <Col><Button onClick={() => CampNameClick()} className=' ml-3 p-2'>{<EditOutlined className='ml-2 ' style={{ fontSize: "24px", display: "inline" }} />} </Button></Col>
                <Col><Tag className='ml-3 mt-2' color={"orange"}>Draft</Tag></Col>
            </Row>
        </div>

    );
    const headerFromExtra = () => (
        <div>
            <Row>
                <Col span={1} className='mr-2'>
                    <div>
                        <Row className='ml-1 mt-2'>
                            {campaignState.campaignFrom != "" ? <CheckCircleFilled style={{ fontSize: "24px", color: 'black' }} /> : <CheckCircleFilled style={{ fontSize: "24px", color: '#dbd9d2' }} />}
                        </Row>
                    </div>
                </Col>
                <Row>
                    <Col >
                        <div>
                            <h3>From</h3>
                        </div>
                        <div>
                            Who is sending this Template Message?
                        </div>
                    </Col>
                </Row>
            </Row>
            <Row className='mt-2 ml-1'>
                <Col span={1}>
                </Col >
                <Tag align="left" color="green">{getCampaignRow().friendlyname}</Tag>

            </Row>
        </div>
    );
    const headerToExtra = () => (
        <div>
            <Row>
                <Col span={1} className='mr-2'>
                    <div>
                        <Row className='ml-1 mt-2'>
                            {/* <CheckCircleFilled style={{ fontSize: "24px", color: '#dbd9d2' }} /> */}
                            {campaignState.campaignTo != "" ? <CheckCircleFilled style={{ fontSize: "24px", color: 'black' }} /> : <CheckCircleFilled style={{ fontSize: "24px", color: '#dbd9d2' }} />}

                        </Row>
                    </div>
                </Col>
                <Row>
                    <Col >
                        <div>
                            <h3>To</h3>
                        </div>
                        <div>
                            Who are you sending this Template Message to?
                        </div>
                    </Col>
                </Row>
            </Row>
            <Row className='mt-2 ml-1'>
                <Col span={1}>
                </Col >
                {/* {getCampaignParameter()} */}
                {campaignState.campaignTo.map((item, index) => <Tag className='m-1' color={"volcano"}>{getCampaignTo(item)}</Tag>)}
                {/* {variable.map((item, index) => <Tag className='m-1' color={"volcano"}>{getCampaignParameter(item)}</Tag>)} */}
            </Row>



        </div>

    );
    const headerChooseTempExtra = () => (
        <div>
            <Row>
                <Col span={1} className='mr-2'>
                    <div>
                        <Row className='ml-1 mt-2'>

                            {campaignState.chooseTemplateMessage != "" ? <CheckCircleFilled style={{ fontSize: "24px", color: 'black' }} /> : <CheckCircleFilled style={{ fontSize: "24px", color: '#dbd9d2' }} />}

                        </Row>
                    </div>
                </Col>
                <Row>
                    <Col >
                        <div>
                            <h3>Choose Template</h3>
                        </div>
                        <div>
                            Choose which template you will send with
                        </div>
                    </Col>
                </Row>

            </Row>
            <Row className='mt-2 ml-1'>
                <Col span={1}>
                </Col >
                <Tag align="left" color="blue">{campaignState.chooseTemplateName}</Tag>

            </Row>

        </div>

    );
    const headerContentExtra = () => (
        <div>
            <Row>
                <Col span={1} className='mr-2'>
                    <div>
                        <Row className='ml-1 mt-2'>
                            {(campaignState.chooseTemplateId != "" && variable.length == campaignState.chooseTemplateDynamicCount) ? <CheckCircleFilled style={{ fontSize: "24px", color: 'black' }} /> : <CheckCircleFilled style={{ fontSize: "24px", color: '#dbd9d2' }} />}
                        </Row>
                    </div>
                </Col>
                <Row>
                    <Col >
                        <div>
                            <h3>Content</h3>
                        </div>
                        <div>
                            Design the Content for your Template Message.
                        </div>
                    </Col>
                </Row>
            </Row>


        </div>

    );
    const genFromExtra = () => (
        <div>
            <Row className='mt-3 mr-3'>

                <UserOutlined style={{ fontSize: "24px" }} />
            </Row>
        </div>

    );
    const genToExtra = () => (
        <div>

            <Row className='mt-3 mr-3'>

                <UsergroupAddOutlined style={{ fontSize: "24px" }} />
            </Row>
        </div>

    );
    const genChooseTempExtra = () => (
        <div>
            <Row className='mt-3 mr-3'>
                <UnorderedListOutlined style={{ fontSize: "24px" }} />
            </Row>
        </div>

    );
    const genContentExtra = () => (
        <div>
            <Row className='mt-3 mr-3'>
                <FontSizeOutlined style={{ fontSize: "24px" }} />
            </Row>
        </div>

    );
    const sendMessage = () => {
        let regex = /{{(\d+)}}/g;
        let text = campaignState.chooseTemplateMessage;
        let matches = text.match(regex);
        let message = [];
        let bracketop = "{{"
        let bracketcl = "}}"
        let strMessage = "";
        let i = 0;
        if (matches) {
            let currentIndex = 0;

            matches.forEach(matchValue => {

                const number = parseInt(matchValue.match(/\d+/)[0], 10);

                const matchIndex = text.indexOf(matchValue);

                const outsideText = text.slice(currentIndex, matchIndex);
                strMessage += outsideText + bracketop + getCampaignParameter(variable[number - 1]) + bracketcl;

                message.push((<span >{outsideText}</span>));
                message.push((<span className=" text-danger font-size-md">{bracketop}{getCampaignParameter(1)}{bracketcl}</span>));
                currentIndex = matchIndex + matchValue.length;

            });

            // Eğer metin, son eşleşme sonrasında devam ediyorsa, kalan kısmı da ekrana yazdır
            if (currentIndex < text.length) {
                const remainingText = text.slice(currentIndex);
                strMessage += remainingText;
                message.push((<span>{remainingText}</span>));

            }
        } else {
            // Eğer eşleşme bulunamadıysa, metnin tamamını dışındaki metin olarak kabul edebilirsiniz.
            message.push((<span>{text}</span>));
            strMessage += text;
        }
        message.push(changeButtonEdit())
        strMessage += changeButtonEdit();

        return strMessage;

    };
    const messageEdit = (value) => {

        let regex = /{{(\d+)}}/g;
        let text = campaignState.chooseTemplateMessage;
        let matches = text.match(regex);
        let component = [];
        let content = [];
        if (matches) {
            let currentIndex = 0;

            matches.forEach(matchValue => {

                const number = parseInt(matchValue.match(/\d+/)[0], 10);

                const matchIndex = text.indexOf(matchValue);

                const outsideText = text.slice(currentIndex, matchIndex);

                component.push((<span >{outsideText}</span>));
                content.push((<span >{outsideText}</span>));
                // component.push((<span className="py-1 text-danger font-size-lg" >{buttonVariable}</span>));
                // if (variable[number - 1] == null) {
                component.push((<span className=" text-danger font-size-md">{getCampaignParameterName(variable[number - 1])}</span>));
                content.push((<span className=" m-1 text-danger font-size-md">
                    {(DynamicSelectList(number - 1))}
                </span>));
                // }
                currentIndex = matchIndex + matchValue.length;

            });

            // Eğer metin, son eşleşme sonrasında devam ediyorsa, kalan kısmı da ekrana yazdır
            if (currentIndex < text.length) {
                const remainingText = text.slice(currentIndex);
                component.push((<span>{remainingText}</span>));
                content.push((<span>{remainingText}</span>));
            }
        } else {
            // Eğer eşleşme bulunamadıysa, metnin tamamını dışındaki metin olarak kabul edebilirsiniz.
            component.push((<span>{text}</span>));
            content.push((<span>{text}</span>))

        }

        return value ? component : content;

    };

    return (
        <div >
            {contextHolder}
            <Row>
                <Col span={18}>
                    <Card>
                        <Row >
                            <Col style={panelInfoStyle} span={16}>
                                {input ? CampaignInput() : CampaignLabel()}
                            </Col>
                            <Col className='mt-4' span={4}>

                                <Button style={{ color: 'blue' }} className='mr-2' type="primary" icon={<CopyOutlined />} block>Taslağa Ekle</Button>

                            </Col>
                            <Col className='mt-4' span={4}>
                                <Col className='ml-2'>

                                    <Button onClick={onFinish} type="primary" icon={<SendOutlined />} block>Gönder</Button></Col>
                            </Col>
                        </Row>
                        <Col>
                            <div>
                                <Collapse
                                    defaultActiveKey={['1']}
                                    // onChange={onChangeAcco}
                                    expandIconPosition={'start'}
                                    accordion>
                                    <Panel key="1" style={(fromValidation) ? panelErrorStyle : panelInfoStyle} header={headerFromExtra()} extra={genFromExtra()} showArrow={false} >
                                        <Row className='mt-4'>
                                            <Col span={1}></Col>
                                            <Col className='' span={7}>
                                                <div align="left" style={{
                                                    width: "100%",
                                                }} className=' mt-2' ><h5>Gönderilicek Numara: </h5> </div>
                                            </Col>
                                            <Col span={10}>
                                                <Select
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    onChange={
                                                        handleFromChange}>
                                                    {fromData.map((item, index) => (
                                                        <Option key={item.firmbusinessnumberid} value={item.firmbusinessnumberid} >
                                                            {item.friendlyname}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className='mt-5  mb-4'>
                                            <Col span={1}></Col>
                                            <Col span={17}>
                                                <div style={{ wordBreak: "break-all", width: "100%" }}  >
                                                    <Tag style={{ wordBreak: "break-all", width: "100%" }} align="left" color="blue">
                                                        <ExclamationCircleOutlined className='my-3 mr-1' style={{ fontSize: "18px" }} /> Bir kampanya planlamak için  WhatsApp Business hesabınızı bağlamanız gereklidir. </Tag>
                                                </div>
                                            </Col>
                                        </Row>

                                    </Panel>
                                    <Panel key="2" style={(toValidation) ? panelErrorStyle : panelInfoStyle} header={headerToExtra()} extra={genToExtra()} showArrow={false} >
                                        {/* <Row className='mt-4 mb-4 ml-2'>
                                            <Col span={14}>
                                                <h2 className='ml-4' align="left" > Kampanyanın gönderileceği grupları ve segmentleri seçiniz </h2>
                                            </Col>
                                        </Row> */}
                                        <Row className='mt-4'>
                                            <Col span={1}></Col>
                                            <Col span={7} className=''>
                                                <div align="left" className='mt-2'><h5>Gönderilecek Gruplar : </h5> </div>
                                            </Col>
                                            <Col span={10}>
                                                <Select
                                                    mode="multiple"
                                                    onChange={handleToChange}
                                                    tagRender={tagRender}
                                                    style={{
                                                        width: '100%',
                                                    }}>
                                                    {toData.map((item, index) => (
                                                        <Option value={item.groupid} label={item.groupname} >
                                                            {item.groupname}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className='mt-5  mb-4'>
                                            <Col span={1}></Col>
                                            <Col span={17}>
                                                <div style={{ wordBreak: "break-all", width: "100%" }}  >
                                                    <Tag style={{ wordBreak: "break-all", width: "100%" }} align="left" color="blue">
                                                        <ExclamationCircleOutlined className='my-3 mr-1' style={{ fontSize: "18px" }} /> Gönderilecek grupları düzenlemek ve yeni grup oluşturmak için <a><u>Ayarlara</u></a> Gidiniz </Tag>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Panel>
                                    <Panel key="3" style={(chooseTemplateValidation) ? panelErrorStyle : panelInfoStyle} header={headerChooseTempExtra()} extra={genChooseTempExtra()} showArrow={false} >
                                        <div>
                                            <Row>
                                                <Col span={9} className=' mt-3 pl-3'>
                                                    <div className="mr-md-3 ">
                                                        <Input placeholder=" Template Ara" prefix={<SearchOutlined />} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="table-responsive  p-3">
                                                <Table
                                                    pagination={{ pageSize: 6 }}
                                                    columns={columns}
                                                    // loading={props.isLoading}
                                                    dataSource={datas}
                                                    rowKey='id'
                                                    // onChange={() => onChangeTemplate()}
                                                    rowSelection={
                                                        {
                                                            type: 'radio',
                                                            columnWidth: 48,
                                                            onSelect: (record) => {
                                                                { setVariable("") }

                                                                try {
                                                                    setCampaignState({
                                                                        ...campaignState,
                                                                        chooseTemplateId: record.id,
                                                                        chooseTemplateMessage: record.headData,
                                                                        chooseTemplateName: record.templateName,
                                                                        chooseTemplateDynamicCount: record.dynamicCount,
                                                                        chooseTemplateDynamicButtonCount: record.dynamicButtonCount,
                                                                        chooseTemplateButtonsData: record.buttonsData,
                                                                        chooseTemplateType: record.templatetype
                                                                    })
                                                                } catch (error) {
                                                                    alert(error);
                                                                }


                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Panel>
                                    <Panel key="4" style={(contentValidation) ? panelErrorStyle : panelInfoStyle} header={headerContentExtra()} extra={genContentExtra()} showArrow={false} >
                                        <div>
                                            <Row className=' d-flex align-items-center justify-content-center'>
                                            <Col className='d-flex align-items-center justify-content-center'> <b>Mesaj İçeriği</b> 
                                                </Col>
                                                <Col span={22} className='ml-2'>
                                                    <div className=" mt-1 ml-4 mb-4   p-4" style={{ background: "#e8e8e8", borderRadius: 10 }}>
                                                        {/* {component} */}
                                                        {/* {content} */}
                                                        {messageEdit(false)}
                                                    </div>
                                                </Col>
                                                
                                                <Col span={22} className='ml-2'>
                                                    
                                                    {contentButton()}
                                                </Col>
                                                {/* <Col span={22} className='ml-2'>
                                                    <div className=" ml-4 mb-5 pt-0  p-4" style={{ background: "#e8e8e8", borderRadius: 10 }}>
                                                       
                                                        {changeButton(false)}
                                                    </div>
                                                </Col> */}
                                            </Row>


                                        </div>
                                    </Panel>
                                </Collapse>
                            </div>
                        </Col>
                    </Card>
                </Col>
                <Col span={6}>
                    <Col style={{ position: "fixed" }} className='pl-2 ml-4 mt-2' span={7}>
                        <div style={{ backgroundColor: "#e5ddd5" }} class="smartphone">
                            <br></br>
                            <Row >
                                <Col span={4}>
                                </Col>
                                <Col span={20}>
                                    <div className=" mt-4 mr-2 p-1" style={{ background: "#d3eebe", borderRadius: 10 }}>
                                        {templateTypeFor()}
                                        <div className="p-1">{messageEdit(true)}</div>
                                    </div>
                                    <div className='my-1 '></div>
                                    <Col >
                                        {phoneButton()}
                                        {/* {contentButton()} */}
                                        {/* <a>Ödeme Yap<RollbackOutlined className='ml-2' style={{ fontSize: '18px' }} /></a> */}

                                        {/* <div className='my-1 '></div>
                                        <div className='mr-2 p-1 d-flex align-items-center justify-content-center' style={{ background: "#d3eebe", borderRadius: 5 }} >
                                            <a>Evet<ArrowRightOutlined className='ml-2' style={{ fontSize: '18px' }} /></a>
                                        </div> */}
                                    </Col>
                                </Col>
                            </Row>
                            <br></br>
                        </div>
                    </Col>
                </Col>
            </Row>
        </div>

    );

}


export default CreateCampaigns