import actionTypes from "utils/redux/actions/actionTypes";


const initialState = {
    notificationList: [],
    isLoading: false,
    hasError: false,
    errorMessage: "",
    homePageNotifyList: [],
    unreadNotificationCount: 0
};
export function notificationReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.SELECT_NOTIFICATION_LIST_SUCCESS:

            return {
                ...state,
                notificationList: action.payload
            };

        case actionTypes.SELECT_NOTIFICATION_LIST_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };

        case actionTypes.SELECT_NOTIFICATION_LIST_ERROR:
            return {
                ...state,
                hasError: action.hasError,
                errorMessage: action.errorMessage
            };
        case actionTypes.SELECT_HOME_NOTIFICATION_LIST_SUCCESS:
 
            return {
                ...state,
                homePageNotifyList: action.payload
            };
        case actionTypes.SELECT_UNREAD_NOTIFICATION_COUNT:

            return {
                ...state,
                unreadNotificationCount: action.payload
            };

        default:
            return state;
    }
}
