import { API_BASE_URL } from "constants/ApiConstant";
import globalConstant from "constants/globalConstant";
import React from "react"




const ProfileImageComponent = ({ imageName, imageType, nameSurname }) => {

    if (imageName && imageName.length > 0) {
        var imageUrl = "";
        switch (imageType) {
            case globalConstant.imageTypes.customerImage.type:
                imageUrl = API_BASE_URL + globalConstant.imageTypes.customerImage.imagePath;
                break;
            case globalConstant.imageTypes.userImage.type:
                imageUrl = API_BASE_URL + globalConstant.imageTypes.userImage.imagePath;
                break;

            default:
                break;
        }

        return(
            <img src={imageUrl} style={{width : 75, height : 75}}></img>
        );

    } else {
        var nameSurnameWord = "";
        var splitList = nameSurname.split(" ");
        var filtered = splitList.filter(function (el) {
            return el;
        });
        if (filtered.length > 1) {
            nameSurnameWord = splitList[0][0] + splitList[1][0]
        } else {
            nameSurnameWord = splitList[0][0] + splitList[0][1]
        }

        return (
            <div className="profileImage">
                {nameSurnameWord}
            </div>
        );
    }


}

export default ProfileImageComponent;
