import React, { Component, useEffect, useState } from 'react'
import Flex from 'components/shared-components/Flex';
import { Tabs, Form, Button, message, Input, Row, Col, Card,Menu } from 'antd';
import { Progress, Tooltip, Tag ,notification} from 'antd';
import { Badge, Descriptions, Table } from 'antd';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import {DeleteOutlined,EditOutlined} from '@ant-design/icons';
import network from 'network/network';
import helperFunc from 'helpers/helperFunc';
import { useNavigate, useParams } from 'react-router-dom';

import { PlusCircleOutlined, ClockCircleOutlined, SearchOutlined, TeamOutlined, RollbackOutlined, ArrowRightOutlined } from '@ant-design/icons';


const Groups = (props) => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");
    const [groupList, setGroupList] = useState([]);
    useEffect(() => {
        selectGroupList();
    }, []);

    const onSearch = e => {
        const value = e.currentTarget.value
        setSearchValue(value);
    }
    const deleteClick=()=>
    {
        console.log("Silindii")
    }
    const deleteGroupConfirm = async (row) => {
		// setLoadingPanel(true);
		var params = {
			groupId: row.groupid
		}

		var result = await network.get("/admin/Group/deleteGroup", params, false);

		// setTimeout(() => {
		// 	setLoadingPanel(false);
		// }, 250);

		if (result.isSuccess) {

			var data = result;
			if (data.isSuccess) {

				notification.success({
					message: `Başarılı`,
					description: "Group silme işlemi başarılı bir şekilde gerçekleştirildi",
					placement: "topRight",
				});
				// selectCustomerList();

			} else {
				notification.error({
					message: `Hata!`,
					description: data.errorMessage,
					// placement:"topRight",
				});
			}
		} else {
			notification.error({
				message: `Hata!`,
				description: result.errorMessage,
				placement: "topRight",
			});
		}
	}
    const deleteGroupPrevious = (row) => {

		helperFunc.showConfirm({
			content: `${row.groupname} adlı grup kalıcı olarak silinecek. Devam edilsin mi?`,
			title: "Hatırlatma", onConfirm: () => deleteGroupConfirm(row)
		});
	}
    const getDeleteGroupButton = (row) => {

			return {
					label:"Düzenle",
					key: '5',
					icon: <EditOutlined />,
					onClick: ()=> navigate(`/admin/Group/${row.groupid}`) 
			}
		
	}
    const getItems = elm => (
        <Menu>
            <Menu.Item onClick={()=> navigate(`groups-create/${elm.groupid}`)}>
                <Flex alignItems="center">
                    <EditOutlined />
                    <span className="ml-2">Düzenle</span>
                </Flex>
            </Menu.Item>
            <Menu.Item onClick={()=> deleteGroupPrevious(elm)} >
                <Flex alignItems="center">
                    <DeleteOutlined />
                    <span className="ml-2">Sil</span>
                </Flex>
            </Menu.Item>
        </Menu>
    )
        
        // return [
        //     {
                
        //         label: 'Müşteriyi Sil',
        //         key: '1',
        //         icon: <DeleteOutlined />,
        //         // onClick: () => deleteGroupPrevious(elm)
        //     },
            
        // ];
    

    const [columns, setColumns] = useState([

        {
            width: '20px',

        },
        {
            title: 'Grup Adı',
            dataIndex: 'GroupName',
            key: 'groupname',
            width: '300px',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, record) => (
                <div>
                    <div class="flex-container d-inline">
                        <div class="item  ">{record.groupname}</div>
                    </div>
                </div>
            )
        },
        {
            title: 'Müşteri Sayısı',
            dataIndex: 'customerCount',
            key: 'customerCount',
            width: '150px',
            align: 'center',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, record) => (
                <div>
                    <div class="flex-container d-inline">
                        <div class="item  ">20</div>
                    </div>
                </div>
            )
        },

        {
            title: 'Durumu',
            dataIndex: 'id',
            width: 250,
            align: 'center',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, elm) => {
                return (
                    <Tooltip title="Gönd. Tarihi : 2018-04-24 18:00 
                    Okunma Tarihi : 2018-04-24 18:00">
                        <div className='my-4 '>
                            <><Tag color={"green"}>Aktif</Tag></>
                        </div>
                    </Tooltip>

                )
            }

        },
        {
            title: 'Oluşturulma Tarihi',
            dataIndex: 'id',
            width: 250,
            align: 'center',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, elm) => {
                return (
                    <div className='my-4 '>
                        2018-04-24
                    </div>
                )
            }

        },
        {
            title: '',
            dataIndex: 'actions',
            width: 50,
            align: 'center',
            render: (_, elm) => (
                <div className="text-right">
                    <EllipsisDropdown menu={getItems(elm)} />
                </div>
            )
        }

    ])
    const data = [
        { campaignsName: 'Initial Message', createDate: '28.10.2023', groupName: 'ChatShire', status: 'Draft', recipients: 30, delivered: 28, read: 22 },
        { campaignsName: 'Campaigns Name', createDate: '28.11.2023', groupName: 'Gbm Yazılım', status: 'Completed', recipients: 38, delivered: 33, read: 28 },
        { campaignsName: 'Tester Name', createDate: '29.11.2020', groupName: 'Gbm ', status: 'Completed', recipients: 45, delivered: 50, read: 32 },
        { campaignsName: 'Start Message', createDate: '31.10.2023', groupName: 'letStart', status: 'Draft', recipients: 100, delivered: 28, read: 22 },
    ];
    const selectGroupList = async () => {

        var params = {
        }

        var result = await network.get("/admin/Group/selectGroupList", params, false);
        console.log(result);
        const { data } = result;
        if (result.isSuccess && data.isSuccess) {
            setGroupList(data.data);
        }
    }
    return (
        <div>
            <Card title="Grup Bilgisi">
                <Flex className="mt-4" alignItems="center" justifyContent="between" mobileFlex={false}>
                    <Flex className="mb-1" mobileFlex={false}>
                        <div className="mr-md-3 mb-3">
                            <Input placeholder="Gruplarda Ara " prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
                        </div>
                    </Flex>
                    <Flex className="mb-1" mobileFlex={false}>
                    </Flex>
                    <Flex className="mb-1" alignItems="center" justifyContent="between" mobileFlex={false}>
                        <div className="mr-md-3 mb-3">
                            <Button href={"groups/groups-create/0"} type="primary" icon={<PlusCircleOutlined />} block>Grup Oluştur</Button>
                        </div>
                    </Flex>
                </Flex>
                <Col span={24}><Table
                    columns={columns}
                    // loading={props.isLoading}
                    dataSource={groupList}
                    rowKey='id'
                /></Col>
            </Card>
        </div>
    );
}
export default Groups 