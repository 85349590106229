import React, { useEffect } from "react"
import { Button, Skeleton } from 'antd';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectLatestCustomerList } from "utils/redux/actions/defaultDashboardActions";
import ChartWidget from "components/shared-components/ChartWidget";
import AvatarStatus from "components/shared-components/AvatarStatus";
import {
    UserAddOutlined, 
  } from '@ant-design/icons';
import globalConstant from "constants/globalConstant";
import { useHistory, useNavigate } from "react-router-dom";

const LatestCustomerList = (props) => {

    const navigate = useNavigate();

    useEffect(() => {
        props.selectLatestCustomerListAction();
    }, []);

    const viewCustomerDetails = row => {
        navigate(`/customer/detail/${row.CUSTOMERID}`)
      }
      
    const getLatestCustomerList = () => {

        if (props.latestCustomerListLoading) {
            return (
                <>
                    <Skeleton loading paragraph={true} round />
                    <Skeleton loading paragraph={true} round />
                    <Skeleton loading paragraph={true} round />
                </>
            );
        } else {
            return props.latestCustomerList.map((elm, i) => (
                <div key={i} className={`d-flex align-items-center justify-content-between mb-4`}>
                    <AvatarStatus id={i} src={globalConstant.imageTypes.getImageUrl(globalConstant.imageTypes.customerImage.type, elm.CUSTOMERIMAGE)} name={elm.CLIENTNAME} subTitle={elm.CLIENTNUMBER} />
                    <div>
                        <Button onClick={() => viewCustomerDetails(elm)} icon={<UserAddOutlined />} type="default" size="small">Düzenle</Button>
                    </div>
                </div>
            ))
        }
    }

    return getLatestCustomerList();
}


function mapStateToProps(state) {

    return {
        latestCustomerList: state.dashboardReducer.latestCustomerList,
        latestCustomerListLoading: state.dashboardReducer.latestCustomerListLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectLatestCustomerListAction: bindActionCreators(selectLatestCustomerList, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LatestCustomerList);
