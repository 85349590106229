import React, { useState, useEffect, useRef } from 'react'
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt'
import { Tabs, Form, Button, message, Input, Row, Col, Card, Upload, InputNumber, Select } from 'antd';
import Flex from 'components/shared-components/Flex'
 
import { CloseOutlined } from '@ant-design/icons';
import network from 'network/network';
import globalConstant from 'constants/globalConstant';
import { useNavigate, useParams } from 'react-router-dom'; 
import { fileToBase64 } from 'helpers/fileHelper';
import { Helmet } from 'react-helmet';

const { Dragger } = Upload;
const { Option } = Select;


const { TabPane } = Tabs;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

const ADD = 'ADD'
const AddProduct = (props) => {

	let navigate = useNavigate();

	const { mode = ADD, param } = props 

	const [form] = Form.useForm();

	const mainImageFileUploadRef = useRef();

	const [uploadedImg, setImage] = useState('')
	const [uploadLoading, setUploadLoading] = useState(false)
	const [submitLoading, setSubmitLoading] = useState(false);
	const [getDetailLoading, setGetDetailLoading] = useState(true);
	const [productGroupList, setProductGroupList] = useState([]);

	const { id } = useParams();

	const [productState, setProductState] = useState({
		productName: "",
		productImage: "",
		productPrice: "",
		productGroup: -1,
		tags: [],
		productId: id
	});

	useEffect(() => {

		if (id > 0) {
			selectProductDetail();
		} else {
			setGetDetailLoading(false);
		}

	}, [form, mode, param, props]);

	useEffect(() => {

		selectProductGroups();

	}, []);

	const selectProductGroups = async () => {

		var params = { 
		}

		var result = await network.get("/admin/Products/selectProductGroupList", params, false);

		setProductGroupList((result.data.data || []));

	}

	const selectProductDetail = async () => {

		setGetDetailLoading(true);
		var params = {
			productId: id
		}

		var result = await network.get("/admin/Products/selectProductDetail", params, false);

		setTimeout(() => {
			setGetDetailLoading(false);
		}, 100);

	 
		if (result.isSuccess && result.data.isSuccess) {
			var prod = result.data.data;
			var tags = [];
			if (prod.tags) {
				tags = (prod.tags || "").split(",");
			}


		 
			setProductState({
				productGroup: prod.groupid,
				productId: prod.productid,
				productImage: prod.productimage || "",
				productName: prod.productname,
				productPrice: prod.productprice,
				tags: tags
			});
		} else {
			setProductState({
				...productState,
				productId: -1
			})
		}
	}

	const onCountryCodeChange = (val) => {

		try {
			setProductState({
				...productState,
				productGroup: val
			})
		} catch (error) {
			alert(error);
		}

	}
	const onTagChange = (val) => {


		try {
			setProductState({
				...productState,
				tags: val
			})
		} catch (error) {
			alert(error);
		}

	}


	const onFinish = async () => {


		if (productState.productName.length == 0 || productState.productPrice.toString().length == 0
			|| !(productState.productGroup > 0)) {
			message.error('Lütfen zorunlu alanları doldurunuz');
			return;
		}

		setSubmitLoading(true)

		var params = {
			Productid: parseInt(productState.productId),
			productImage: productState.productImage,
			Productname: productState.productName,
			Productprice: productState.productPrice.toString(),
			Groupid: productState.productGroup,
			Tags: productState.tags.join(","), 
		}
		var method = "insertProduct";
		if (params.Productid>0) {
			method = "updateProduct";
		}
		var result = await network.post(`/admin/Products/${method}`, params);

		setSubmitLoading(false)
		if (result.isSuccess) {
			var postResult = result.data;
			if (postResult.isSuccess) {

				message.success('Ürün bilgileri başarılı bir şekilde kaydedilmiştir.');

				setTimeout(() => {

					navigate(`/product-list`)
				}, 250);

			} else {
				alert(postResult.errorMessage);
			}

		} else {
			alert(result.errorMessage);
		}


	};



	const onFileUpload = async (event) => {

		var mainImageFromFile = event.target.files[0]
		console.log(mainImageFromFile);
		if (mainImageFromFile) {
			var base64 = await fileToBase64(mainImageFromFile);

			setProductState({
				...productState,
				productImage: base64
			});
		}
	};



	const getRemoveImageButton = () => {

		if (productState.productImage.length > 0) {

			return (
				<Button type="danger" onClick={() => {
					setProductState({
						...productState,
						productImage: ""
					});
				}} htmlType="button" shape='circle' loading={submitLoading}
					icon={<CloseOutlined></CloseOutlined>}
					style={{ float: "right" }}
				>
				</Button>
			);
		}
	}

	if (getDetailLoading) {
		return (
			<div>

			</div>
		);
	}

	const getimageUrl = () => {

		if (productState.productImage.length > 0) {
			if (productState.productImage.indexOf("data:image") != -1) {

				return productState.productImage;
			} else {

				var imageurl = globalConstant.imageTypes.getImageUrl(globalConstant.imageTypes.productImage.type, productState.productImage);

				return imageurl;
			}

		} else {
			return "";
		}
	}
	return (
		<>
			<Helmet>
				<title>{productState.productId > 0 ? 'Ürünü Güncelle' : `Ürün Ekle`}</title>
			</Helmet>
			<Form
				layout="vertical"
				name="advanced_search"
				className="ant-advanced-search-form"
			>
				<PageHeaderAlt className="border-bottom" overlap>
					<div className="container">
						<Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
							<h2 className="mb-3">{productState.productId > 0 ? 'Ürün Güncelle' : `Ürün Ekle`} </h2>
							<div className="mb-3">
								<Button type="primary" onClick={() => onFinish()} htmlType="submit" loading={submitLoading} >
									{productState.productId > 0 ? 'Güncelle' : `Ekle`}
								</Button>
							</div>
						</Flex>
					</div>
				</PageHeaderAlt>
				<div className="container">
					<Tabs defaultActiveKey="1" style={{ marginTop: 30 }}>
						<TabPane tab="Genel Bilgiler" key="1">
							<Row gutter={16}>
								<Col xs={24} sm={24} md={17}>
									<Card title="Ürün Bilgileri">
										<div className='ant-col ant-form-item-label'>
											<label className='ant-form-item-required' htmlFor="productName">Ürün Adı</label>
										</div>

										<Input value={productState.productName}
											defaultValue={productState.productName}
											onChange={(val) => {
												setProductState({
													...productState,
													productName: val.target.value
												});
											}}
											placeholder="Ürün Adı" />
										<div className='m-4'>

										</div>
										<Row gutter={16} >

											<Col xs={24} sm={24} md={12}>
												<div className='ant-col ant-form-item-label'>
													<label className='ant-form-item-required' htmlFor="customerName">Ürün Fiyatı</label>
												</div>

												<InputNumber
													value={productState.productPrice}
													defaultValue={productState.productPrice}
													onChange={(val) => {
														setProductState({
															...productState,
															productPrice: val
														});
													}}
													className="w-100"
													formatter={value => `₺ ${value}`}
													parser={value => value.replace(/\₺\s?|(,*)/g, '')}
												/>
											</Col>
										</Row>
									</Card>
								</Col>
								<Col xs={24} sm={24} md={7}>
									<Card title="Fotoğraf">
										<div className="row justify-content-center" style={{ width: "100%" }}>

											<div className="col-lg-6 col-md-6 col-sm-12" style={{ textAlign: "left" }}>
												{getRemoveImageButton()}
												<div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 25, cursor: "pointer" }}
													onClick={() =>
														mainImageFileUploadRef.current.click()
													}
												>
													{productState.productImage ?
														<>

															<img src={getimageUrl()} alt="avatar" className="img-fluid" />

														</>
														:
														(<><span>
															Fotoğraf Yükle
														</span>
															<img src='/img/avatars/picture.png' width={80}></img></>)}

												</div>

												<input
													ref={mainImageFileUploadRef}
													style={{ visibility: "hidden" }}
													type="file"
													accept="image/*"
													className="custom-file-input"
													id="inputGroupFile01"
													aria-describedby="inputGroupFileAddon01"
													onChange={onFileUpload}
												/>

											</div>
										</div>


									</Card>

									<Card title="Alt Bilgiler">
										<div className='ant-col ant-form-item-label'>
											<label className='ant-form-item-required' htmlFor="customerName">Ürün Kategorisi</label>
										</div>
										<Select className="w-100" placeholder="Ürün Kategorisi"
											onChange={onCountryCodeChange}
											defaultValue={productState.productGroup > 0 ? productState.productGroup : "Ürün Grubu"}
										> 
											{productGroupList.map((item, index) => (
												<Option key={item.groupname} value={item.groupid}>
													{item.groupname}
												</Option>
											))}
										</Select>
										<div className='m-4'></div>
										<div className='ant-col ant-form-item-label'>
											<label className='ant-form-item' htmlFor="customerName">Anahtar Kelimeler</label>
										</div>
										<Select mode="tags" style={{ width: '100%' }} placeholder="Tags"
											defaultValue={productState.tags}
											onChange={onTagChange}
										>
											<Option disabled >
												Anahtar Kelimeler
											</Option>

										</Select>
									</Card>

								</Col>
							</Row>
						</TabPane>
					</Tabs>
				</div>
			</Form>
		</>
	)
}

export default AddProduct
