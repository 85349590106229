import actionTypes from "../actions/actionTypes";


const initialState = {
    branchList: [],
    isLoading: false
};

export function branchReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SELECT_BRANCHLIST_SUCCESS:
            return {
                ...state,
                branchList: action.payload
            }
        case actionTypes.SELECT_BRANCHLIST_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        default:
            return state;
    }
}