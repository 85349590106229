import React, { useState } from "react";

import { Card, Table, Select, Input, Button, Badge, Menu, Tag, notification, Spin, Modal, Row, Col, Timeline } from 'antd';
import network from "network/network";
import globalConstant from "constants/globalConstant";
import { FontAwesomeIcon,  } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckDouble } from '@fortawesome/free-solid-svg-icons';

const MessageItemInformationModal = React.forwardRef((props, ref) => {

    const [modalVisible, setModalVisible] = useState(false);
    const [loadingVisible, setLoadingVisible] = useState(false);
    const [messageDetail, setMessageDetail] = useState({});

    React.useImperativeHandle(ref, () => ({
        getMessageInfo
    }));
    const getMessageInfo = async (mainMessageId) => {
        setLoadingVisible(true);
        setModalVisible(true);
        var params = {
            mainMessageId: mainMessageId
        }
        var result = await network.get("/messages/getSingleMessageInformation", params);
        setLoadingVisible(false);
        const { data } = result;

        if (result.isSuccess && data.isSuccess) {
            setMessageDetail(data.data);
        }
    }
    const closeModal = () => {
        setModalVisible(false)
        setMessageDetail({});
    }

    const getEventTitle = (event) => {

        const iconStyle = {
            marginRight : 5
        }
        switch (event) {
            case globalConstant.messageEventTypes.enqueued:
                return{
                    text : "Kuyruğa Alındı"
                }  
            case globalConstant.messageEventTypes.sent:
                return{
                    text : "Gönderildi",
                    icon : <FontAwesomeIcon style={{ ...iconStyle  }} icon={faCheck} />
                } 
            case globalConstant.messageEventTypes.delivered:
                return{
                    text : "Teslim Edildi",
                    icon : <FontAwesomeIcon style={{  ...iconStyle }} icon={faCheckDouble} />
                }  
            case globalConstant.messageEventTypes.read:
                return{
                    text : "Okundu",
                    icon : <FontAwesomeIcon style={{  color:"green", ...iconStyle }} icon={faCheckDouble} />
                }  


            default:
                break;
        }
    }

    const getDetailView = () => {

        if (messageDetail.detailList && messageDetail.detailList.length > 0) {
            return messageDetail.detailList.map((item, index) => {
                const messageInfo = getEventTitle(item.type);


                return (
                    <Timeline.Item label={item.date + " - " + item.time} >
                        {messageInfo.text}
                  
                    </Timeline.Item>
                );
            })
        }


    }
    return (
        <Modal
            visible={modalVisible}
            title="Mesaj Bilgileri"
            onCancel={() => closeModal()}
            footer={[
                <Button key="back" onClick={() => closeModal()}>
                    Kapat
                </Button>
            ]}
        >
            <Spin tip="Yükleniyor..." spinning={loadingVisible}>
                <Card >
                    <Timeline mode={"left"}>
                        <Timeline.Item label={messageDetail.systemSendDate}>Sistemden Gönderildi</Timeline.Item>
                        {getDetailView()}

                        {/* <Timeline.Item label="2015-09-01">Create a services</Timeline.Item>
                        <Timeline.Item label="2015-09-01 09:12:11">Solve initial network problems</Timeline.Item>
                        <Timeline.Item>Technical testing</Timeline.Item>
                        <Timeline.Item label="2015-09-01 09:12:11">Network problems being solved</Timeline.Item> */}
                    </Timeline>
                </Card>

            </Spin>
        </Modal>
    );
})
export default MessageItemInformationModal;