import React from 'react';
import { SyncOutlined } from '@ant-design/icons';

const NavRefresh = () => {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <button onClick={handleRefresh} style={buttonStyle}>
      <SyncOutlined style={iconStyle} />
    </button>
  );
};

const buttonStyle = {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
};

const iconStyle = {
  color: 'gray', 
  fontSize: '15px', 
};

export default NavRefresh;
