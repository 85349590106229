import actionTypes from "utils/redux/actions/actionTypes";

 


const initialState = {
    requestInfoList: [], 
    isLoading: false,
    hasError: false,
    errorMessage: "", 
};
export function requestInfoReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.SELECT_REQUEST_INFO_LIST_SUCCESS:

            return {
                ...state,
                requestInfoList : action.payload
            };

        case actionTypes.SELECT_REQUEST_INFO_LIST_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
  
        case actionTypes.SELECT_REQUEST_INFO_LIST_ERROR:
            return {
                ...state,
                hasError: action.hasError,
                errorMessage: action.errorMessage
            }; 
        default:
            return state;
    }
}
