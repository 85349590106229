import globalConstant from "constants/globalConstant";
import actionTypes from "utils/redux/actions/actionTypes";




const initialState = {
    leftMenuList: [],
    initialRouteName : "",
    isLoading: false,
    hasError: false,
    errorMessage: "", 
};
export function leftMenuReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.SELECT_MENU_LIST_SUCCESS:

        var data = action.payload;
        var menuList = data.menuList;
        var initialRouteName = data.initialRouteName;

        localStorage.setItem("initialRouteName", initialRouteName);

            return {
                ...state,
                leftMenuList: menuList,
                initialRouteName : initialRouteName
            };

        case actionTypes.SELECT_MENU_LIST_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };

        case actionTypes.SELECT_MENU_LIST_ERROR:

            return {
                ...state,
                hasError: action.hasError,
                errorMessage: action.errorMessage
            }; 
        default:
            return state;
    }
}