import { Modal, Button } from 'antd';
import { API_BASE_URL } from 'constants/ApiConstant';
import globalConstant from 'constants/globalConstant';
import { LEFT_MENU_LIST } from 'constants/StorageConstant';
import AppLocale from "lang";
import navigationConfig from "configs/NavigationConfig";
const { confirm } = Modal;





const helperFunc = {

  showConfirm({ onConfirm, title, content }) {
    confirm({
      title: title,
      content: content,
      onOk() {
        return onConfirm();
      },
      onCancel() { },
    });
  },

  getLocaleText(localeKey, title) {
    var key = AppLocale[localeKey];


    return key.messages[title]

  },

  playSound() {
    const sound = API_BASE_URL + globalConstant.soundFolderPaths.newOrderSound + "newOrderSound.wav";
    var audio = new Audio(sound);

    setTimeout(() => {
      audio.play();
    }, 500);

    setTimeout(() => {
      audio.remove();
    }, 2500);
  },

  //  getLeftMenuList2(selectedValue){
  //   try {
  //     if(selectedValue=="order"){
  //       return orderMenuData;
  //     }else if(selectedValue=="reservation"){
  //       return orderMenuData;
  //     }else{
  //       return orderMenuData;
  //     }
      
  //   } catch (error) {
  //     console.error('Error reading menu data:', error);
  //     return null;
  //   }
  // },
  
  getLeftMenuList() {

    var menuJson = [];
    var menuList = localStorage.getItem(LEFT_MENU_LIST);
    if (menuList && menuList.length > 0) {
      menuJson = JSON.parse(menuList)
    }
    
    //console.log({menuJson});
    //console.log({navigationConfig});
    return menuJson;

  }
}
export default helperFunc;