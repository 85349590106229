import { Select } from 'antd';

const { Option } = Select;

const CustomSelect = ({ menuListData, selectedValue, handleChange }) => {

      return (
        <Select value={selectedValue} onChange={handleChange} style={{width:"100%"}}>
          {menuListData.map((app) => (
            <Option key={app.appId} value={app.appName}>
              {(app.appName)}
            </Option>
          ))}
        </Select>
      );
    };

export default CustomSelect;