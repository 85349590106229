import actionTypes from "utils/redux/actions/actionTypes";

 


const initialState = {
    latestReservationList: [], 
    latestResservationListLoading: false,
    hasError: false,
    errorMessage: "", 
};
export function defaultDashboardReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.SELECT_D_LATEST_RESERVATIONS_SUCCESS:

            return {
                ...state,
                latestReservationList: action.payload
            };
        case actionTypes.SELECT_D_LATEST_RESERVATIONS_LOADING:

            return {
                ...state,
                latestResservationListLoading: action.isLoading
            };

        default:
            return state;
    }
}
