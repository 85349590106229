import React, { Component, useEffect, useState } from 'react'
import Flex from 'components/shared-components/Flex';
import { Tabs, Form, Button, message, Input, Row, Col, Card, } from 'antd';
import { Progress, Tooltip, Tag } from 'antd';
import { Badge, Descriptions, Table } from 'antd';
import './phone.css'
import { ClockCircleOutlined, SearchOutlined, TeamOutlined, RollbackOutlined, ArrowRightOutlined } from '@ant-design/icons';


const CampaignsDetail = (props) => {
    const [searchValue, setSearchValue] = useState("");
    const onSearch = e => {
        const value = e.currentTarget.value
        setSearchValue(value);
    }
    const [columns, setColumns] = useState([
        {

            dataIndex: 'campaignsName',
            key: 'campaignsName',
            width: '30px',
            colSpan: 1,
            align: 'center',
            render: (_, record) => (
                <div>
                    <span>
                        <img src='/img/avatars/user-blank-image.jpg' width={50}></img>
                    </span>

                </div>
            )
        },
        {
            title: 'Müşteri Adı',
            dataIndex: 'CustomerName',
            key: 'CustomerName',
            width: '350px',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, record) => (
                <div>
                    <div class="flex-container d-inline">
                        <div class="item  ">Muhammed Danışman</div>
                    </div>
                </div>
            )
        },
        {
            title: 'Telefon Numarası ',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: '150px',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, record) => (
                <div>
                    <div class="flex-container d-inline">
                        <div class="item  ">+905443777170</div>
                    </div>
                </div>
            )
        },

        {
            title: 'Durumu',
            dataIndex: 'id',
            width: 250,
            align: 'center',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, elm) => {
                return (
                    <Tooltip title="Gönd. Tarihi : 2018-04-24 18:00 
                    Okunma Tarihi : 2018-04-24 18:00">
                        <div className='my-4 '>
                            <><Tag color={"green"}>Read</Tag></>
                        </div>
                    </Tooltip>

                )
            }

        },
        {
            title: 'Durum Tarihi',
            dataIndex: 'id',
            width: 250,
            align: 'center',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, elm) => {
                return (
                    <div className='my-4 '>
                        2018-04-24 18:00:00
                    </div>
                )
            }

        },

    ])



    const data = [
        { campaignsName: 'Initial Message', createDate: '28.10.2023', groupName: 'ChatShire', status: 'Draft', recipients: 30, delivered: 28, read: 22 },
        { campaignsName: 'Campaigns Name', createDate: '28.11.2023', groupName: 'Gbm Yazılım', status: 'Completed', recipients: 38, delivered: 33, read: 28 },
        { campaignsName: 'Tester Name', createDate: '29.11.2020', groupName: 'Gbm ', status: 'Completed', recipients: 45, delivered: 50, read: 32 },
        { campaignsName: 'Start Message', createDate: '31.10.2023', groupName: 'letStart', status: 'Draft', recipients: 100, delivered: 28, read: 22 },
    ];


    if (searchValue.length > 0) {
        data = data.filter(function (el) {
            return (el.campaignName.toUpperCase().indexOf(searchValue.toUpperCase()) != -1 ||
                (el.createDate || "").toString().toUpperCase().indexOf(searchValue.toUpperCase()) != -1
                // ||(el.groupname ||"").toString().toUpperCase().indexOf(searchValue.toUpperCase()) != -1
            )
        });
    }


    return (
        <div>

            <Card title="Kampanya İstatistikleri">
                <Row gutter={12}>
                    <Col xs={12} sm={12} md={8} lg={8} xl={4}>
                        <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                            <Row className='mr-1'>
                                <Col span={19}><h4 className='ml-1 ' >Alıcı </h4>
                                </Col>
                                <Col span={4} >
                                    <TeamOutlined className='mt-1 ml-2 d-flex align-items-center justify-content-center' style={{ fontSize: '21px', color: "#52c41a" }} />
                                </Col>
                            </Row>
                            <br></br>
                            <h1 className=' ml-2 mb-3'>100 </h1>
                            <Row className='ml-1 mr-2'>
                                <Col span={18}>
                                <Progress strokeColor={"#52c41a"} className="mt-1 mr-1 mb-1 " percent={99} showInfo={false} />
                                </Col>
                                <Col span={6}>
                                    <h4 className='mt-1 ml-2'>%99</h4>
                                </Col>
                            </Row>
                            {/* <Progress strokeColor={"#52c41a"} className=" mb-1 " percent={99} /> */}
                        </Card>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={4}>
                        <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                            <Row className='mr-1'>
                                <Col span={19}><h4 className='ml-1 ' >Gönderildi </h4>
                                </Col>
                                <Col span={4} >

                                    <h2 className='ml-3' style={{ color: "#697780", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 12 11" height="19" width="19" preserveAspectRatio="xMidYMid meet" class="" fill="none"><title>msg-check</title><path d="M11.1549 0.652832C11.0745 0.585124 10.9729 0.55127 10.8502 0.55127C10.7021 0.55127 10.5751 0.610514 10.4693 0.729004L4.28038 8.36523L1.87461 6.09277C1.8323 6.04622 1.78151 6.01025 1.72227 5.98486C1.66303 5.95947 1.60166 5.94678 1.53819 5.94678C1.407 5.94678 1.29275 5.99544 1.19541 6.09277L0.884379 6.40381C0.79128 6.49268 0.744731 6.60482 0.744731 6.74023C0.744731 6.87565 0.79128 6.98991 0.884379 7.08301L3.88047 10.0791C4.02859 10.2145 4.19574 10.2822 4.38194 10.2822C4.48773 10.2822 4.58929 10.259 4.68663 10.2124C4.78396 10.1659 4.86436 10.1003 4.92784 10.0156L11.5738 1.59863C11.6458 1.5013 11.6817 1.40186 11.6817 1.30029C11.6817 1.14372 11.6183 1.01888 11.4913 0.925781L11.1549 0.652832Z" fill="currentcolor"></path></svg> </h2>
                                </Col>
                            </Row>
                            <br></br>
                            <h1 className=' ml-2 mb-3'>95</h1>
                            <Row className='ml-1 mr-2'>
                                <Col span={18}>
                                <Progress strokeColor={"#697780"} className="mt-1 mr-1 mb-1 " percent={95} showInfo={false} />
                                </Col>
                                <Col span={6}>
                                    <h4 className='mt-1 ml-2'>%95</h4>
                                </Col>
                            </Row>
                            {/* <Progress strokeColor={"#697780"} className=" mr-1 mb-1 " percent={95} /> */}
                        </Card>

                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={4}>
                        <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                            <Row className='mr-1'>
                                <Col span={19}><h4 className='ml-1 ' >Teslim Edildi</h4>
                                </Col>
                                <Col span={4} >

                                    <h2 className='ml-3' style={{ color: "#697780", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 18 18" height="21" width="21" preserveAspectRatio="xMidYMid meet" class="dh5rsm73 hpdpob1j" version="1.1" x="0px" y="0px" enable-background=""><title>status-dblcheck</title><path fill="currentColor" d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"></path></svg></h2>
                                </Col>
                            </Row >
                            <br></br>
                            <h1 className=' ml-2 mb-3'>85</h1>
                            <Row className='ml-1 mr-2' >
                                <Col span={18}>
                                <Progress strokeColor={"#697780"} className="mt-1 mr-1 mb-1 " percent={85} showInfo={false} />
                                </Col>
                                <Col span={6}>
                                    <h4 className='mt-1 ml-2'>%85</h4>
                                </Col>
                            </Row>
                            {/* <Progress strokeColor={"#697780"} className=" mr-1 mb-1 " percent={85} /> */}
                        </Card>
                    </Col>

                    <Col xs={12} sm={12} md={8} lg={8} xl={4} >
                        <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                            <Row className='mr-1'>
                                <Col span={19}><h4 className='ml-1 ' >Okundu </h4>
                                </Col>
                                <Col span={4} >
                                    <h2 className='ml-3' style={{ color: "#53bdea", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 18 18" height="21" width="21" preserveAspectRatio="xMidYMid meet" class="dh5rsm73 hpdpob1j" version="1.1" x="0px" y="0px" enable-background=""><title>status-dblcheck</title><path fill="currentColor" d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"></path></svg></h2>

                                </Col>
                            </Row >
                            <br></br>
                            <h1 className=' ml-2 mb-3'>70</h1>
                            <Row className='ml-1 mr-2'>
                                <Col span={18}>
                                <Progress strokeColor={"#53bdea"} className="mt-1 mr-1 mb-1 " percent={70} showInfo={false} />
                                </Col>
                                <Col span={6}>
                                    <h4 className='mt-1 ml-2'>%70</h4>
                                </Col>
                            </Row>
                            {/* <Progress strokeColor={"#53bdea"} className=" mr-1 mb-1 " percent={70} /> */}
                        </Card>

                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={4}>
                        <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                            <Row className='mr-1'>
                                <Col span={19}><h4 className='ml-1 ' >İletilemedi </h4>
                                </Col>
                                <Col span={4} >
                                    <h2 className='ml-3' style={{ color: "red", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 16 15" height="22" width="22" preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="new 0 0 16 15"><title>msg-time</title><path fill="currentColor" d="M9.75,7.713H8.244V5.359c0-0.276-0.224-0.5-0.5-0.5H7.65c-0.276,0-0.5,0.224-0.5,0.5v2.947 c0,0.276,0.224,0.5,0.5,0.5h0.094c0.001,0,0.002-0.001,0.003-0.001S7.749,8.807,7.75,8.807h2c0.276,0,0.5-0.224,0.5-0.5V8.213 C10.25,7.937,10.026,7.713,9.75,7.713z M9.75,2.45h-3.5c-1.82,0-3.3,1.48-3.3,3.3v3.5c0,1.82,1.48,3.3,3.3,3.3h3.5 c1.82,0,3.3-1.48,3.3-3.3v-3.5C13.05,3.93,11.57,2.45,9.75,2.45z M11.75,9.25c0,1.105-0.895,2-2,2h-3.5c-1.104,0-2-0.895-2-2v-3.5 c0-1.104,0.896-2,2-2h3.5c1.105,0,2,0.896,2,2V9.25z"></path></svg></h2>

                                </Col>
                            </Row>
                            <br></br>
                            <h1 className=' ml-2 mb-3'>5</h1>
                            <Row className='ml-1 mr-2'>
                                <Col span={19}>
                                <Progress strokeColor={"red"} className="mt-1 mr-1 mb-1 " percent={5} showInfo={false} />
                                </Col>
                                <Col span={5}>
                                    <h4 className='mt-1 ml-2'>%5</h4>
                                </Col>
                            </Row>
                           
                        </Card>

                    </Col>


                    {/* <Col className='text-center' span={4} >
                        <Card bodyStyle={{ padding: 8 }}  >
                            <h1><UserDeleteOutlined /></h1>

                            <div className="rounded" style={{ height: "2px", background: '#cd5c5c', border: "20px" }}></div>
                            <h2 className='mt-3 '> 5</h2>
                            <h4 >Abonelikten Çıkanlar</h4>
                        </Card>
                    </Col> */}
                    <Col className='text-center mock-block' xs={12} sm={12} md={8} lg={8} xl={4}>
                        <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                            <h4 className='ml-1 mb-4' >Alıcı-Okunma Oranı</h4>
                            <Tooltip title="Gönderilme / Teslim Edilme  = %80 ">
                                <Progress className='sm:mb-0 mb-2' percent={80} success={{ percent: 70 }} width={95} type="circle" />
                            </Tooltip>
                        </Card>
                    </Col>
                </Row>
            </Card>
            <Card title='Kampanya Detayları' >
                <div>
                    <Row >
                        <Col className='mt-2' span={14}>
                            <Descriptions bordered>
                                <Descriptions.Item label="Kampanya Adı" span={3}>Gbm Yazılım Kampanyası</Descriptions.Item>
                                <Descriptions.Item label="Gönderilen Numara" span={3}>+905443777170</Descriptions.Item>
                                <Descriptions.Item label="Durumu " span={3}>
                                    <Badge status="success" text="Complated" />
                                </Descriptions.Item>
                                <Descriptions.Item label="Oluşturulma Tarihi" span={3}>2018-04-24 18:00:00</Descriptions.Item>
                                <Descriptions.Item label="Gönderilen Gruplar " span={3}>
                                    <Tag className='m-1' color={"volcano"}>#ChatShire</Tag>
                                    <Tag className='m-1' color={"volcano"}>#Gbm </Tag>
                                    <Tag className='m-1' color={"volcano"}>#LogoYazılım</Tag>
                                    <Tag className='m-1' color={"volcano"}>#Grup1 </Tag>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={2}>
                        </Col>

                        <Col style={{ background: "#e5ddd5", borderRadius: 10}} className='mt-1  ml-3' span={6}>
                            <Row>
                                <Col span={5}>
                                </Col>
                                <Col span={19}  className='my-3'>

                                    <div className=" mt-3 mr-2 p-1 " style={{ background: "#d3eebe", borderRadius: 10 }}>
                                        <div align="center" className='mb-1' >
                                            <img style={{ borderRadius: 7 }} src="https://i.hizliresim.com/7kb2hsj.png" width={"100%"} height={"100%"} />
                                        </div>
                                        <div className='p-1 ' > Merhaba bu Test1 tarafından gönderilmiş Test mesajıdır lütfen dikkate almayınız
                                        Merhaba bu Test1 tarafından gönderilmiş Test mesajıdır lütfen dikkate almayınız
                                        </div>
                                       
                                    </div>
                                    <div className='my-1 '></div>
                                    <Col span={24}>
                                        <div className=' mr-2 p-1 d-flex align-items-center justify-content-center' style={{ background: "#d3eebe", borderRadius: 5 }} >
                                            <a> Ödeme Yap<RollbackOutlined className='ml-2' style={{ fontSize: '18px' }} /></a>
                                        </div>
                                        <div className='my-1 '></div>
                                        <div className='mr-2 p-1 d-flex align-items-center justify-content-center mb-3' style={{ background: "#d3eebe", borderRadius: 5 }} >
                                            <a>Evet<ArrowRightOutlined className='ml-2' style={{ fontSize: '18px' }} /></a>
                                        </div>

                                    </Col>
                                </Col>

                            </Row>
                            {/* <Descriptions contentStyle={{ backgroundColor: "green",padding:"0px"}}   layout='vertical'  bordered>
                                <Descriptions.Item contentStyle={{ backgroundColor: "green",padding:"0px"}} label="Gönderilen Mesaj İçeriği" span={3}>
                                    <br></br>
                                    <Row >
                                        <Col span={3}>
                                        </Col>  
                                        <Col span={21}>
                                            <div className="  mr-2 p-3" style={{ background: "#d3eebe", borderRadius: 10 }}>
                                                <div className=' mr-2 p-1 d-flex align-items-center justify-content-center' ></div>
                                                
                                                Merhaba bu Test1 tarafından gönderilmiş Test mesajıdır lütfen dikkate almayınız
                                                Merhaba bu Test1 tarafından gönderilmiş Test mesajıdır lütfen dikkate almayınız
                                            </div>
                                            <div className='my-1 '></div>
                                            <Col span={24}>
                                                <div className=' mr-2 p-1 d-flex align-items-center justify-content-center' style={{ background: "#d3eebe", borderRadius: 5 }} >
                                                    <a> Ödeme Yap<RollbackOutlined className='ml-2' style={{ fontSize: '18px' }} /></a>
                                                </div>
                                                <div className='my-1 '></div>
                                                <div className='mr-2 p-1 d-flex align-items-center justify-content-center' style={{ background: "#d3eebe", borderRadius: 5 }} >
                                                    <a>Evet<ArrowRightOutlined className='ml-2' style={{ fontSize: '18px' }} /></a>
                                                </div>
                                                
                                            </Col>
                                        </Col>

                                    </Row>
                                    <br></br>

                                </Descriptions.Item>
                            </Descriptions> */}

                        </Col>
                        <Col span={1}>
                        </Col>
                    </Row>



                </div>
            </Card>
            <Card title="Alıcı Bilgisi">
                <Flex className="mb-1" mobileFlex={false}>
                    <div className="mr-md-3 mb-3">
                        <Input placeholder="Müşteri Adı / Telefon Numarası Ara " prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
                    </div>
                </Flex>
                <Col span={24}><Table
                    columns={columns}
                    // loading={props.isLoading}
                    dataSource={data}
                    rowKey='id'
                /></Col>
            </Card>


        </div>
    );

}


export default CampaignsDetail