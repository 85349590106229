import actionTypes from "utils/redux/actions/actionTypes";

const initialState = {
    chatList: [],
    isLoading: false,
    hasError: false,
    errorMessage: "",
};
export function chatReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SELECT_CHAT_LIST_SUCCESS:
            return {
                ...state,
                chatList: action.payload
            };

        case actionTypes.SELECT_CHAT_LIST_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };

        case actionTypes.SELECT_CHAT_LIST_ERROR:
            return {
                ...state,
                hasError: action.hasError,
                errorMessage: action.errorMessage
            };

        case actionTypes.CHAT_MASTER_UPDATE_PREV_TEXT:

            var newList = JSON.parse(JSON.stringify(state.chatList));
            var index = newList.findIndex(w => w.customerNumber == action.customerNumber);

            if (index != -1) {
                newList[index].lastWrittenMessage = action.newText;
                newList[index].unreadMessageCount = newList[index].unreadMessageCount + 1;

            }

            return {
                ...state,
                chatList: newList
            };
        case actionTypes.RESET_UNREAD_COUNT:

            var newList = JSON.parse(JSON.stringify(state.chatList));
            var index = newList.findIndex(w => w.customerNumber == action.customerNumber);

            if (index != -1) {
                newList[index].unreadMessageCount = 0;
            }

            return {
                ...state,
                chatList: newList
            };
        case actionTypes.ON_CHAT_LISTENER: 
            var data = action.payload;
            var destination = data.destination;

            var newList = JSON.parse(JSON.stringify(state.chatList));
            var numberIndex = newList.findIndex(w => w.customerNumber == destination);

            if (numberIndex == -1) {
                var chatObj = {
                    customerId: -1,
                    customerImage: "",
                    customerName: destination,
                    firmId: -1,
                    lastMessageDate: "",
                    lastWrittenMessage: action.payload.lastWrittenMessage,
                    unreadMessageCount: 1,
                    customerNumber : destination

                }
                newList.push(chatObj);

                return {
                    ...state,
                    chatList: newList
                };

            } else {
                return {
                    ...state,
                }
            }

        default:
            return state;
    }
}


const chatDetailState = {
    messageList: [],
    isLoading: false,
    hasError: false,
    errorMessage: "",
    customerName: "",
    customerId: "",
    customerImage: "",
    scrollBottomActive: false,
    customerNumber: "",
    isAdminTransaction: false
};
export function chatDetailReducer(state = chatDetailState, action) {
    switch (action.type) {
        case actionTypes.SELECT_CHAT_DETAIL_LIST_SUCCESS:
            return {
                ...state,
                messageList: action.payload,
                customerName: action.customerName,
                customerId: action.customerId,
                customerImage: action.customerImage,
                customerNumber: action.customerNumber,
                scrollBottomActive: action.payload.length > 0,
                isAdminTransaction: action.isAdminTransaction
            };

        case actionTypes.SELECT_CHAT_DETAIL_LIST_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
                scrollBottomActive: false
            };
        case actionTypes.FINISH_ADMIN_CHAT:
            return {
                ...state,
                isAdminTransaction: false
            };
            case actionTypes.START_ADMIN_CHAT:
                return {
                    ...state,
                    isAdminTransaction: true
                };

        case actionTypes.SELECT_CHAT_DETAIL_LIST_ERROR:
            return {
                ...state,
                hasError: action.hasError,
                errorMessage: action.errorMessage,
                scrollBottomActive: false
            };

        case actionTypes.ON_CHAT_LISTENER:

            var data = action.payload;
            var destination = data.destination;
            if (destination == state.customerNumber) {
                var defaultList = JSON.parse(JSON.stringify(state.messageList));
                defaultList.push(data);

                if (global.chatBodyRef) {
                    setTimeout(() => {
                        global.chatBodyRef.scrollToBottom()
                    }, 250);
                }
                return {
                    ...state,
                    messageList: defaultList,
                };

            } else {
                return {
                    ...state,
                }
            }

        default:
            return state;
    }
}