import globalConstant from "constants/globalConstant";
import network from "network/network";
import actionTypes from "./actionTypes";



export function selectOrderList(user) {

    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.SELECT_ORDER_LIST_LOADING,
            isLoading: true
        })

        network.get("/admin/orders/selectOrderList", user, false).then((result) => {

            dispatch({
                type: actionTypes.SELECT_ORDER_LIST_LOADING,
                isLoading: false
            })

            const { data } = result;
            console.log(data);
            if (result.isSuccess && data.isSuccess) {

                var products = JSON.parse(data.data)[0];
                dispatch({
                    type: actionTypes.SELECT_ORDER_LIST_SUCCESS,
                    payload: products
                })

            } else {
                dispatch({
                    type: actionTypes.SELECT_ORDER_LIST_ERROR,
                    errorMessage: result.errorMessage,
                    hasError: true
                })
            }

        });
    }
};



export function selectOrderDetail(orderId) {

    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.SELECT_ORDER_DETAIL_LOADING,
            isLoading: true
        })
        const params = {
            orderId: orderId
        }

        network.get("/admin/Orders/selectOrderDetailForUpdate", params, false).then((result) => {
            const { data } = result;
            if (result.isSuccess && data.isSuccess) {

                var prod = data.data;

                const orderStatus = prod.orderStatus;
                var orderisEnable = orderStatus !== globalConstant.orderStatus.deliveryComplete;
              
                
                dispatch({
                    type: actionTypes.SELECT_ORDER_DETAIL_SUCCESS,
                    orderItemList: prod.orderItemList,
                    orderMasterInfo: prod,
                    orderisEnable : orderisEnable
                }) 

            } else {
                dispatch({
                    type: actionTypes.SELECT_ORDER_DETAIL_ERROR,
                    errorMessage: result.errorMessage,
                    hasError: true
                })
            }
            setTimeout(() => {
                dispatch({
                    type: actionTypes.SELECT_ORDER_DETAIL_LOADING,
                    isLoading: false
                })
            }, 0);

        });
    }
}


export function updateOrderMaster(updateparam, value) {

    return (dispatch, getState) => {
        var defaultValues = JSON.parse(JSON.stringify(getState().orderDetailReducer.orderMasterInfo));
        defaultValues[updateparam] = value;

        dispatch({
            type: actionTypes.UPDATE_ORDER_MASTER_INFO,
            orderMasterInfo: defaultValues
        })
    }
}
export function insertOrderItem(params) {

    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.ORDER_ITEM_ADD,
            payload: params
        })
    }
}

export function updateOrderItem(params) {

    return (dispatch, getState) => { 

        dispatch({
            type: actionTypes.ORDER_ITEM_UPDATE,
            payload: params
        })
    }
}
export function deleteOrderItem(params) {

    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.ORDER_ITEM_DELETE,
            payload: params
        })
    }
}
