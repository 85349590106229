import actionTypes from "utils/redux/actions/actionTypes";

const initialState = {
    stampList: [],
    isLoading: false,
    hasError: false,
    errorMessage: "",
};
export function stampReducer(state = initialState, action) {
    switch (action.type) {
        // case actionTypes.SELECT_CHAT_LIST_SUCCESS:
        //     return {
        //         ...state,
        //         chatList: action.payload
        //     };

        case actionTypes.STAMP_LISTENER: 
            var data = action.payload;
            

            var newList = JSON.parse(JSON.stringify(state.stampList));
                console.log(data)

            
                var stampObj = action
                    
               
                newList.push(stampObj);

                return {
                    ...state,
                    
                };

            

        default:
            return state;
    }
}


