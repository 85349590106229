import network from "network/network";
import actionTypes from "./actionTypes";
 
 

export function selectProductList (user) {

    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.SELECT_PRODUCT_LIST_LOADING,
            isLoading : true
        }) 
        var params = {}

        network.get("/admin/products/selectProductList", params, false).then((result) => {
 
            dispatch({
                type: actionTypes.SELECT_PRODUCT_LIST_LOADING,
                isLoading : false
            }) 
            
            if (result.isSuccess) {

                var products = (result.data.data);
                dispatch({
                    type: actionTypes.SELECT_PRODUCT_LIST_SUCCESS,
                    payload : products
                })

            } else {
                dispatch({
                    type: actionTypes.SELECT_PRODUCT_LIST_ERROR,
                    errorMessage: result.errorMessage,
                    hasError : true
                })
            }

        });
    }
};
 
