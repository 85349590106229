import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, Table } from "antd";
import React, { useRef } from "react";
import NumberFormat from "react-number-format";
import tools from 'tools'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import InsertOrderItemModal from "./InsertOrderItemModal";
import { connect } from "react-redux";
import { deleteOrderItem } from "utils/redux/actions/orderAction";
import { bindActionCreators } from "redux";
import helperFunc from "helpers/helperFunc";

const OrderItemList = ({ orderItemList, deleteOrderItemAction }) => {

    const orderItemModalRef = useRef();

    const deleteOrderItem = (record) => {
    
		helperFunc.showConfirm({
			content: `Sipariş kalemi silinecek. Onaylıyor musunuz?`,
			title: "Uyarı!", onConfirm: () => deleteOrderItemConfirm(record.itemId)
		});

    }
    const editOrderItem = (record) => {

        orderItemModalRef.current.openItemModal(record.itemId);
    }
    const deleteOrderItemConfirm =(itemId)=>{

        deleteOrderItemAction(itemId);
    }

    /*
    itemId: 112
orderId: 112
price: 30
productId: 1
productName: "19 Litre Su"
quantity: 3
    */

    const tableColumns = [
        {
            title: 'Id',
            dataIndex: 'itemId',
            sorter: (a, b) => tools.antdTableSorter(a, b, 'itemId')
        },
        {
            title: 'Ürün Adı',
            dataIndex: 'productName',
            sorter: (a, b) => tools.antdTableSorter(a, b, 'productName')
        },
        {
            title: 'Ürün Adedi',
            dataIndex: 'quantity',
            sorter: (a, b) => tools.antdTableSorter(a, b, 'quantity')
        },
        
        {
            title: 'Toplam Tutar',
            dataIndex: 'price',
            render: (_, record) => (
                <span className="font-weight-semibold">
                    <NumberFormat
                        displayType={'text'}
                        value={(Math.round((record.price*record.quantity) * 100) / 100).toFixed(2)}
                        prefix={'₺'}
                        thousandSeparator={true}
                    />
                </span>
            ),
            sorter: (a, b) => tools.antdTableSorter(a, b, 'price')
        },
        {
            title: 'İşlemler',
            key: 'action',
            
            render: (text, record) => (
                <span>
                    <Button type="link" onClick={() => editOrderItem(record)} htmlType="button" icon={<EditOutlined />}>
                        Düzenle
                    </Button>
                    <Button type="link" danger onClick={() => deleteOrderItem(record)} htmlType="button" icon={<DeleteOutlined />}  >
                        Sil
                    </Button>
                </span>
            ),
        },
    ];

    return (
        <div className="table-responsive">
            <Table
                pagination={false}
                columns={tableColumns}
                dataSource={orderItemList}
                rowKey='ORDERID'
            />
            <InsertOrderItemModal ref={orderItemModalRef}>

            </InsertOrderItemModal>
        </div>
    );
}


function mapStateToProps(state) {

    return { 
        orderItemList : state.orderDetailReducer.orderItemList ||[]
    }
}

function mapDispatchToProps(dispatch) {
    return { 
        deleteOrderItemAction: bindActionCreators(deleteOrderItem, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderItemList);