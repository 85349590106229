
import React, { useState, useEffect, useRef } from 'react'
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt'
import { Tabs, Form, Button, message, Input, Row, Col, Card,Menu, Upload, InputNumber, Select } from 'antd';
import Flex from 'components/shared-components/Flex' 
import network from 'network/network';
import globalConstant from 'constants/globalConstant';
import { useNavigate, useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';
const { TabPane } = Tabs;
const ADD = 'ADD'

const GroupsCreate = props => {
	let navigate = useNavigate();

	const { mode = ADD, param } = props

	const [form] = Form.useForm();

	const [submitLoading, setSubmitLoading] = useState(false);
	const [getDetailLoading, setGetDetailLoading] = useState(true);
	const [firmSellerList, setFirmSellerList] = useState([]);
	const [selectedFirmSeller, setSelectedFirmSeller] = useState(-1);

	const { id } = useParams();

	const [groupState, setGroupState] = useState({
        groupId: id,
		groupName: "",
	});

	useEffect(() => {
        console.log(id)
		
		if (id > 0) {
			selectGroupDetail();
		} else {
			setGetDetailLoading(false);
		}

	}, [form, mode, param, props]);
    // const getGroup = (groupId) => {

    //     var index = toData.findIndex(w => w.groupid == groupId);
    //     return toData[index].groupname

    // }
	const selectGroupDetail = async () => {
        
		setGetDetailLoading(true);
		var result = await network.get("/admin/Group/selectGroupList",false);
        
		setTimeout(() => {
			setGetDetailLoading(false);
		}, 100);

		const { data } = result.data;
		console.log(data)
        // setGroupDetail(data);
        var index = data.findIndex(w => w.groupid == id);
        console.log(data[index].groupname)
		if (result.isSuccess) {
			setGroupState({
                groupId:id,
				groupName: data[index].groupname,
			});
		}
	}

	const onFinish = async () => {

		if (groupState.groupName.length==0) {
			message.error('Lütfen zorunlu alanları doldurunuz');
			return;
		}
		setSubmitLoading(true)

		var params = {
			groupid: parseInt(groupState.groupId),
			groupname: groupState.groupName,
			status: true	
		}

        console.log(params)
        
		var method = "insertGroup";
		if (params.groupid > 0) {
            console.log("updatee Girdi")
			method = "updateGroup";
		}

		var result = await network.post(`/admin/Group/${method}`, params);

		setSubmitLoading(false)
		if (result.isSuccess) {
			var postResult = result.data;
			if (postResult.isSuccess) {

				message.success('Müşteri bilgileri başarılı bir şekilde kaydedilmiştir.');

				setTimeout(() => {

					//  navigate(`/customers`)
				}, 250);

			} else {
				alert(postResult.errorMessage);
			}

		} else {
			alert(result.errorMessage);
		}


	};

	if (getDetailLoading) {
		return (
			<div>
                
			</div>
		);
	}


	

	return (
		<>
			<Helmet>
				<title>{id > 0 ? 'Grubu Güncelle' : `Grup Ekle`}</title>
			</Helmet>
			<Form
				layout="vertical"
				
				name="advanced_search"
				className="ant-advanced-search-form"
			>
				<PageHeaderAlt className="border-bottom" overlap>
					<div className="container">
						<Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
							<h2 className="mb-3">{id> 0 ? 'Grubu Güncelle' : `Grup Ekle`} </h2>
							<div className="mb-3">
								<Button type="primary" onClick={() => onFinish()} htmlType="submit" loading={submitLoading} >
									{id > 0 ? 'Güncelle' : `Ekle`}
								</Button>
							</div>
						</Flex>
					</div>
				</PageHeaderAlt>
				<div className="container">
					<Tabs defaultActiveKey="1" style={{ marginTop: 30 }}>
						<TabPane tab="Genel Bilgiler" key="1">
							<Row gutter={16}>
								<Col xs={24} sm={24} md={17}>
									<Card title="Grup Bilgileri">
										<div className='ant-col ant-form-item-label'>
											<label className='ant-form-item-required' htmlFor="customerName">Grup Adı</label>
										</div>

										<Input value={groupState.groupName}
											defaultValue={groupState.groupName}
											onChange={(val) => {
												setGroupState({
													...groupState,
													groupName: val.target.value
												});
											}}
											placeholder="Grup Adı" />
										<div className='m-4'>
										</div>
                                        
									</Card>
								</Col>
								
							</Row>
						</TabPane>
					</Tabs>
				</div>
			</Form>
		</>
	)
}

export default GroupsCreate