import AppLocale from 'lang'
import LoginForm from 'pages/Auth/LoginForm'
import AppLayout from 'pages/Dashboard/AppLayout'
import Home from 'pages/Dashboard/home/index'
import ErrorPage from 'pages/ErrorPage'
import React, { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Customers from 'pages/Dashboard/customers/index'
import Product from 'pages/Dashboard/product/index'
import Orders from 'pages/Dashboard/orders/index'
import Sellers from 'pages/Dashboard/sellers/index'
import Chat from 'pages/Dashboard/chat/index'
import Notification from 'pages/Dashboard/notifications/index'
import EditCustomer from 'pages/Dashboard/customers/edit-customer'
import { selectActivityStatusList } from "utils/redux/actions/activityStatusActions";
import OrderDetail from 'pages/Dashboard/orders/order-detail'
import EditOrder from 'pages/Dashboard/orders/edit-order'
import AddProduct from 'pages/Dashboard/product/add-product'
import Campaigns from 'pages/Dashboard/campaigns/campaigns'
import CampaignDetail from 'pages/Dashboard/campaigns/campaign-detail'
import CampaignCreate from 'pages/Dashboard/campaigns/campaign-create'
import Groups from 'pages/Dashboard/groups/'
import GroupsCreate from 'pages/Dashboard/groups/groups-create'
import BaristaScreen from 'pages/Dashboard/barista-screen'
import KodsanReport from 'pages/Dashboard/kodsan-report'
import CoffeeDashboard from 'pages/Dashboard/coffee-dashboard'
import DashboardCustomer from 'pages/Dashboard/dashboard-customer'
import DetailDashboardCustomer from 'pages/Dashboard/dashboard-customer/detail-customer'



function Router(props) {

  const { locale, token, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <BrowserRouter >
        <Routes  >
          <Route path='/' element={<PrivateRoute><AppLayout /></PrivateRoute>}>
            <Route index={true} element={<CoffeeDashboard />} />
            <Route path='chat' element={<Chat />} />
            <Route path='customers' element={<Customers />} />
            <Route path='customer/detail/:id' element={<EditCustomer />} />
            <Route path='product-list' element={<Product />} />
            <Route path='product/add/:id' element={<AddProduct />} />
            <Route path='orders' element={<Orders />} />
            <Route path='order/detail/:id' element={<OrderDetail />} />
            <Route path='order/edit/:id' element={<EditOrder />} />
            <Route path='sellers' element={<Sellers />} />
            <Route path='notifications' element={<Notification />} />
            <Route path='campaigns' element={<Campaigns />}/>
            <Route path='campaign-detail' element={<CampaignDetail />}/>
            <Route path='campaign-create' element={<CampaignCreate />}/>
            <Route path='groups' element={<Groups/>}/>
            <Route path='groups/groups-create/:id' element={<GroupsCreate/>}/>
            <Route path='barista-screen' element={<BaristaScreen/>}/>
            <Route path='kodsan-report' element={<KodsanReport/>}/>
            <Route path='coffee-dashboard' element={<CoffeeDashboard/>}/>
            <Route path='dashboard-customer' element={<DashboardCustomer/>}/>
            <Route path='dashboard-customer/detail/:id' element={<DetailDashboardCustomer/>}/>
          </Route>
          <Route path='login' element={<LoginForm />} />
          <Route path='*' element={<ErrorPage />}></Route>
          
        </Routes>
      </BrowserRouter>
    </IntlProvider>
  )
}


const mapStateToProps = ({ theme, auth, leftMenuReducer }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  const { initialRouteName } = leftMenuReducer;
  return { locale, direction, token }
};

export default (connect(mapStateToProps)(Router));

