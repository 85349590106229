import network from "network/network"
import React, { useEffect, useState } from "react"
import { Modal,  } from 'antd';

import { FontAwesomeIcon,  } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

const ListMessageItemModal = ({ modalVisible, messageId, setModalVisible, messageHeader }) => {

    const [listLoading, setListLoading] = useState(true);
    const [itemList, setItemList] = useState([]);


    useEffect(() => {

        if (messageId > 0) {
            setListLoading(true);
            selectMessageItems();
        }

    }, [messageId]);

    const selectMessageItems = async () => {

        var params = {
            userToken: localStorage.getItem("auth_token"),
            messageId: messageId
        }
        var result = await network.get("/messages/selectListMessageItems", params);
        setListLoading(false);

        if (result.isSuccess) {
            var data = result.data;
            setItemList(data);
        } else {
            alert(result.errorMessage);
        }
    }


    const getModalListItems = (item) => {


        return item.options.map((item2, i) => (
            <div key={"item.options" + i} className="justify-content-between" style={{display:"flex",alignItems:"center", margin: 10, border: 0, borderTopWidth: 1, borderTopStyle: "solid", borderTopColor: "#d1d1d1" }}>
                <div>
                    <div style={{ color: "black" }} className="font-size-md">
                        {item2.title}
                    </div>
                    <div className="text-gray font-size-sm" >
                        {item2.description}
                    </div>
                </div>
                <div>
                    <FontAwesomeIcon style={{ color:"#fff", borderWidth:1, borderColor:"black", borderStyle:"solid", borderRadius:50 }} className="" icon={faCircle} />
                </div>
            </div>
        ));
    }

    return (
        <Modal
            title={messageHeader}
            centered
            visible={modalVisible}
            onOk={() => setModalVisible(false)}
            onCancel={() => setModalVisible(false)}
            footer={null}
        >
            {itemList.map((item, i) => (
                <div key={"itemList" + i} className="text-dark">
                    <div>
                        <span className="text-primary font-size-lg">{item.title}</span>
                    </div>
                    {getModalListItems(item)}
                </div>
            ))}
        </Modal>
    );
}
export default ListMessageItemModal;