import React, { useEffect } from "react"
import {Skeleton } from 'antd';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";  
import { selectMessageChart } from "utils/redux/actions/defaultDashboardActions";
import ChartWidget from "components/shared-components/ChartWidget";


const MessageChart = (props) => {

    useEffect(()=>{

        props.selectMessageChartAction();
    },[]);
    const getMessageChat = () => {

        if (props.messageChartLoading || props.messageChartDates.length == 0) {
    
          return (
            <>
              <Skeleton loading paragraph={true} round />
              <Skeleton loading paragraph={true} round />
              <Skeleton loading paragraph={true} round />
            </>
          );
        } else {
       
    
          return (
            <ChartWidget
              title="Mesaj Oturumu-Sipariş Grafiği"
              // series={visitorChartData.series} 
              // xAxis={visitorChartData.categories} 
              series={props.messageChartSeries}
              xAxis={props.messageChartDates}
              height={'400px'}
              direction={"tr"}
            />
          );
        }
      }

    return getMessageChat();
}


function mapStateToProps(state) {

    return {
        messageChartDates: state.dashboardReducer.messageChartDates,
        messageChartSeries: state.dashboardReducer.messageChartSeries,
        messageChartLoading: state.dashboardReducer.messageChartLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectMessageChartAction: bindActionCreators(selectMessageChart, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageChart);
