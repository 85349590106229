import React, { useEffect } from "react"
import { Avatar,Table, Tag } from 'antd';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  selectLatestOrderList } from "utils/redux/actions/defaultDashboardActions";
 
import globalConstant from "constants/globalConstant";
import { useHistory, useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import Tools from "tools";



const getPaymentStatusText = (status, paymentType) => {


    if (paymentType == globalConstant.paymentType.cashOnDelivery) {
      return "Kapıda Ödeme";
    } else {
      if (status && status != globalConstant.paymentStatus.waitForPayment) {
        if (status == globalConstant.paymentStatus.paymentComplete) {
          return "Ödeme Alındı";
        } else {
          return "Ödeme Hataya Düştü";
        }
      } else {
        return "Online Ödeme Bekleniyor";
      }
    }
  }
  const getPaymentStatusColor = (status, paymentType) => {
    if (paymentType == globalConstant.paymentType.cashOnDelivery) {
      return "blue";
    } else {
      if (status && status != globalConstant.paymentStatus.waitForPayment) {
        if (status == globalConstant.paymentStatus.paymentComplete) {
          return "green";
        } else {
          return "red";
        }
      } else {
        return "orange";
      }
    }
  }

const LatestOrderList = (props) => {

    const navigate = useNavigate();

    useEffect(() => {

        props.selectLatestOrderListAction();
    }, []); 

    const tableColumns = [
        {
          title: 'Müşteri',
          dataIndex: 'CLIENTNAME',
          key: 'CLIENTNAME',
          render: (text, record) => (
            <div className="d-flex align-items-center">
              <Avatar size={30} className="font-size-sm" style={{ backgroundColor: "#04d182" }}>
                {Tools.getNameInitial(text)}
              </Avatar>
              <span className="ml-2">{text}</span>
            </div>
          ),
        },
        {
          title: 'Tarih',
          dataIndex: 'ORDERDATEFORMAT',
          key: 'ORDERDATEFORMAT',
        },
        {
          title: 'Tutar',
          dataIndex: 'TOTALAMOUNT',
          key: 'TOTALAMOUNT',
          render: (_, record) => (
            <span className="font-weight-semibold">
              <NumberFormat
                displayType={'text'}
                value={(Math.round(record.TOTALAMOUNT * 100) / 100).toFixed(2)}
                prefix={'₺'}
                thousandSeparator={true}
              />
            </span>
          )
        },
        {
          title: () => <div className="text-left">Sipariş Durumu</div>,
          key: 'PAYMENTSTATUS',
          render: (_, record) => (
            <Tag color={getPaymentStatusColor(record.PAYMENTSTATUS, record.PAYMENTTYPE)}>
              {getPaymentStatusText(record.PAYMENTSTATUS, record.PAYMENTTYPE)}
            </Tag>
          ),
        },
      ];

    return (
        <Table
            className="no-border-last"
            columns={tableColumns}
            dataSource={props.latestOrderList}
            rowKey='id'
            pagination={false}
            loading={props.latestOrderListLoading}
        />
    );
}


function mapStateToProps(state) {

    return {
        latestOrderList: state.dashboardReducer.latestOrderList,
        latestOrderListLoading: state.dashboardReducer.latestOrderListLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectLatestOrderListAction: bindActionCreators(selectLatestOrderList, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LatestOrderList);
